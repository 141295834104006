import { all } from 'redux-saga/effects';

import loginSagaWatch from '@/src/store/modules/login/saga';
import accountSagaWatch from '@/src/store/modules/account/saga';
import usersSagaWatch from '@/src/store/modules/users/saga';
import auditLogSagaWatch from '@/src/store/modules/audit-log/saga';
import ordersSagaWatch from '@/src/store/modules/orders/saga';
import orderDetailsSagaWatch from '@/src/store/modules/order-details/saga';
import subscriptionsSagaWatch from '@/src/store/modules/subscriptions/saga';
import promotionsSagaWatch from '@/src/store/modules/promotions/saga';
import promotionDetailsSagaWatch from '@/src/store/modules/promotion-details/saga';
import couponsSagaWatch from '@/src/store/modules/coupons/saga';
import productsSagaWatch from '@/src/store/modules/products/saga';
import allProductsSagaWatch from '@/src/store/modules/products-all/saga';
import categoriesSagaWatch from '@/src/store/modules/categories/saga';
import highlightsSagaWatch from '@/src/store/modules/highlights/saga';
import manufacturersSagaWatch from '@/src/store/modules/manufacturers/saga';
import referralsSagaWatch from '@/src/store/modules/referrals/saga';
import customerSagaWatch from '@/src/store/modules/customers/saga';
import hubsSagaWatch from '@/src/store/modules/hubs/saga';
import walletSagaWatch from '@/src/store/modules/wallet/saga';
import shippingSagaWatch from '@/src/store/modules/shipping/saga';
import changePasswordSagaWatch from '@/src/store/modules/change-password/saga';
import forgotPasswordSagaWatch from '@/src/store/modules/forgot-password/saga';
import petbreedsSagaWatch from '@/src/store/modules/pet-breeds/saga';
import tagsSaga from '@/src/store/modules/tags/saga';
import timelineEventsSagaWatch from '@/src/store/modules/timeline-events/saga';
import aislesSagaWatch from '@/src/store/modules/aisles/saga';
import assemblingsSagaWatch from '@/src/store/modules/assemblings/saga';
import catalogCategoriesAisleSagaWatch from '@/src/store/modules/catalog-categories/saga';
import attributesSagaWatch from '@/src/store/modules/attributes/saga';
import departmentsSagaWatch from '@/src/store/modules/departments/saga';
import catalogDepartmentsSagaWatch from '@/src/store/modules/catalog-departments/saga';
import productDetailsWatch from '@/src/store/modules/product-details/saga';
import expeditionWatch from '@/src/store/modules/expedition/saga';
import hubsDataWatch from '@/src/store/modules/hubs-data/saga';
import districtsWatch from '@/src/store/modules/districts/saga';
import regionsWatch from '@/src/store/modules/regions/saga';
import zipcodesWatch from '@/src/store/modules/zipcodes/saga';
import etasWatch from '@/src/store/modules/etas/saga';
import suspensionsWatch from '@/src/store/modules/suspensions/saga';
import statesWatch from '@/src/store/modules/states/saga';
import citiesWatch from '@/src/store/modules/cities/saga';
import countriesWatch from '@/src/store/modules/countries/saga';
import policiesSagaWatch from '@/src/store/modules/policies/saga';
import priceTablesSagaWatch from '@/src/store/modules/price-tables/saga';
import priceIndexingSagaWatch from '@/src/store/modules/price-indexing/saga';
import recommendationsSagaWatch from '@/src/store/modules/recommendations/saga';
import deliverersSagaWatch from '@/src/store/modules/deliverers/saga';
import breedsSagaWatch from '@/src/store/modules/breeds/saga';
import accessoriesSagaWatch from '@/src/store/modules/accessories/saga';
import gatewayStoresSagaWatch from '@/src/store/modules/gateway-stores/saga';
import skusPetzSagaWatch from '@/src/store/modules/skus-petz/saga';
import customFieldsSagaWatch from '@/src/store/modules/custom-fields/saga';
// import categoryDetailsWatch from '@/src/store/modules/category-details/saga';

export default function* rootSaga() {
  yield all([
    loginSagaWatch(),
    accountSagaWatch(),
    ordersSagaWatch(),
    orderDetailsSagaWatch(),
    subscriptionsSagaWatch(),
    promotionsSagaWatch(),
    promotionDetailsSagaWatch(),
    couponsSagaWatch(),
    productsSagaWatch(),
    allProductsSagaWatch(),
    productDetailsWatch(),
    categoriesSagaWatch(),
    highlightsSagaWatch(),
    manufacturersSagaWatch(),
    referralsSagaWatch(),
    customerSagaWatch(),
    hubsSagaWatch(),
    usersSagaWatch(),
    changePasswordSagaWatch(),
    shippingSagaWatch(),
    forgotPasswordSagaWatch(),
    walletSagaWatch(),
    auditLogSagaWatch(),
    petbreedsSagaWatch(),
    tagsSaga(),
    timelineEventsSagaWatch(),
    aislesSagaWatch(),
    catalogCategoriesAisleSagaWatch(),
    attributesSagaWatch(),
    departmentsSagaWatch(),
    catalogDepartmentsSagaWatch(),
    expeditionWatch(),
    hubsDataWatch(),
    districtsWatch(),
    regionsWatch(),
    zipcodesWatch(),
    etasWatch(),
    suspensionsWatch(),
    statesWatch(),
    citiesWatch(),
    countriesWatch(),
    policiesSagaWatch(),
    priceTablesSagaWatch(),
    priceIndexingSagaWatch(),
    recommendationsSagaWatch(),
    deliverersSagaWatch(),
    breedsSagaWatch(),
    accessoriesSagaWatch(),
    gatewayStoresSagaWatch(),
    skusPetzSagaWatch(),
    assemblingsSagaWatch(),
    customFieldsSagaWatch()
  ]);
}
