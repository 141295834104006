import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import moment from '@/src/services/moment';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';
import formatCurrency from '@/src/utils/formatCurrency';

import formatParityText from '@/src/utils/formatParityText';
import useProductsFilter from '@/src/hooks/useLabelById';

const FilteredTags = props => {
  const {
    populateTable,
    query,
    selectedDeliverers,
    setSelectedFilters,
    noMargin
  } = props;
  const chips = [];
  const history = useHistory();

  useEffect(() => {
    return () => {
      setSelectedFilters(false);
    };
    // eslint-disable-next-line
  }, []);

  const { getLabelById } = useProductsFilter();
  const hubsList = useSelector(state => state.hubsData.list);
  const deliveryCompanies = useSelector(
    state => state.shipping.deliveryCompaniesList
  );
  const params = buildQueryFromUrl(history);

  const translatedFilters = {
    partially_delivered: 'Entrega parcial',
    banned: 'Banido',
    with_open_issue: 'Com alerta',
    fraudulent: 'Fraudulento',
    min_value: 'Valor mínimo',
    min_fixed_value: 'Valor mínimo',
    max_value: 'Valor máximo',
    max_fixed_value: 'Valor máximo',
    max_converted_value: 'Valor máximo',
    min_converted_value: 'Valor mínimo',
    less_orders_than: 'Qtd máxima',
    more_orders_than: 'Qtd mínima',
    more_items_than: 'Maior que',
    max_percentage_value: 'Maior que',
    less_items_than: 'Menor que',
    min_percentage_value: 'Menor que',
    start_date: 'De',
    start_at: 'De',
    end_date: 'Até',
    end_at: 'Até',
    by_status: 'Status',
    discount_type: 'Tipo de Desconto',
    percentage: 'Porcentagem',
    fixed_value: 'Valor Fixo',
    gender: 'Gênero',
    by_gender: 'Gênero',
    city_name: 'Cidade',
    state_name: 'Estado',
    animal_species: 'Espécie Pet',
    pet_gender: 'Gênero Pet',
    pet_month: 'Aniversário Pet',
    pet_species: 'Espécie',
    pet_breed: 'Raça',
    by_tag: 'Tag',
    by_manufacturer: 'Fabricante',
    by_category: 'Categoria',
    by_available_quantity: 'Range: Disponíveis',
    by_stock_quantity: 'Range: Totais',
    by_reserved_quantity: 'Range: Reservados',
    delivery_company_id: 'Transportadora',
    by_interval: 'Frequência (dias)',
    next_billing_start_date: 'Próxima cobrança de',
    next_billing_end_date: 'Próxima cobrança até',
    by_order_request_origin: 'Solicitação pendente de'
  };

  const translatedPendingOrder = {
    petz: 'Petz',
    storefront: 'Cliente',
    portal: 'Zee'
  };

  const translatedStatus = {
    waiting: 'Aguardando',
    started: 'Iniciado',
    picked: 'Coletado',
    packed: 'Empacotado',
    canceled: 'Cancelado',
    separated: 'Embarque',
    billed: 'Faturado',
    processing: 'Processando',
    dispatched: 'Em trânsito',
    delivered: 'Entregue',
    handled: 'Saiu para entrega',
    delivering: 'Chegando',
    active: 'Ativa',
    inactive: 'Inativo',
    not_active: 'Inativo',
    expired: 'Expirada',
    expiring: 'A Vencer',
    archived: 'Arquivada',
    female: 'Feminino',
    male: 'Masculino',
    cachorro: 'Cachorro',
    gato: 'Gato',
    f: 'Fêmea',
    m: 'Macho'
  };

  const translatedMonth = {
    '1': 'Janeiro',
    '2': 'Fevereiro',
    '3': 'Março',
    '4': 'Abril',
    '5': 'Maio',
    '6': 'Junho',
    '7': 'Julho',
    '8': 'Agosto',
    '9': 'Setembro',
    '10': 'Outubro',
    '11': 'Novembro',
    '12': 'Dezembro'
  };

  const removeTag = (type, arrayItem) => {
    let newFilters = {
      ...query.filters
    };
    const newParams = params;

    if (arrayItem) {
      if (type === 'pet_breed' || type === 'by_tag') {
        if (typeof newFilters[type] === 'string') {
          // replace of arrayItem
          const arrayItemReplace = arrayItem.replace(' ', '_');
          // transform string of newFilters[type] in an array
          const breedArrayFilter = newFilters[type].split(',');
          const newPetFilters = breedArrayFilter.filter(
            breedId => breedId !== arrayItemReplace
          );
          newFilters = {
            ...query.filters,
            [type]: newPetFilters
          };
          newParams.filters[type] = newPetFilters;
        } else {
          const arrayItemReplace = arrayItem.replace(' ', '_');
          const newPetFilters = newFilters[type].filter(
            breedId => breedId !== arrayItemReplace
          );
          if (newFilters[type].length === 1) {
            delete newParams.filters[type];
            delete newFilters[type];
          } else {
            newFilters = {
              ...query.filters,
              [type]: newPetFilters
            };
            newParams.filters[type] = newPetFilters;
          }
        }
      } else if (
        type === 'by_hub' ||
        type === 'hubs' ||
        type === 'by_property' ||
        type === 'by_status' ||
        type === 'status' ||
        type === 'by_deliverer' ||
        type === 'by_coupons' ||
        type === 'by_order_request_origin'
      ) {
        const newItems = newFilters[type].filter(item => item !== arrayItem);
        newFilters = {
          ...query.filters,
          [type]: newItems
        };
        newParams.filters[type] = newItems;
      }
    } else {
      delete newParams.filters[type];
      delete newFilters[type];
    }

    let daysAgoFilter = query.daysAgo;
    const hasFilters = Object.values(newFilters).filter(
      item => item.length > 0
    );

    const hasDateFilter = newFilters.start_date || newFilters.end_date;

    if (hasDateFilter) {
      daysAgoFilter = 'all';
    } else if (type === 'start_date' || type === 'end_date') {
      daysAgoFilter = '1';
    } else if (query.daysAgo && hasFilters.length === 0) {
      daysAgoFilter = query.daysAgo === 'all' ? 'all' : '1';
    }

    populateTable({
      query: {
        page: 1,
        search: newParams.search,
        daysAgo: daysAgoFilter,
        sort: query.sort,
        filters: newFilters
      }
    });
  };

  const removeDispatchTag = key => {
    const filterKey = key.split('/')[0];
    const filterType = key.split('/')[1];
    const filterValue = key.split('/')[2];
    const newFilters = query.filters
      .map(item => {
        const newItem = { ...item };
        if (newItem.key === filterKey && newItem.type === filterType) {
          const valuesArr =
            typeof newItem.value === 'string' && newItem.value.split(',');
          if (valuesArr) {
            newItem.value = valuesArr
              .filter(value => value !== filterValue)
              .join();
          } else {
            newItem.value = '';
          }
        }
        return newItem;
      })
      .filter(item => item.value !== '');
    populateTable({
      query: {
        ...query,
        filters: newFilters,
        sort: window.location.href.includes('expedicao?view=lines')
          ? 'created_at'
          : query.sort
      }
    });
  };

  const handleDispatchFilters = filter => {
    const filterObj = {
      label: '',
      value: ''
    };
    let startContent;
    let endContent;
    switch (filter.key) {
      case 'hub_internal_id':
        filterObj.label = 'Hub';
        filterObj.value = hubsList.find(
          item =>
            item.attributes.internal_id === filter.value ||
            item.attributes.internal_id === filter.value.toString()
        )?.attributes?.name;
        break;
      case 'total':
        filterObj.label =
          filter.type === 'gte' ? 'Valor mínimo' : 'Valor máximo';
        filterObj.value = formatCurrency(filter.value);
        break;
      case 'created_at':
        startContent = `${filter.tag ? 'Mais de' : 'Até'}`;
        endContent = `${filter.tag ? 'Menos de' : 'A partir de'}`;
        filterObj.label = `${
          !filter.tag ? 'Data de criação' : 'Tempo do pedido'
        }`;
        filterObj.value = `${
          filter.type === 'lte' ? startContent : endContent
        } ${
          filter.tag
            ? `${formatParityText(filter.tag || 0, 'minuto')}`
            : moment(filter.value).format('DD/MM/YY')
        }`;
        break;
      case 'updated_at':
        filterObj.label = 'Data de atualização';
        filterObj.value = `${
          filter.type === 'lte' ? 'Até' : 'A partir de'
        } ${moment(filter.value).format('DD/MM/YY')}`;
        break;
      case 'eta_max':
        filterObj.label = 'Tempo de mesa';
        filterObj.value = `${filter.type === 'lte' ? 'Até' : 'A partir de'} ${
          filter.value
        }`;
        break;
      case 'status':
        filterObj.label = 'Status';
        filterObj.value = translatedStatus[filter.value];
        break;
      case 'delivery_company_id':
        filterObj.label = 'Transportadora';
        filterObj.value =
          deliveryCompanies.find(item => item.id === filter.value)?.attributes
            ?.name || filter.value;
        break;
      // case 'search':
      //   filterObj.label = '';
      //   filterObj.value = filter.value;
      //   break;
      default:
        break;
    }
    return filterObj;
  };

  // dispatch filters
  if (Array.isArray(query.filters)) {
    query.filters.forEach(item => {
      if (
        !item.value ||
        item.key === 'search' ||
        item.key === 'has_alert' ||
        item.key === 'has_return_request' ||
        item.key === 'has_return_resolved' ||
        item.key === 'has_cancelation_request' ||
        item.key === 'has_cancelation_resolved' ||
        item.key === 'has_invoice_error' ||
        // item.key === 'sale_channel_id' ||
        (item.key === 'hub_internal_id' && !item.tag) ||
        item.type === 'not_eq' ||
        (window.location.href.includes('embarques') &&
          item.key !== 'delivery_company_id')
      )
        return false;
      const tags = [];
      if (typeof item.value === 'string' && item.value.includes(',')) {
        item.value.split(',').forEach(value => {
          tags.push({
            ...item,
            value
          });
        });
      } else {
        tags.push(item);
      }
      return tags.map(tag => {
        const filter = handleDispatchFilters(tag);
        return chips.push(
          <Tag
            className="chips"
            closable
            key={`${tag.key}/${tag.type}/${tag.value}`}
            onClose={() =>
              removeDispatchTag(`${tag.key}/${tag.type}/${tag.value}`)
            }
          >
            {`${filter.label && `${filter.label}: `}${filter.value}`}
          </Tag>
        );
      });
    });
  } else {
    Object.keys(query.filters).forEach(key => {
      if (
        key === 'partially_delivered' ||
        key === 'with_open_issue' ||
        key === 'fraudulent'
      ) {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}`}
          </Tag>
        );
      }
      if (key === 'more_items_than' || key === 'less_items_than') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]} ${query.filters[key]} item(s)`}
          </Tag>
        );
      }
      if (
        key === 'min_value' ||
        key === 'max_value' ||
        key === 'min_fixed_value' ||
        key === 'max_fixed_value'
      ) {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]} ${formatCurrency(query.filters[key])}`}
          </Tag>
        );
      }
      if (key === 'discount_type') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${
              translatedFilters[String(query.filters[key])]
            }`}
          </Tag>
        );
      }
      if (key === 'min_percentage_value' || key === 'max_percentage_value') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]} ${String(query.filters[key]).concat(
              '%'
            )}`}
          </Tag>
        );
      }
      if (
        key === 'start_date' ||
        key === 'start_at' ||
        key === 'end_date' ||
        key === 'end_at'
      ) {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]} ${moment(query.filters[key]).format(
              'DD/MM/YYYY'
            )}`}
          </Tag>
        );
      }
      if (key === 'by_status') {
        query.filters[key].forEach(status => {
          return chips.push(
            <Tag
              className="chips"
              closable
              key={status}
              onClose={() => removeTag(key, status)}
            >
              {`${translatedStatus[status]}`}
            </Tag>
          );
        });
        return false;
      }
      if (key === 'by_order_request_origin') {
        query.filters[key].forEach(status => {
          return chips.push(
            <Tag
              className="chips"
              closable
              key={status}
              onClose={() => removeTag(key, status)}
            >
              {`Solicitação pendente de ${translatedPendingOrder[status]}`}
            </Tag>
          );
        });
        return false;
      }
      if (
        key === 'active' ||
        key === 'expired' ||
        key === 'expiring' ||
        key === 'not_active'
      ) {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedStatus[key]}`}
          </Tag>
        );
      }
      if (key === 'by_deliverer') {
        query.filters[key].forEach(deliverer => {
          return chips.push(
            <Tag
              className="chips"
              closable
              key={deliverer}
              onClose={() => removeTag(key, deliverer)}
            >
              {selectedDeliverers[deliverer]}
            </Tag>
          );
        });
        return false;
      }
      if (key === 'by_hub') {
        query.filters[key].forEach(hubId => {
          return chips.push(
            <Tag
              className="chips"
              closable
              key={`hub_${hubId}`}
              onClose={() => removeTag(key, hubId)}
            >
              {getLabelById('hubs', hubId)}
            </Tag>
          );
        });
        return false;
      }
      if (key === 'by_property') {
        query.filters[key].forEach(propertyId => {
          return chips.push(
            <Tag
              className="chips"
              closable
              key={`property_${propertyId}`}
              onClose={() => removeTag(key, propertyId)}
            >
              {getLabelById('property', propertyId)}
            </Tag>
          );
        });
        return false;
      }
      if (key === 'by_coupons') {
        query.filters[key].forEach(coupon => {
          return chips.push(
            <Tag
              className="chips"
              closable
              key={coupon}
              onClose={() => removeTag(key, coupon)}
            >
              {coupon}
            </Tag>
          );
        });
        return false;
      }
      if (key === 'gender' || key === 'by_gender') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${
              translatedStatus[query.filters[key]]
            }`}
          </Tag>
        );
      }
      if (key === 'city_name' || key === 'status') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${query.filters[key]}`}
          </Tag>
        );
      }
      if (key === 'state_name') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${query.filters[key]}`}
          </Tag>
        );
      }
      if (key === 'pet_species') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${
              translatedStatus[query.filters[key]]
            }`}
          </Tag>
        );
      }
      if (key === 'pet_gender') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${
              translatedStatus[query.filters[key]]
            }`}
          </Tag>
        );
      }
      if (key === 'max_converted_value') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${formatCurrency(query.filters[key])}`}
          </Tag>
        );
      }
      if (key === 'min_converted_value') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${formatCurrency(query.filters[key])}`}
          </Tag>
        );
      }
      if (key === 'more_orders_than') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${query.filters[key]}`}
          </Tag>
        );
      }
      if (key === 'less_orders_than') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${query.filters[key]}`}
          </Tag>
        );
      }
      if (key === 'banned') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}`}
          </Tag>
        );
      }
      if (key === 'pet_month') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {`${translatedFilters[key]}: ${
              translatedMonth[query.filters[key]]
            }`}
          </Tag>
        );
      }
      if (key === 'pet_breed') {
        if (typeof query.filters[key] === 'string') {
          const breedArray = query.filters[key].split(',');
          const breedReplace = breedArray.map(breed => breed.replace('_', ' '));
          return chips.push(
            breedReplace.map(breed => (
              <Tag
                className="chips"
                closable
                key={breed}
                onClose={() => removeTag(key, breed)}
              >
                {`${translatedFilters[key]}: ${breed}`}
              </Tag>
            ))
          );
        }
        if (typeof query.filters[key] === 'object') {
          const breedReplace = query.filters[key].map(breed =>
            breed.replace('_', ' ')
          );
          return chips.push(
            breedReplace.map(breed => (
              <Tag
                className="chips"
                closable
                key={breed}
                onClose={() => removeTag(key, breed)}
              >
                {`${translatedFilters[key]}: ${breed}`}
              </Tag>
            ))
          );
        }
      }
      if (key === 'by_tag') {
        if (typeof query.filters[key] === 'string') {
          const tagsArray = query.filters[key].split(',');
          const tagsReplace = tagsArray.map(tag => tag.replace('_', ' '));
          return chips.push(
            tagsReplace.map(tag => (
              <Tag
                className="chips"
                closable
                key={tag}
                onClose={() => removeTag(key, tag)}
              >
                {`${translatedFilters[key]}: ${tag}`}
              </Tag>
            ))
          );
        }
        if (typeof query.filters[key] === 'object') {
          const tagsReplace = query.filters[key].map(tag =>
            tag.replace('_', ' ')
          );
          return chips.push(
            tagsReplace.map(tag => (
              <Tag
                className="chips"
                closable
                key={tag}
                onClose={() => removeTag(key, tag)}
              >
                {`${translatedFilters[key]}: ${tag}`}
              </Tag>
            ))
          );
        }
      }
      if (key === 'by_manufacturer') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {getLabelById('manufacturer', query.filters[key])}
          </Tag>
        );
      }
      if (key === 'not_archived' || key === 'archived') {
        return false;
      }
      if (key === 'by_category') {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {getLabelById('category', query.filters[key])}
          </Tag>
        );
      }
      if (
        key === 'by_available_quantity' ||
        key === 'by_stock_quantity' ||
        key === 'by_reserved_quantity'
      ) {
        return chips.push(
          <Tag
            className="chips"
            closable
            key={key}
            onClose={() => removeTag(key)}
          >
            {translatedFilters[key]}
          </Tag>
        );
      }
      return chips.push(
        <Tag
          className="chips"
          closable
          key={key}
          onClose={() => removeTag(key)}
        >
          {`${translatedFilters[key] || key} ${query.filters[key]}`}
        </Tag>
      );
    });
  }

  useEffect(() => {
    const chip = chips ? chips[0]?.length : 0;
    const hasChips = chips.length > 0 && chip !== 0;
    setSelectedFilters(hasChips);
  }, [chips, setSelectedFilters]);

  return <section className={noMargin ? '' : 'mt-20 mb-20'}>{chips}</section>;
};

FilteredTags.defaultProps = {
  populateTable: () => {},
  query: {
    page: '1',
    perPage: 20,
    daysAgo: '1',
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  selectedDeliverers: {},
  selectedHubs: {},
  setSelectedFilters: () => true,
  noMargin: false
};

FilteredTags.propTypes = {
  populateTable: PropTypes.func,
  query: PropTypes.shape({
    page: PropTypes.number,
    daysAgo: PropTypes.string,
    perPage: PropTypes.number,
    search: PropTypes.string,
    sort: PropTypes.string,
    url: PropTypes.string,
    filters: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
  }),
  selectedDeliverers: PropTypes.shape({}),
  selectedHubs: PropTypes.shape({}),
  setSelectedFilters: PropTypes.func,
  noMargin: PropTypes.bool
};

export default React.memo(FilteredTags);
