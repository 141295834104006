import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Row, Tooltip, Icon, Input, Switch, Select } from 'antd';
import { debounce } from 'throttle-debounce';

import { getDepartments } from '@/src/store/modules/departments/slice';
import { getCategories } from '@/src/store/modules/categories/slice';
import { getManufacturers } from '@/src/store/modules/manufacturers/slice';
import { getSalechannels } from '@/src/store/modules/products/slice';
import { getAisles } from '@/src/store/modules/aisles/slice';

import Tags from '@/src/components/Tags/index';

import { handleSelectSearch } from '@/src/utils/searchUtils';

const { OptGroup, Option } = Select;
const { TextArea } = Input;

const ProductDetailsForm = props => {
  const {
    content,
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    changeAttributes,
    editPermission,
    tags,
    setTags,
    setSalechannel
  } = props;

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(content.active);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);

  const { manufacturers } = useSelector(state => state.manufacturers);
  const { salechannels } = useSelector(state => state.products);
  const { departments } = useSelector(state => state.departments);
  const { categories, isLoading: categoriesLoading } = useSelector(
    state => state.categories
  );
  const { aisles, isLoading: aislesLoading } = useSelector(
    state => state.aisles
  );

  useEffect(() => {
    if (content?.id) {
      dispatch(getDepartments());
      dispatch(
        getCategories({
          query: {
            sort: '',
            page: 1,
            perPage: 100,
            search: ''
          }
        })
      );
      dispatch(
        getAisles({
          query: {
            sort: '',
            page: 1,
            perPage: 200,
            search: '',
            filters: {
              by_category: content?.categoryId
            }
          }
        })
      );
      dispatch(getSalechannels());
      setFieldsValue({
        title: content?.title,
        category_id: content?.categoryId,
        extra_categories_ids: content?.extraCategoriesIds || [],
        manufacturer_id: {
          key: content?.manufacturerId,
          label: content?.manufacturerName
        },
        description: content?.description || '',
        // video: content?.video || '',
        information: content?.information || '',
        search_slug: content?.searchSlug || '',
        sale_channel_ids: content?.saleChannelIds || [],
        aisle_ids: content?.aisles?.map(item => item.id) || []
        // meta_title: content?.meta_title || '',
        // meta_description: content?.meta_description || '',
      });
      const tagsValue = content?.searchMetadata
        ? content.searchMetadata.split(',')
        : [];
      setTags(tagsValue);
    }
    // eslint-disable-next-line
  }, [content.id]);

  useEffect(() => {
    setManufacturerOptions(manufacturers);
  }, [manufacturers]);

  const formItemRow = structure => {
    return (
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          {structure}
        </Row>
      </Form.Item>
    );
  };

  const renderFormItem = (type, label, tootipdesc, placeholder, value, erp) => {
    const itemType =
      type === 'input' ? (
        <Input
          placeholder={placeholder}
          disabled={value === 'search_slug' ? content?.active : !editPermission}
        />
      ) : (
        <TextArea placeholder={placeholder} disabled={!editPermission} />
      );

    return formItemRow(
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginBottom: 0, fontWeight: 'bold' }}>{label}</p>
          {tootipdesc && (
            <Tooltip placement="right" title={tootipdesc}>
              <Icon style={{ marginLeft: 6 }} type="info-circle" />
            </Tooltip>
          )}
        </div>
        {getFieldDecorator(value, {
          rules: [{ required: false, message: 'Preencha o campo' }]
        })(itemType)}
        {erp && <span className="erp_desc">Estoka: {erp}</span>}
      </>
    );
  };

  const handleTags = (value, action) => {
    setTags(prev => {
      const tagsArr =
        action === 'add'
          ? [...prev, value]
          : prev.filter(item => item !== value);
      return tagsArr;
    });
  };

  const delayedManufacturersQuery = useRef(
    debounce(300, value =>
      dispatch(getManufacturers({ query: { search: value, perPage: 100 } }))
    )
  ).current;

  const handleManufacturersSearch = value => {
    if (value) {
      delayedManufacturersQuery(value);
    } else {
      setManufacturerOptions([]);
    }
  };

  const handleCategorySelect = value => {
    const currentExtraCategories =
      getFieldValue('extra_categories_ids') || content.extraCategoriesIds;
    changeAttributes([value, ...currentExtraCategories]);
    dispatch(
      getAisles({
        query: {
          sort: '',
          page: 1,
          perPage: 200,
          search: '',
          filters: {
            by_category: value
          }
        }
      })
    );
    setFieldsValue({ aisle_ids: [] });
  };

  return (
    <>
      <Form.Item style={{ marginBottom: 10 }}>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ margin: '0 7px 0 0', fontWeight: 'bold' }}>
            Status Produto
          </p>
          {getFieldDecorator('active', {
            valuePropName: 'defaultChecked',
            initialValue: visible
          })(
            <Switch
              onChange={() => setVisible(!visible)}
              disabled={!editPermission}
            />
          )}
        </Row>
      </Form.Item>
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, marginRight: 6, fontWeight: 'bold' }}>
              Canais de venda
            </p>
            <span>(opcional)</span>
            <Tooltip
              placement="right"
              title="A seleção de canais de venda altera a visibilidade dos SKUs"
            >
              <Icon style={{ marginLeft: 6 }} type="info-circle" />
            </Tooltip>
          </div>
          {getFieldDecorator(
            'sale_channel_ids',
            {}
          )(
            <Select
              placeholder="Selecione um ou mais canais de venda"
              disabled={!editPermission}
              showSearch
              filterOption={handleSelectSearch}
              mode="multiple"
              onChange={value => setSalechannel(value)}
            >
              {salechannels.map(item => (
                <Option key={`salechannel-${item.id}`} value={Number(item.id)}>
                  {item.attributes.name}
                </Option>
              ))}
            </Select>
          )}
        </Row>
      </Form.Item>
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
              Código Marketing
            </p>
          </div>
          <Input
            value={content?.internalId || '–'}
            disabled
            style={{ width: '50%' }}
          />
        </Row>
      </Form.Item>
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
              Categoria Principal
            </p>
            <Tooltip
              placement="right"
              title="Selecione a categoria principal do produto"
            >
              <Icon style={{ marginLeft: 6 }} type="info-circle" />
            </Tooltip>
          </div>
          {getFieldDecorator('category_id', {
            rules: [{ required: true, message: 'Selecione uma categoria' }]
          })(
            <Select
              placeholder="Selecione uma categoria"
              onChange={handleCategorySelect}
              disabled={!editPermission}
              showSearch
              filterOption={handleSelectSearch}
              loading={categoriesLoading}
            >
              {departments.map(item => {
                const categoriesArr = categories.filter(
                  obj => obj.attributes.department_id === Number(item.id)
                );
                return (
                  <OptGroup
                    label={item.attributes.name}
                    key={`department-${item.id}`}
                  >
                    {categoriesArr?.map(obj => {
                      return (
                        <Option
                          key={obj.attributes.id}
                          value={obj.attributes.id}
                        >
                          {obj.attributes.name} para {item.attributes.name}
                        </Option>
                      );
                    })}
                  </OptGroup>
                );
              })}
            </Select>
          )}
        </Row>
      </Form.Item>
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, marginRight: 6, fontWeight: 'bold' }}>
              Categorias Secundárias
            </p>
            <span>(opcional)</span>
            <Tooltip
              placement="right"
              title="Selecione outras categorias onde o produto deve aparecer"
            >
              <Icon style={{ marginLeft: 6 }} type="info-circle" />
            </Tooltip>
          </div>
          {getFieldDecorator(
            'extra_categories_ids',
            {}
          )(
            <Select
              placeholder="Selecione uma ou mais categorias"
              disabled={!editPermission}
              showSearch
              filterOption={handleSelectSearch}
              mode="multiple"
              onChange={value => {
                const currentCategoryId =
                  getFieldValue('category_id') || content.categoryId;
                changeAttributes([currentCategoryId, ...value]);
              }}
              loading={categoriesLoading}
            >
              {departments.map(item => {
                const categoriesArr = categories.filter(
                  obj => obj.attributes.department_id === Number(item.id)
                );
                return (
                  <OptGroup
                    label={item.attributes.name}
                    key={`department-${item.id}`}
                  >
                    {categoriesArr?.map(obj => {
                      return (
                        <Option
                          key={obj.attributes.id}
                          value={obj.attributes.id}
                        >
                          {obj.attributes.name} para {item.attributes.name}
                        </Option>
                      );
                    })}
                  </OptGroup>
                );
              })}
            </Select>
          )}
        </Row>
      </Form.Item>
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, marginRight: 6, fontWeight: 'bold' }}>
              Corredores
            </p>
            <span>(opcional)</span>
            <Tooltip
              placement="right"
              title="O produto poderá ser associado apenas aos corredores de sua categoria principal"
            >
              <Icon style={{ marginLeft: 6 }} type="info-circle" />
            </Tooltip>
          </div>
          {getFieldDecorator(
            'aisle_ids',
            {}
          )(
            <Select
              placeholder="Selecione um ou mais corredores"
              disabled={!editPermission}
              showSearch
              filterOption={handleSelectSearch}
              mode="multiple"
              loading={aislesLoading}
            >
              {[...aisles]
                .sort((a, b) =>
                  a.attributes.name.localeCompare(b.attributes.name)
                )
                .map(item => (
                  <Option key={`aisle-${item.id}`} value={item.id}>
                    {item.attributes.name}
                  </Option>
                ))}
            </Select>
          )}
        </Row>
      </Form.Item>
      {renderFormItem(
        'input',
        'Nome do Produto',
        'Nome do produto que irá aparecer no app',
        'Nome do produto',
        'title'
      )}
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Fabricante</p>
          </div>
          {getFieldDecorator('manufacturer_id', {
            rules: [{ required: true, message: 'Selecione um fabricante' }]
          })(
            <Select
              placeholder="Selecione um fabricante"
              disabled={!editPermission}
              showSearch
              allowClear
              labelInValue
              onSearch={handleManufacturersSearch}
              notFoundContent="Digite o nome do fabricante"
              filterOption={false}
            >
              {manufacturerOptions.map(item => (
                <Option key={item.attributes.id} value={item.attributes.id}>
                  {item.attributes.name}
                </Option>
              ))}
            </Select>
          )}
        </Row>
      </Form.Item>
      {renderFormItem(
        'textarea',
        'Descrição',
        'Texto descritivo sobre o produto que irá aparecer no app',
        'Descrição do produto',
        'description'
      )}
      {/* {renderFormItem(
        'input',
        'URL do Video',
        'Video exibido no carrossel de imagens do produto. No momento essa funcionalidade não está ativada',
        'Link',
        'video'
      )} */}
      {renderFormItem(
        'textarea',
        'Composição',
        'Informações que explicam do que o produto é feito ou o que o compõe. Aparece no final da página',
        'Escreva a composição',
        'information'
      )}
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
              Palavras-chave
            </p>
            <Tooltip
              placement="right"
              title="Palavras que facilitam o encontro dos produtos pelo cliente, através da busca"
            >
              <Icon style={{ marginLeft: 6 }} type="info-circle" />
            </Tooltip>
          </div>
          <Tags
            removeTags={value => handleTags(value, 'remove')}
            addTags={value => handleTags(value, 'add')}
            tags={tags}
            newTag
            closable
            module="product"
          />
        </Row>
      </Form.Item>
      {renderFormItem(
        'input',
        'Slug',
        'URL da página de produto na web',
        'Slug do produto',
        'search_slug'
      )}
      <Form.Item style={{ marginBottom: 10 }}>
        <Row
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 0 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
              Código Deep Link
            </p>
          </div>
          <Input
            value={content?.internalId || '–'}
            disabled
            style={{ width: '50%' }}
          />
        </Row>
      </Form.Item>
      {/* {renderFormItem(
        'input',
        'Meta Title',
        'Título do produto para SEO',
        'Exemplo: Nome do produto | Zee.Now',
        'meta_title'
      )}
            {renderFormItem(
        'input',
        'Meta Description',
        'Descrição do produto para SEO',
        'Breve descrição do produto',
        'meta_description'
      )} */}
    </>
  );
};

ProductDetailsForm.defaultProps = {
  content: {
    active: false,
    externalTitle: ''
  },
  getFieldDecorator: () => {},
  getFieldValue: () => {},
  setFieldsValue: () => {},
  changeAttributes: () => {},
  tags: [],
  setTags: () => {},
  setSalechannel: () => {}
};

ProductDetailsForm.propTypes = {
  content: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  getFieldValue: PropTypes.func,
  setFieldsValue: PropTypes.func,
  changeAttributes: PropTypes.func,
  editPermission: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  setTags: PropTypes.func,
  setSalechannel: PropTypes.func
};

export default ProductDetailsForm;
