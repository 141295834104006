import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Button, Col } from 'antd';
import Tables from '@/src/components/Tables/index';
import Searchbar from '@/src/components/Searchbar/Searchbar';

// import BookmarksNav from '@/src/components/Filters/BookmarksNav/index';
// import SaveFilters from '@/src/components/Filters/SaveFilters/index';
import FilteredTags from '@/src/components/Filters/FilteredTags/index';

import FilterMenu from '@/src/pages/Filters/index';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import {
  getCustomers,
  setSort,
  exportCSV,
  setSelectedFilters
} from '@/src/store/modules/customers/slice';

// import {
//   saveAccountBookmark
//   removeAccountBookmark
// } from '@/src/store/modules/account/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

export const CustomersPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { editPermission } = usePermissions();

  const customersPermission = editPermission(modules.CUSTOMERS_VIEW);

  const typeTable = 'customers';

  const exportCSVHandler = () => {
    const params = buildQueryFromUrl(history);
    dispatch(exportCSV({ query: params }));
  };

  const getCustomersHandler = useCallback(
    urlQuery => {
      dispatch(getCustomers({ ...urlQuery }));
    },
    [dispatch]
  );

  // const setPaginationHandler = useCallback(
  //   payload => {
  //     dispatch(setPagination({ ...payload }));
  //   },
  //   [dispatch]
  // );
  const setSortHandler = useCallback(
    payload => {
      dispatch(setSort({ ...payload }));
    },
    [dispatch]
  );
  // const saveBookmarkHandler = useCallback(
  //   payload => {
  //     dispatch(saveAccountBookmark({ ...payload }));
  //   },
  //   [dispatch]
  // );
  // const removeBookmarkHandler = useCallback(
  //   payload => {
  //     dispatch(removeAccountBookmark({ ...payload }));
  //   },
  //   [dispatch]
  // );
  const setSelectedFilterHandler = useCallback(
    payload => {
      dispatch(setSelectedFilters(payload));
    },
    [dispatch]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getCustomersHandler({ query: params });
  }, [getCustomersHandler, history]);

  const {
    isLoading,
    customers,
    count,
    query
    // selectedFilters
  } = useSelector(state => state.customers);

  // const {
  //   bookmarks,
  //   loadingBookmarks
  // } = useSelector(state => state.account);

  // const customersBookmarks = bookmarks.filter(
  //   bookmark => bookmark.attributes.scope === 'Customer'
  // );
  return (
    <>
      <Row type="flex" justify="space-between" align="middle" className="mb-20">
        <Col span={20}>
          <h1 className="m-0">Clientes</h1>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '8px 16px 16px'
        }}
      >
        <Row>
          {/* <Col span={24}>
            <BookmarksNav
              scope="Customers"
              accountBookmarks={customersBookmarks}
              populateItems={getCustomersHandler}
              removeAccountBookmark={removeBookmarkHandler}
              query={query}
              saveAccountBookmark={saveBookmarkHandler}
              selectedFilters={selectedFilters}
            />
          </Col> */}
        </Row>
        <Row type="flex" justify="space-between" gutter={[8, 8]}>
          <Col span={16}>
            <Searchbar
              placeholder="CPF / E-mail / Nome do Cliente / Telefone"
              populateResult={getCustomersHandler}
              query={query}
              value={query.search}
            />
          </Col>
          <Col>
            <FilterMenu filterContent="customer" />
            {customersPermission && (
              <Button
                onClick={exportCSVHandler}
                size="large"
                className="ant-btn-secondary"
              >
                exportar csv
              </Button>
            )}
          </Col>
        </Row>
        <Row type="flex">
          {/* {selectedFilters && (
            <Col>
              <section className="mt-20 mb-20">
                <SaveFilters
                  scope="Customer"
                  accountBookmarks={bookmarks}
                  query={query}
                  loadingBookmarks={loadingBookmarks}
                  saveAccountBookmark={saveBookmarkHandler}
                />
              </section>
            </Col>
          )}  */}
          <Col>
            <section className="mt-20 mb-20">
              <FilteredTags
                query={query}
                populateTable={getCustomersHandler}
                setSelectedFilters={setSelectedFilterHandler}
              />
            </section>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <Tables
              rowKey="id"
              contentToShow={typeTable}
              visiblePaginationUp
              visiblePaginationDown
              showCountFilters
              isLoading={isLoading}
              data={customers}
              history={history}
              count={count}
              query={query}
              setSortState={setSortHandler}
              populateTables={getCustomersHandler}
              rowClassName={record =>
                record?.attributes.banned ? 'banned has-click' : 'has-click'
              }
              rowClick={id => history.push(`/clientes/${id}`)}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CustomersPage;
