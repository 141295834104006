import React, { useEffect, useCallback } from 'react';
import { Row, Col, Card, Spin, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LoginForm from '@/src/components/Login/LoginForm';
import zeenowLoginBg from '@/src/assets/images/login-bg-zeenow.jpg';
import zeedogLoginBg from '@/src/assets/images/skull.jpg';
import logoZeeNow from '@/src/assets/images/logo-zn-positivo.png';
import logoZeeDog from '@/src/assets/images/logo-zeedog.svg';
import { getAccount } from '@/src/store/modules/account/slice';
import { loginAccount } from '@/src/store/modules/login/slice';
import { getToken } from '@/src/services/auth';

import { useBreakpoint } from '@/src/context/BreakpointContext';
import * as modules from '@/src/globals/permissionsModules';
import { ZEENOW_STORE } from '@/src/globals/constants';

export const LoginPage = () => {
  const { isAuthenticated, account, permissions } = useSelector(
    state => state.account
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoint = useBreakpoint();

  const hasToken = getToken();

  const handleSubmit = (email, password) => {
    dispatch(loginAccount({ email, password }));
  };

  const getAccountHandler = useCallback(
    accountId => {
      dispatch(getAccount(accountId));
    },
    [dispatch]
  );

  useEffect(() => {
    if (Object.entries(breakpoint).length === 0) return;
    if (isAuthenticated || hasToken) {
      if (breakpoint.mobile) {
        history.push('/expedicao');
      } else {
        const userPermissions = permissions
          .filter(item => item.kind === 'edit')
          .map(item => item.name);
        const firstModule = modules.data.find(
          item => item.permission === userPermissions[0]
        );
        history.push(firstModule?.url || '/pedidos');
      }
      const userId = account?.id || localStorage.getItem('USER_ID');
      if (!isAuthenticated) getAccountHandler(userId);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, breakpoint.mobile]);

  useEffect(() => {
    notification.destroy();
  }, []);

  return (
    <>
      {!isAuthenticated && !hasToken ? (
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            height: '100vh',
            background: `linear-gradient(
              ${
                ZEENOW_STORE
                  ? 'rgba(51, 214, 239, 0.9),rgba(51, 214, 239, 0.9)'
                  : 'rgba(125, 125, 125, 0.9),rgba(125, 125, 125, 0.9)'
              }),
              url(${ZEENOW_STORE ? zeenowLoginBg : zeedogLoginBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <Col xs={20} md={10} lg={8}>
            <Card
              cover={
                <>
                  <img
                    alt={ZEENOW_STORE ? 'Logo Zee.Now' : 'Logo Zee.Dog'}
                    src={ZEENOW_STORE ? logoZeeNow : logoZeeDog}
                    style={{
                      width: ZEENOW_STORE ? '116px' : '100px',
                      margin: '32px auto 12px'
                    }}
                  />
                </>
              }
            >
              <LoginForm handleSubmit={handleSubmit} />
            </Card>
          </Col>
        </Row>
      ) : (
        <Spin
          style={{
            background: 'rgba(255, 255, 255, 0.8)',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
      )}
    </>
  );
};

export default LoginPage;
