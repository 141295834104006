import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Menu,
  Col,
  Card,
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Spin,
  Empty,
  Tooltip
} from 'antd';

import { getCustomFields } from '@/src/store/modules/custom-fields/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import validateJson from '@/src/utils/validateJson';

const ProductCustomFields = ({
  customFields,
  setCustomFields,
  categoryId,
  getFieldDecorator
}) => {
  const dispatch = useDispatch();

  const { customFields: data, isLoading } = useSelector(
    state => state.customFields
  );

  const { editPermission } = usePermissions();
  const customFieldsPermission = editPermission(modules.CUSTOM_FIELDS);

  useEffect(() => {
    if (categoryId) {
      const payload = {
        query: {
          perPage: 200,
          filters: {
            by_category: categoryId
          }
        }
      };
      dispatch(getCustomFields(payload));
    }
  }, [dispatch, categoryId]);

  useEffect(() => {
    setCustomFields(prev =>
      prev.filter(item => data.find(field => field.id === item.id))
    );
    // eslint-disable-next-line
  }, [data]);

  const fieldsRef = useRef([]);

  const handleFieldsRef = element => {
    if (element) {
      const hasEl = fieldsRef.current.find(input => input.id === element.id);
      if (hasEl) {
        fieldsRef.current = fieldsRef.current.map(input => {
          if (input.id === hasEl.id) return element;
          return input;
        });
      } else {
        fieldsRef.current.push(element);
      }
    }
  };

  const handleAddCustomField = option => {
    const { id, attributes } = option;
    const type = attributes.field_type;
    const media = attributes.media_type;
    const label = data.find(item => item.id === id).attributes.name;
    setCustomFields([...customFields, { id, label, type, media }]);
  };

  const handleRemoveCustomField = id => {
    setCustomFields(customFields.filter(item => item.id !== id));
  };

  const handleClickRemove = id => {
    const input = fieldsRef.current.find(field => field.props.id === id);
    if (input?.state?.value) {
      Modal.confirm({
        title: 'REMOVER CAMPO CUSTOMIZADO',
        okText: 'Sim',
        cancelText: 'Não',
        centered: true,
        content:
          'Tem certeza que deseja remover este campo? Você poderá associá-lo novamente, mas o valor preenchido será perdido.',
        onOk() {
          handleRemoveCustomField(id);
        },
        onCancel() {}
      });
    } else {
      handleRemoveCustomField(id);
    }
  };

  const handleInputValue = e => {
    const {
      value,
      dataset: { id }
    } = e.target;
    setCustomFields(
      customFields.map(item => {
        if (item.id === id) {
          return { ...item, value };
        }
        return item;
      })
    );
  };

  const menu =
    isLoading || !data.length ? (
      <div
        className="ant-dropdown-menu"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {isLoading ? (
          <Spin size="small" style={{ padding: 12 }} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    ) : (
      <Menu>
        {[...data]
          .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
          .map(item => (
            <Menu.Item
              key={item.id}
              onClick={e => handleAddCustomField(item)}
              disabled={customFields.find(
                field => field.id.toString() === item.id
              )}
            >
              {item.attributes.name}
            </Menu.Item>
          ))}
      </Menu>
    );

  return (
    <Col className="no-shadow" style={{ marginBottom: 7 }}>
      <Card
        className="no-shadow no-border custom-fields-card"
        title={
          <>
            <h2>CAMPOS CUSTOMIZADOS</h2>
            <span style={{ marginBottom: 0 }}>
              Campos disponíveis na categoria do produto. Caso não encontre o
              campo desejado, você pode criar um novo campo ou associar um campo
              existente na página da{' '}
              {categoryId ? (
                <Link
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                  to={`/categorias/${categoryId}`}
                >
                  categoria
                </Link>
              ) : (
                'categoria'
              )}
            </span>
          </>
        }
      >
        <Tooltip
          placement="right"
          title={
            customFieldsPermission
              ? ''
              : 'Apenas usuários com permissão podem editar campos customizados'
          }
        >
          <Dropdown
            overlay={menu}
            trigger={['click']}
            disabled={!customFieldsPermission}
          >
            <Button
              className="ant-btn ant-btn-link"
              style={{
                color: '#000000D9',
                padding: '0'
              }}
              disabled={!customFieldsPermission}
            >
              <Icon type="plus-circle" />
              Adicionar campo
            </Button>
          </Dropdown>
        </Tooltip>
        <Form>
          {customFields.map(item => (
            <Form.Item style={{ width: '50%', marginBottom: 10 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                  {item.label}
                  <span style={{ marginLeft: 4, fontWeight: 'normal' }}>
                    (
                    {[...item.media]
                      .sort((a, b) => a.localeCompare(b))
                      .map(type => {
                        if (type === 'web') return 'Site';
                        if (type === 'mobile') return 'App';
                        return type;
                      })
                      .join('/')}
                    )
                  </span>
                </p>
                <Button
                  type="link"
                  style={{ color: '#000000A6', transform: 'translateX(16px)' }}
                  disabled={!customFieldsPermission}
                  onClick={() => handleClickRemove(item.id)}
                >
                  <Icon type="delete" />
                </Button>
              </div>
              {item.type === 'text'
                ? getFieldDecorator(`custom-field-${item.id}`, {
                    rules: [
                      {
                        required: true,
                        message: 'Campo obrigatório'
                      }
                    ],
                    initialValue: item.value
                  })(
                    <Input
                      data-id={item.id}
                      ref={handleFieldsRef}
                      disabled={!customFieldsPermission}
                      onChange={handleInputValue}
                      placeholder="Digite o valor no formato de texto"
                    />
                  )
                : getFieldDecorator(`custom-field-${item.id}`, {
                    validateFirst: true,
                    rules: [
                      {
                        required: true,
                        message: 'Campo obrigatório'
                      },
                      {
                        validator: (rules, value) => validateJson(value),
                        message: 'Entrada inválida'
                      }
                    ],
                    initialValue: item.value
                  })(
                    <Input.TextArea
                      data-id={item.id}
                      style={{
                        maxWidth: 'unset',
                        resize: 'both',
                        transition: 'scale 0s'
                      }}
                      ref={handleFieldsRef}
                      disabled={!customFieldsPermission}
                      onChange={handleInputValue}
                      placeholder="Digite o valor no formato JSON"
                    />
                  )}
            </Form.Item>
          ))}
        </Form>
      </Card>
    </Col>
  );
};

ProductCustomFields.defaultProps = {
  customFields: [],
  setCustomFields: () => {},
  categoryId: undefined,
  getFieldDecorator: () => {}
};

ProductCustomFields.propTypes = {
  customFields: PropTypes.array,
  setCustomFields: PropTypes.func,
  categoryId: PropTypes.number,
  getFieldDecorator: PropTypes.func
};

export default ProductCustomFields;
