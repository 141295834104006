import { useDispatch } from 'react-redux';
import { getUpdateProduct } from '@/src/store/modules/product-details/slice';

const useSaveProductEdits = () => {
  const dispatch = useDispatch();
  const removeData = dataString =>
    dataString.replace(/data:image\/[\w]+;base64,/g, '');

  // eslint-disable-next-line
  const formatImageUpload = (image, index = false) => {
    if (image.filename) {
      return {
        image: removeData(image.url),
        filename: image.filename,
        sort: index + 1,
        metadata: image.metadata || ''
      };
    }
    if (index !== false) {
      return {
        id: image.id,
        sort: index + 1,
        metadata: image.metadata || ''
      };
    }
  };

  const saveAllEdits = (
    id,
    newProductValues,
    thumbnail,
    mainImages,
    largeImages,
    experienceImages,
    ifoodImages,
    imagesToDelete,
    propertiesSelected,
    propertiesUnselected,
    customFields,
    skuChanges,
    hubSkus
  ) => {
    const values = Object.keys(newProductValues).reduce((acc, key) => {
      if (!key.startsWith('custom-field')) {
        acc[key] = newProductValues[key];
      }
      return acc;
    }, {});

    const newThumb = thumbnail?.length ? formatImageUpload(thumbnail[0]) : {};

    const images = mainImages?.length
      ? mainImages
          .map((image, index) => formatImageUpload(image, index))
          .filter(image => image)
      : [];
    const large_images = largeImages?.length
      ? largeImages
          .map((image, index) => formatImageUpload(image, index))
          .filter(image => image)
      : [];
    const experience_images = experienceImages?.length
      ? experienceImages
          .map((image, index) => formatImageUpload(image, index))
          .filter(image => image)
      : [];
    const ifood_images = ifoodImages?.length
      ? ifoodImages
          .map((image, index) => formatImageUpload(image, index))
          .filter(image => image)
      : [];
    const manufacturer_id = newProductValues.manufacturer_id.key;
    const aisle_ids = newProductValues.aisle_ids.map(aisle => Number(aisle));
    const custom_fields = customFields.map(field => ({
      id: Number(field.id),
      value: field.value
    }));

    window.scrollTo(0, 0);
    dispatch(
      getUpdateProduct({
        ...values,
        id,
        thumb: newThumb,
        images,
        large_images,
        experience_images,
        ifood_images,
        image_ids: imagesToDelete,
        selected_property_value_ids: propertiesSelected,
        unselected_property_value_ids: propertiesUnselected,
        // later remove this line
        is_there_any_breed_suggestion: false,
        skus: skuChanges,
        hub_skus_attributes: hubSkus,
        manufacturer_id,
        aisle_ids,
        custom_fields
      })
    );
  };

  return { saveAllEdits };
};

export default useSaveProductEdits;
