/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Row, Button, Tooltip, Icon, Switch, Avatar, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import {
  getHighlights,
  updateHighlight,
  getOrderByDragNDrop,
  setHighlights
} from '@/src/store/modules/highlights/slice';

import { getCategories } from '@/src/store/modules/categories/slice';

import Tables from '@/src/components/Tables';

import { translateHighlightsLabel } from '@/src/utils/localeData';
import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import dragIcon from '@/src/assets/images/drag-n-drop.svg';

const { TabPane } = Tabs;

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

function Highlights() {
  const history = useHistory();
  const dispatch = useDispatch();
  const typeTable = 'highlights';

  const archived = {
    archived: true
  };

  const notArchived = {
    not_archived: true
  };

  const { highlights, isLoading, count, query } = useSelector(
    state => state.highlights
  );
  const { categories } = useSelector(state => state.categories);

  const getHighlightsHandler = useCallback(
    urlQuery => {
      dispatch(getHighlights({ ...urlQuery }));
    },
    [dispatch]
  );

  const changeTabs = evt => {
    const params = {
      ...query,
      filters: evt === '1' ? notArchived : archived
    };
    getHighlightsHandler({ query: params });
  };

  useEffect(() => {
    const filters = {
      filters: history.location.search.includes('filters[archived]')
        ? archived
        : notArchived
    };
    const params = buildQueryFromUrl(history, filters);
    getHighlightsHandler({ query: params });
    dispatch(getCategories({ query: { perPage: 1000 } }));
    return () => {
      dispatch(
        setHighlights({
          highlights: [],
          count: 0,
          query: {
            page: 1,
            perPage: 20,
            url: ''
          }
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  const manager = useRef(RNDContext);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = highlights[dragIndex];
      const data = update(highlights, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow]
        ]
      });
      dispatch(setHighlights({ highlights: data, count, query }));
      dispatch(getOrderByDragNDrop(data.map(item => item.id)));
    },
    // eslint-disable-next-line
    [highlights]
  );

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      render: () => <img src={dragIcon} alt="" style={{ fontSize: 20 }} />
    },
    {
      title: 'Imagem',
      align: 'center',
      width: '20%',
      dataIndex: 'attributes',
      render: record => (
        <Link
          to={`/destaques/${record.id}`}
          style={{
            border: '0',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'transparent',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          <Avatar
            shape="square"
            size={60}
            src={record.image}
            icon="camera"
            className="img-placeholder"
          />
        </Link>
      )
    },
    {
      title: 'Tipo',
      align: 'left',
      dataIndex: 'attributes.highlightable_type',
      render: record => (
        <span>{translateHighlightsLabel[record?.toLowerCase()]}</span>
      )
    },
    {
      title: 'Nome',
      align: 'left',
      dataIndex: 'attributes.highlightable_name',
      render: (record, data) => {
        if (data.attributes.highlightable_type.toLowerCase() === 'category') {
          const category = categories?.find(
            item =>
              item.attributes.internal_id === data.attributes.highlightable_id
          );
          const department = category?.attributes?.department_name;
          if (department && record)
            return (
              <Link
                to={`/destaques/${data.id}`}
                style={{
                  textDecoration: 'underline',
                  color: 'rgba(0, 0, 0, 0.65)'
                }}
              >
                <span>{`${record} para ${department}`}</span>
              </Link>
            );
        }
        return (
          <Link
            to={`/destaques/${data.id}`}
            style={{
              textDecoration: 'underline',
              color: 'rgba(0, 0, 0, 0.65)'
            }}
          >
            <span>{record || '-'}</span>
          </Link>
        );
      }
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Status</span>
          <Tooltip
            placement="top"
            overlayClassName="tooltip-table"
            title="Este controle torna o destaque visível no aplicativo."
          >
            <Icon type="info-circle" style={{ marginLeft: '5px' }} />
          </Tooltip>
        </div>
      ),
      align: 'center',
      dataIndex: 'attributes',
      key: 'table-status',
      render: record => {
        return (
          <Switch
            defaultChecked={record.visibility}
            onChange={e => {
              const payload = {
                id: record.id,
                data: {
                  visibility: e
                }
              };
              dispatch(updateHighlight(payload));
            }}
          />
        );
      }
    }
  ];

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginBottom: 20 }}>
        <div>
          <h1 style={{ marginBottom: 0 }}>Destaques</h1>
        </div>
        <div>
          <Button
            className="ant-btn ant-btn-primary"
            onClick={() => history.push('/destaques/novo')}
          >
            Novo Destaque
          </Button>
        </div>
      </Row>
      <div className="section-wrapper">
        <Tabs
          onChange={changeTabs}
          style={{ marginBottom: 20 }}
          type="card"
          defaultActiveKey={
            history.location.search.includes('filters[archived]') ? '2' : '1'
          }
        >
          <TabPane tab="Ativos" key="1" />
          <TabPane tab="Arquivados" key="2" />
        </Tabs>
        <hr style={{ margin: '-20px 0 20px' }} />
        <DndProvider manager={manager.current.dragDropManager}>
          <Tables
            data={highlights}
            className="even-gray"
            columnsDefaults={columns}
            count={count}
            query={query}
            visiblePaginationUp
            visiblePaginationDown
            rowKey={record => record.id}
            contentToShow={typeTable}
            history={history}
            isLoading={isLoading}
            populateTables={getHighlightsHandler}
            components={components}
            onRow={(record, index) => ({
              index,
              moveRow
            })}
          />
        </DndProvider>
      </div>
    </>
  );
}

export default Highlights;
