import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  // Button,
  Modal,
  // List,
  // Input,
  Row,
  Card,
  Radio
} from 'antd';

import { getCustomerCreditCards } from '@/src/store/modules/customers/slice';
// import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';
import style from '@/src/pages/Subscriptions/Subscriptions.module.scss';

// const { Search } = Input;

const CreditCardModal = props => {
  const dispatch = useDispatch();

  const [aux, setAux] = useState({});

  const {
    showCreditCardModal,
    setShowCreditCardModal,
    customer,
    setEditNewCreditCard,
    editNewCreditCard,
    subscriptionCreditCard
  } = props;

  const { creditCard } = useSelector(state => {
    return state.customers;
  });

  const getCreditCardsHandler = useCallback(
    urlQuery => {
      if (customer.id)
        dispatch(getCustomerCreditCards({ ...urlQuery, id: customer.id }));
    },
    [dispatch, customer]
  );

  useEffect(() => {
    if (customer.id) {
      getCreditCardsHandler({ query: { page: 1, perPage: 10 } });
    }
    // eslint-disable-next-line
  }, [customer]);

  const handleSelect = id => {
    setAux(editNewCreditCard);
    const selected = creditCard.filter(card => id === card.attributes.id)[0];
    setEditNewCreditCard({ ...selected.attributes });
  };

  const handleOnOk = () => {
    setShowCreditCardModal(false);
  };
  return (
    <Modal
      title="ALTERAR CARTÃO"
      visible={showCreditCardModal}
      width={400}
      height={600}
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      className="centered-footer"
      // footer={null}
      onOk={() => handleOnOk()}
      onCancel={() => {
        if (aux?.id) setEditNewCreditCard(aux);
        setShowCreditCardModal(false);
      }}
    >
      <Row>
        {/* <Search
        placeholder="Buscar pelos últimos números do cartão"
        size="large"
        className="ant-searchbar ant-searchbar-list"
        onSearch={e => handleSearch(e, 'associated')}
        allowClear
      /> */}
      </Row>
      <Row type="flex">
        <Radio.Group
          name="cards"
          onChange={e => handleSelect(e.target.value)}
          defaultValue={subscriptionCreditCard.id}
          value={editNewCreditCard.id}
          className={style.creditCardModal__radio}
          size="large"
        >
          {creditCard.map(card => {
            const { attributes } = card;
            return (
              // <>
              <Card
                size="small"
                className={style.creditCardModal__card}
                style={{ marginBottom: 18 }}
                key={attributes.id}
              >
                <Radio key={attributes.id} value={attributes.id}>
                  <div className={style.creditCardModal__radio_item}>
                    <p>XXXX XXXX XXXX {attributes?.number}</p>
                    <span>Nome: {attributes?.name}</span>
                    <span>Bandeira: {attributes?.card_type}</span>
                    <span>Expiração: {attributes?.expiration}</span>
                  </div>
                </Radio>
              </Card>
              // </>
            );
          })}
        </Radio.Group>
      </Row>
    </Modal>
  );
};

CreditCardModal.propTypes = {
  showCreditCardModal: PropTypes.bool.isRequired,
  setShowCreditCardModal: PropTypes.func.isRequired,
  customer: PropTypes.any.isRequired,
  editNewCreditCard: PropTypes.any.isRequired,
  setEditNewCreditCard: PropTypes.func.isRequired,
  subscriptionCreditCard: PropTypes.any.isRequired
};

export default CreditCardModal;
