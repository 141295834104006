import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon, Skeleton, Button } from 'antd';

import { ZEENOW_STORE } from '@/src/globals/constants';
import useDispatchModal from '@/src/hooks/useDispatchModal';
import StatusPill from '@/src/components/StatusPill';
import RejectReturnModal from '@/src/components/Expedition/Modal/RejectReturn';
import { handleStatusPill } from '@/src/utils/dispatchUtils';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import styles from '../CustomDrawer.module.scss';

import handleHeaderTable from './columns';

const Header = ({ data, title, table, hasAlert, loading }) => {
  const headerTable = handleHeaderTable(data, table);

  const [requestBtn, setRequestBtn] = useState(false);
  const [rejectReturnModal, setRejectReturnModal] = useState(false);

  const { handleShowModal } = useDispatchModal();
  const { editPermission } = usePermissions();

  useEffect(() => {
    if (!ZEENOW_STORE) return;
    if (table === 'expedition') {
      if (editPermission(modules.DISPATCH_ORDERS_VIEW)) {
        setRequestBtn(false);
      } else {
        const hasRequest = data.hasCancelationRequest || data.hasReturnRequest;
        setRequestBtn(hasRequest);
      }
    } else {
      setRequestBtn(false);
    }
    // eslint-disable-next-line
  }, [data, table]);

  return (
    <>
      <RejectReturnModal
        order={data}
        visible={rejectReturnModal}
        setVisible={setRejectReturnModal}
      />
      <div className={styles.CustomDrawer__header}>
        {hasAlert && (
          <Icon
            type={data.status === 'canceled' ? 'close-square' : 'warning'}
            theme="outlined"
            style={{
              color: `${data.status === 'canceled' ? '#ff365c' : '#fdab02'}`,
              fontSize: '16px',
              marginRight: '10px',
              fontWeight: 'bold'
            }}
          />
        )}
        <h2>{title}</h2>
        <Skeleton
          active
          title={false}
          loading={loading}
          paragraph={{ rows: 1, width: 80 }}
        >
          <StatusPill
            status={handleStatusPill(data).status}
            steps={handleStatusPill(data).steps}
          />
        </Skeleton>
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          style={{ marginTop: 16 }}
        >
          <Col>
            <Row
              type="flex"
              align="middle"
              gutter={40}
              className={styles.CustomDrawer__summary}
            >
              {headerTable.map(col => (
                <Col
                  key={`customDrawerHeader-${col[0]}`}
                  className={styles['CustomDrawer__summary--col']}
                >
                  <h3>{col[0]}</h3>
                  <Skeleton
                    active
                    title={false}
                    loading={loading}
                    paragraph={{ rows: 1, width: 80 }}
                  >
                    <span>{col[1]}</span>
                  </Skeleton>
                </Col>
              ))}
            </Row>
          </Col>
          {ZEENOW_STORE && requestBtn && data?.hub?.workflow !== 'petz' && (
            <Col style={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                style={{ marginBottom: 8 }}
                className="ant-btn-secondary"
                onClick={() => setRejectReturnModal(true)}
              >
                {data.hasReturnRequest
                  ? 'Rejeitar devolução'
                  : 'Rejeitar cancelamento'}
              </Button>
              <Button
                className="ant-btn"
                onClick={() => handleShowModal(data, '')}
              >
                {data.hasReturnRequest
                  ? 'Concluir devolução'
                  : 'Concluir cancelamento'}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

Header.defaultProps = {
  hasAlert: false,
  loading: false
};

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  table: PropTypes.string.isRequired,
  hasAlert: PropTypes.bool,
  loading: PropTypes.bool
};

export default Header;
