import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  orders: [],
  lastOrdersCustomer: [],
  subscriptionsCustomer: [],
  subscriptionsQuery: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  subscriptionsCount: 0,
  count: 0,
  total: 0,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    daysAgo: '1',
    filters: {}
  },
  hubs: [],
  deliverers: [],
  coupons: [],
  selectedHubs: {},
  selectedDeliverers: {},
  selectedCoupons: [],
  selectedFilters: false,
  isLoading: false
};

const mountSelectedCoupons = filtersObj => {
  const couponsArray = [];
  if (filtersObj) {
    Object.keys(filtersObj).forEach(key => {
      if (key === 'by_coupons') {
        filtersObj[key].forEach(code => {
          couponsArray.push(code);
        });
      }
    });
  }
  return couponsArray;
};

const ordersSlice = createSlice({
  name: '@orders',
  initialState,
  reducers: {
    setOrders(prevState, action) {
      const state = prevState;
      state.orders = action.payload.orders;
      state.count = action.payload.count;
      state.total = action.payload.total;
      state.selectedHubs = action.payload.hub_names || {};
      state.selectedDeliverers = action.payload.deliverer_names || {};
      state.selectedCoupons =
        mountSelectedCoupons(action.payload.query.filters) || [];
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        daysAgo: action.payload.query.daysAgo || state.query.daysAgo,
        search: action.payload.query.search || '',
        sort: action.payload.query.sort || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}&days_ago=${
          action.payload.query.daysAgo
        }${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }${
          action.payload.query.sort !== ''
            ? `&sort${action.payload.query.sort}`
            : ''
        }${
          action.payload.query.filters
            ? buildQueryStringFromObj(action.payload.query.filters)
            : ''
        }`
      };
      state.isLoading = false;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setSearchTerm(prevState, action) {
      const state = prevState;
      state.query.search = action.payload;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSubscriptionPagination(prevState, action) {
      const state = prevState;
      state.subscriptionsQuery.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    setSubscriptionSort(prevState, action) {
      const state = prevState;
      state.subscriptionsQuery.sort = action.payload;
      return state;
    },
    setFilter(prevState, action) {
      const state = prevState;
      state.query.filters = {
        ...state.query.filters,
        ...action.payload
      };
      return state;
    },
    setHubs(prevState, action) {
      const state = prevState;
      state.hubs = action.payload;
      return state;
    },
    setDeliverers(prevState, action) {
      const state = prevState;
      state.deliverers = action.payload;
      return state;
    },
    setCoupons(prevState, action) {
      const state = prevState;
      state.coupons = action.payload;
      return state;
    },
    setSelectedFilters(prevState, action) {
      const state = prevState;
      state.selectedFilters = action.payload;
      return state;
    },
    setLastOrderCustomer(prevState, action) {
      const state = prevState;
      state.lastOrdersCustomer = action.payload.lastOrdersCustomer;
      state.count = action.payload.count;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        daysAgo: action.payload.query.daysAgo || state.query.daysAgo,
        search: action.payload.query.search || '',
        sort: action.payload.query.sort || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}&days_ago=${
          action.payload.query.daysAgo
        }${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }${
          action.payload.query.sort !== ''
            ? `&sort${action.payload.query.sort}`
            : ''
        }`
      };
      state.isLoading = false;
      return state;
    },
    setSubscriptionsCustomer(prevState, action) {
      const state = prevState;
      state.subscriptionsCustomer = action.payload.subscriptionsCustomer;
      state.subscriptionsCount = action.payload.count;
      state.subscriptionsQuery = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        search: action.payload.query.search || '',
        sort: action.payload.query.sort || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}&days_ago=${
          action.payload.query.daysAgo
        }${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }${
          action.payload.query.sort !== ''
            ? `&sort${action.payload.query.sort}`
            : ''
        }`
      };
      state.isLoading = false;
      return state;
    },
    goToOrder(state) {
      return state;
    },
    getCoupons(state) {
      return state;
    },
    getDeliverers(state) {
      return state;
    },
    getHubs(state) {
      return state;
    },
    getOrders(state) {
      return state;
    },
    getLastOrders(state) {
      return state;
    },
    getSubscriptions(state) {
      return state;
    },
    exportCSV(state) {
      return state;
    }
  }
});

export const {
  getOrders,
  getHubs,
  getDeliverers,
  getCoupons,
  goToOrder,
  getLastOrders,
  getSubscriptions,
  setSelectedFilters,
  setCoupons,
  setDeliverers,
  setHubs,
  setFilter,
  setSort,
  setSubscriptionSort,
  setPagination,
  setSubscriptionPagination,
  setSearchTerm,
  setIsLoading,
  setOrders,
  setLastOrderCustomer,
  setSubscriptionsCustomer,
  exportCSV
} = ordersSlice.actions;

export default ordersSlice.reducer;
