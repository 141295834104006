import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import {
  Col,
  Row,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Radio,
  Select,
  Button,
  Divider
} from 'antd';
import PropTypes from 'prop-types';

import moment from '@/src/services/moment';

import { createPromotion } from '@/src/store/modules/promotions/slice';

import { getProducts } from '@/src/store/modules/products/slice';

import { getPromotion } from '@/src/store/modules/promotion-details/slice';

import { getCategories } from '@/src/store/modules/categories/slice';
import { getManufacturers } from '@/src/store/modules/manufacturers/slice';

import formatCurrency from '@/src/utils/formatCurrency';
import percentageFormatter from '@/src/utils/percentageFormatter';
import getNumberFromString from '@/src/utils/getNumberFromString';
import isImmutablePromo from '@/src/utils/isImmutablePromo';

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const NewPromotion = props => {
  const dispatch = useDispatch();

  const { products } = useSelector(state => state.products);
  const { categories } = useSelector(state => state.categories);
  const { manufacturers } = useSelector(state => state.manufacturers);
  const { promotion, isLoading } = useSelector(state => state.promotion);

  const { form, editPromo, promoId } = props;
  const { getFieldDecorator } = form;

  const [applicabilityValue, setApplicabilityValue] = useState(0);
  const [applicabilityType, setApplicabilityType] = useState('everything');
  const [applicabilityOptions, setApplicabilityOptions] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [discountType, setDiscountType] = useState('');

  const onChangeStartDatetime = value => {
    const formattedDate = moment(value).format();
    setStartDate(formattedDate);
  };
  const onChangeEndDatetime = value => {
    const formattedDate = moment(value).format();
    setEndDate(formattedDate);
  };

  const handleApplicabilityTypeChange = value => {
    setApplicabilityType(value);
    form.setFieldsValue({
      applicability_name: undefined
    });
  };

  useEffect(() => {
    switch (applicabilityType) {
      case 'product':
        setApplicabilityOptions(products);
        break;
      case 'category':
        setApplicabilityOptions(categories);
        break;
      case 'manufacturer':
        setApplicabilityOptions(manufacturers);
        break;
      default:
        break;
    }
  }, [products, categories, manufacturers, applicabilityType]);

  const delayedProductsQuery = useRef(
    debounce(200, value => dispatch(getProducts({ query: { search: value } })))
  ).current;

  const delayedCategoriesQuery = useRef(
    debounce(200, value =>
      dispatch(getCategories({ query: { search: value } }))
    )
  ).current;

  const delayedManufacturersQuery = useRef(
    debounce(200, value =>
      dispatch(getManufacturers({ query: { search: value } }))
    )
  ).current;

  const handleApplicabilityValueSearch = value => {
    switch (applicabilityType) {
      case 'product':
        delayedProductsQuery(value);
        break;
      case 'category':
        delayedCategoriesQuery(value);
        break;
      case 'manufacturer':
        delayedManufacturersQuery(value);
        break;
      default:
        break;
    }
  };

  const handleApplicabilityValueChange = value => {
    setApplicabilityValue(value);
  };

  const handleDiscountType = target => {
    setDiscountType(target.target.value);
  };

  const formatValue = value => {
    if (discountType === 'fixed_value') return formatCurrency(value);
    return percentageFormatter(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      const data = {
        start_at:
          typeof startDate === 'string' && startDate === 'Invalid date'
            ? null
            : startDate,
        end_at:
          typeof endDate === 'string' && endDate === 'Invalid date'
            ? null
            : endDate,
        applicability_id: Number(applicabilityValue),
        applicability_type: applicabilityType
      };
      const formValues = values;

      if (formValues.activation_limit === '') formValues.activation_limit = 0;
      if (formValues.first_n_orders === '') formValues.first_n_orders = 0;
      if (formValues.use_limit === '') formValues.use_limit = 0;

      delete formValues.applicability_name;

      if (applicabilityValue && applicabilityValue.length === 0) {
        delete data.applicability_value;
      }

      const promoObj = {
        ...formValues,
        ...data
      };

      if (!err) {
        if (!editPromo) {
          dispatch(createPromotion({ ...promoObj }));
        } else {
          dispatch(getPromotion({ promoId, promoObj }));
        }
      }
    });
  };
  useEffect(() => {
    if (editPromo && promotion.name) {
      const startDateInit = promotion.start_at
        ? moment(promotion.start_at)
        : null;
      const endDateInit = promotion.end_at ? moment(promotion.end_at) : null;

      setStartDate(startDateInit);
      setEndDate(endDateInit);
      setDiscountType(promotion.discount_type);
      setApplicabilityType(promotion.applicability_type);
      setApplicabilityValue(promotion.discountable_id);

      const checkIfZero = value => (value === 0 ? '' : value);

      form.setFieldsValue({
        name: promotion.name,
        description: promotion.description,
        information: promotion.information,
        start_at: startDateInit,
        end_at: endDateInit,
        discount_type: promotion.discount_type,
        discount_value: promotion.discount_value,
        activation_limit: checkIfZero(promotion.activation_limit),
        applicability_type: promotion.applicability_type,
        applicability_name: promotion.category_name
          ? `${promotion.applicability_name} para ${promotion.category_name}`
          : promotion.applicability_name,
        first_n_orders: checkIfZero(promotion.first_n_orders),
        use_limit: checkIfZero(promotion.use_limit),
        minimum_order_value: promotion.minimum_order_value
      });
    }
    // eslint-disable-next-line
  }, [promotion]);

  let formContent;

  const canEditPromo = !isImmutablePromo(promotion);

  if (editPromo) {
    formContent = (
      <Form onSubmit={handleSubmit}>
        <Row type="flex" gutter={4}>
          <Col span={8}>
            <Form.Item colon={false} label="Nome Promoção">
              {getFieldDecorator('name')(
                <Input placeholder="Exemplo: Promo" disabled={!canEditPromo} />
              )}
            </Form.Item>
            <Form.Item colon={false} label="Título (visível para o cliente)">
              {getFieldDecorator('information')(
                <Input
                  placeholder="Exemplo: Bem-vindo à Zee.Now!"
                  disabled={!canEditPromo}
                />
              )}
            </Form.Item>
            <Form.Item colon={false} label="Descrição (visível para o cliente)">
              {getFieldDecorator('description')(
                <TextArea
                  rows={3}
                  placeholder="Exemplo: Agradecemos sua participação"
                  disabled={!canEditPromo}
                />
              )}
            </Form.Item>
            <Row type="flex" align="bottom" gutter={8}>
              <Col span={12}>
                <Form.Item colon={false} label="Vigência">
                  {getFieldDecorator('start_at')(
                    <DatePicker
                      disabled={!canEditPromo}
                      showTime={{ format: 'HH:mm' }}
                      format="DD/MM/YYYY - HH:mm"
                      placeholder="Data - Hora início"
                      onChange={onChangeStartDatetime}
                      style={{ minWidth: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item colon={false}>
                  {getFieldDecorator('end_at')(
                    <DatePicker
                      disabled={!canEditPromo}
                      showTime={{ format: 'HH:mm' }}
                      format="DD/MM/YYYY - HH:mm"
                      placeholder="Data - Hora fim"
                      onChange={onChangeEndDatetime}
                      style={{ minWidth: '100%' }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider
              style={{ height: '100%', display: 'block', margin: '0 auto' }}
              type="vertical"
            />
          </Col>
          <Col span={6}>
            <p
              style={{
                color: 'rgba(0, 0, 0, 0.85)',
                marginBottom: 12,
                lineHeight: 1
              }}
            >
              <strong>Valor Desconto</strong>
            </p>
            <Row type="flex" align="middle" justify="start">
              <Form.Item colon={false}>
                {getFieldDecorator('discount_type')(
                  <Radio.Group
                    onChange={handleDiscountType}
                    disabled={!canEditPromo}
                  >
                    <Radio value="fixed_value" className="radio-squared">
                      $
                    </Radio>
                    <Radio value="percentage" className="radio-squared">
                      %
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item colon={false}>
                {getFieldDecorator('discount_value')(
                  <InputNumber
                    disabled={!canEditPromo}
                    placeholder="Valor"
                    min={0}
                    step={1}
                    formatter={value => formatValue(value)}
                    parser={value => getNumberFromString(value)}
                    style={{ width: 100 }}
                  />
                )}
              </Form.Item>
            </Row>
            <Form.Item colon={false} label="Aplicabilidade">
              {getFieldDecorator('applicability_type')(
                <Select
                  onChange={handleApplicabilityTypeChange}
                  disabled={!canEditPromo}
                >
                  <OptGroup label="Catálogo">
                    <Option value="everything">Toda loja</Option>
                    <Option value="product">Produto</Option>
                    <Option value="category">Categoria</Option>
                    <Option value="manufacturer">Fabricante</Option>
                  </OptGroup>
                </Select>
              )}
            </Form.Item>
            <Form.Item colon={false} label="Valor">
              {getFieldDecorator('applicability_name')(
                <Select
                  disabled={applicabilityType === 'everything' || !canEditPromo}
                  showSearch
                  placeholder="Buscar"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleApplicabilityValueSearch}
                  onChange={handleApplicabilityValueChange}
                  dropdownMatchSelectWidth={false}
                >
                  {applicabilityOptions.map(item => {
                    if (applicabilityType === 'category') {
                      return (
                        <Option key={item.id}>
                          {item.attributes.name} para{' '}
                          {item.attributes.department_name}
                        </Option>
                      );
                    }
                    return (
                      <Option key={item.id}>
                        {item.attributes.title || item.attributes.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item colon={false} label="Valor mínimo da compra">
              {getFieldDecorator('minimum_order_value')(
                <InputNumber
                  placeholder="Valor"
                  min={0}
                  disabled={!canEditPromo}
                  formatter={value => formatCurrency(value)}
                  parser={value => getNumberFromString(value)}
                  style={{ width: 120 }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            <Divider
              style={{ height: '100%', display: 'block', margin: '0 auto' }}
              type="vertical"
            />
          </Col>
          <Col span={8}>
            <Form.Item
              colon={false}
              label="Máximo de ativações da promoção por cupom"
              extra="Vazio caso não exista restrição"
            >
              {getFieldDecorator('activation_limit')(
                <Input placeholder="N° de pessoas" disabled={!canEditPromo} />
              )}
            </Form.Item>
            <Form.Item
              colon={false}
              label="Primeiras N compras do cliente"
              extra="Vazio caso não exista restrição"
            >
              {getFieldDecorator('first_n_orders')(
                <Input placeholder="N° de compras" disabled={!canEditPromo} />
              )}
            </Form.Item>
            <Form.Item
              colon={false}
              label="Número máximo de uso por cliente"
              extra="Vazio caso não exista restrição"
            >
              {getFieldDecorator('use_limit')(
                <Input placeholder="Máximo de uso" disabled={!canEditPromo} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" align="top">
          <Col span={4}>
            <Form.Item colon={false} style={{ marginBottom: 0 }}>
              <Button
                style={{ width: '100%' }}
                htmlType="submit"
                // type="primary"
                loading={isLoading}
                disabled={!canEditPromo}
              >
                Salvar alterações
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  } else {
    formContent = (
      <Form onSubmit={handleSubmit}>
        <Row style={{ maxWidth: 500 }}>
          <Col span={24}>
            <Form.Item colon={false} label="Nome Promoção">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Nome é obrigatório' }]
              })(<Input placeholder="Exemplo: Promo" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ maxWidth: 500 }}>
          <Col span={24}>
            <Form.Item colon={false} label="Título (visível para o cliente)">
              {getFieldDecorator('information', {
                rules: [{ required: true, message: 'Título é obrigatório' }]
              })(<Input placeholder="Exemplo: Bem-vindo à Zee.Now!" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ maxWidth: 500 }}>
          <Col span={24}>
            <Form.Item colon={false} label="Descrição (visível para o cliente)">
              {getFieldDecorator('description', {
                rules: [{ required: true, message: 'Descrição é obrigatória' }]
              })(
                <TextArea
                  rows={3}
                  placeholder="Exemplo: Agradecemos sua participação"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col span={16}>
            <Form.Item colon={false} label="Vigência">
              <DatePicker
                showTime={{
                  format: 'HH:mm',
                  defaultValue: moment().startOf('date')
                }}
                format="DD/MM/YYYY - HH:mm"
                placeholder="Data - Hora início"
                onChange={onChangeStartDatetime}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <Form.Item colon={false}>
              <DatePicker
                showTime={{
                  format: 'HH:mm',
                  defaultValue: moment().startOf('date')
                }}
                format="DD/MM/YYYY - HH:mm"
                placeholder="Data - Hora fim"
                onChange={onChangeEndDatetime}
              />
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <Row>
          <p
            style={{
              color: 'rgba(0, 0, 0, 0.85)',
              marginBottom: 12,
              lineHeight: 1
            }}
          >
            <strong>Valor Desconto</strong>
          </p>
        </Row>
        <Row style={{ maxWidth: 800 }}>
          <Col span={3}>
            <Form.Item colon={false}>
              {getFieldDecorator('discount_type', {
                rules: [
                  {
                    required: true,
                    message: 'Tipo do desconto é obrigatório'
                  }
                ]
              })(
                <Radio.Group onChange={handleDiscountType}>
                  <Radio value="fixed_value" className="radio-squared">
                    $
                  </Radio>
                  <Radio value="percentage" className="radio-squared">
                    %
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item colon={false}>
              {getFieldDecorator('discount_value', {
                rules: [
                  {
                    required: true,
                    message: 'Valor do desconto é obrigatório'
                  }
                ]
              })(
                <InputNumber
                  placeholder="Valor"
                  min={0}
                  formatter={value => formatValue(value)}
                  parser={value => getNumberFromString(value)}
                  style={{ width: 100, marginLeft: 10 }}
                  disabled={discountType.length === 0}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <hr />

        <Row gutter={16}>
          <Col span={6}>
            <Form.Item colon={false} label="Aplicabilidade">
              <Select
                defaultValue={applicabilityType}
                onChange={handleApplicabilityTypeChange}
              >
                <OptGroup label="Catálogo">
                  <Option value="everything">Toda loja</Option>
                  <Option value="product">Produto</Option>
                  <Option value="category">Categoria</Option>
                  <Option value="manufacturer">Fabricante</Option>
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
          {applicabilityType !== 'everything' && (
            <Col span={6}>
              <Form.Item colon={false} label="Valor">
                {getFieldDecorator('applicability_value', {
                  rules: [
                    {
                      required: true,
                      message: 'Valor da Aplicabilidade é obrigatória'
                    }
                  ]
                })(
                  <Select
                    showSearch
                    placeholder="Buscar"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleApplicabilityValueSearch}
                    onChange={handleApplicabilityValueChange}
                    dropdownMatchSelectWidth={false}
                  >
                    {applicabilityOptions.map(item => {
                      if (applicabilityType === 'category') {
                        return (
                          <Option key={item.id}>
                            {item.attributes.name} para{' '}
                            {item.attributes.department_name}
                          </Option>
                        );
                      }
                      return (
                        <Option key={item.id}>
                          {item.attributes.title || item.attributes.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item colon={false} label="Valor mínimo da compra">
              {getFieldDecorator('minimum_order_value')(
                <InputNumber
                  placeholder="Valor"
                  min={0}
                  formatter={value => formatCurrency(value)}
                  parser={value => getNumberFromString(value)}
                  style={{ width: 100 }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col span={10}>
            <Form.Item
              colon={false}
              label="Máximo de ativações da promoção por cupom"
              extra="Vazio caso não exista restrição"
            >
              {getFieldDecorator('activation_limit')(
                <Input style={{ width: '220px' }} placeholder="N° de pessoas" />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              colon={false}
              label="Primeiras N compras do cliente"
              extra="Vazio caso não exista restrição"
            >
              {getFieldDecorator('first_n_orders')(
                <Input placeholder="N° de compras" />
              )}
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col span={8}>
            <Form.Item
              colon={false}
              label="Número máximo de uso por cliente"
              extra="Vazio caso não exista restrição"
            >
              {getFieldDecorator('use_limit')(
                <Input style={{ width: '220px' }} placeholder="Máximo de uso" />
              )}
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <Row>
          {/* <Col span={4} offset={16}>
            <Form.Item colon={false}>
              <Button style={{ width: '100%' }} type="secondary">
                Cancelar
              </Button>
            </Form.Item>
          </Col> */}
          <Col span={4} offset={20}>
            <Form.Item colon={false} style={{ margin: 0 }}>
              <Button
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Salvar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  return <>{formContent}</>;
};

const NewPromoForm = Form.create({ name: 'newPromoForm' })(NewPromotion);

NewPromotion.defaultProps = {
  editPromo: false,
  promoId: ''
};
NewPromotion.propTypes = {
  promoId: PropTypes.string,
  editPromo: PropTypes.bool,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

export default NewPromoForm;
