import React from 'react';
import PropTypes from 'prop-types';
import { Table, Select, Pagination, Row, Col, Icon } from 'antd';
import { Link } from 'react-router-dom';
import moment from '@/src/services/moment';

import formatCPF from '@/src/utils/formatCPF';
import formatCurrency from '@/src/utils/formatCurrency';
// import orderStatusInfo from '@/src/globals/orderStatusInfo';
import StatusPill from '@/src/components/StatusPill';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import { ZEENOW_STORE } from '@/src/globals/constants';

const { Option } = Select;

const OrdersTable = props => {
  const {
    orders,
    count,
    total,
    query,
    history,
    isLoading,
    populateOrdersTable,
    className
  } = props;
  const { routePermission } = usePermissions();
  const customersPermission = routePermission(modules.CUSTOMERS_VIEW);

  const columns = [
    {
      title: 'Incidência',
      key: 'alerts',
      align: 'center',
      render: record => {
        if (record.hasAlert) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Icon type="warning" theme="filled" style={{ fontSize: 14 }} />
              <span style={{ marginLeft: 5 }}>
                {record.orderAlert?.createdAt
                  ? moment(record.orderAlert.createdAt).format('HH:mm')
                  : ''}
              </span>
            </div>
          );
        }
        if (record.orderAlert?.alertResolved) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Icon
                type="check-circle"
                theme="filled"
                style={{ color: '#61edae', fontSize: 16 }}
              />
              <span style={{ marginLeft: 5 }}>
                {record.orderAlert?.updatedAt
                  ? moment(record.orderAlert.updatedAt).format('HH:mm')
                  : ''}
              </span>
            </div>
          );
        }
        return '';
      }
    },
    {
      title: '# do Pedido',
      dataIndex: 'number',
      key: 'number',
      render: (text, record) => (
        <Link
          data-test="order-number"
          to={`/pedidos/${record.id}`}
          style={{
            border: '0',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'transparent',
            color: 'inherit'
          }}
        >
          {text}
        </Link>
      )
    },
    {
      title: 'Data/Hora',
      dataIndex: 'createdAt',
      key: 'created_at',
      sorter: true,
      render: createdAt => moment(createdAt).format('DD-MM-YY / HH:mm')
    },
    {
      title: 'Nome Cliente',
      dataIndex: 'customerName',
      // key: 'customer_name',
      sorter: true,
      render: (text, record) => {
        if (customersPermission) {
          return text ? (
            <Link
              to={`/clientes/${record.customerId}`}
              style={{
                border: '0',
                textDecoration: 'underline',
                cursor: 'pointer',
                background: 'transparent',
                color: 'inherit'
              }}
            >
              {text}
            </Link>
          ) : (
            <span>-</span>
          );
        }
        return text || '-';
      }
    },
    {
      title: 'CPF',
      dataIndex: 'customerDocument',
      // key: 'customer_document',
      render: document => {
        return document ? formatCPF(document) : '-';
      }
    },
    {
      title: 'Itens',
      dataIndex: 'itemsCount',
      key: 'items_count',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Cupom',
      dataIndex: 'coupon',
      key: 'coupon',
      sorter: true,
      render: coupon => {
        return coupon || '-';
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      render: totalValue => formatCurrency(totalValue)
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      render: status => <StatusPill status={status} centered steps={3} />
    }
  ];

  if (ZEENOW_STORE) {
    const znColumns = [
      {
        title: 'Hub',
        dataIndex: 'hubName',
        key: 'hub_name',
        sorter: true,
        render: hub => {
          return hub || '-';
        }
      },
      {
        title: 'Entregador',
        dataIndex: 'delivererName',
        key: 'deliverer_name',
        sorter: true,
        render: deliverer => {
          return deliverer || '-';
        }
      }
    ];
    columns.splice(6, 0, ...znColumns);
  }

  const columnSort = () => {
    const urlQuery = history.location.search;
    if (urlQuery.includes('&sort')) {
      const sort = urlQuery.split('&sort')[1];
      const sortParams = {
        column: sort
          .split('=')[0]
          .replace('[', '')
          .replace(']', ''),
        order: sort
          .split('=')[1]
          .replace('desc', 'descend')
          .replace('asc', 'ascend')
      };
      columns.forEach(el => {
        if (el.key === sortParams.column) {
          Object.assign(el, { defaultSortOrder: sortParams.order });
        }
      });
    }
  };
  columnSort();

  const handleDaysAgoChange = value =>
    populateOrdersTable({
      query: {
        ...query,
        page: 1,
        daysAgo: value
      }
    });

  const changePagination = (page, perPage) =>
    populateOrdersTable({
      query: {
        ...query,
        page,
        perPage
      }
    });
  const changePerPage = (current, perPage) =>
    populateOrdersTable({
      query: {
        ...query,
        page: query.perPage !== perPage ? 1 : current,
        perPage
      }
    });
  return (
    <>
      <Row
        type="flex"
        gutter={16}
        justify="space-between"
        style={{ marginBottom: 20 }}
      >
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="table-results">
            {orders.length > 0 && (
              <p data-test-id="filtered-orders-length">
                <span>{count}</span>{' '}
                {count === 1 ? 'pedido filtrado ' : 'pedidos filtrados '}
                <span>({formatCurrency(total)})</span>
              </p>
            )}
          </div>
        </Col>
        <Col>
          <Row type="flex" gutter={16}>
            <Col>
              <Select
                style={{ width: 150 }}
                value={query.daysAgo}
                onChange={handleDaysAgoChange}
              >
                <Option value="all">Todos</Option>
                <Option value="1">Do dia</Option>
                <Option value="7">Últimos 7 dias</Option>
                <Option value="30">Últimos 30 dias</Option>
                <Option value="60">Últimos 2 meses</Option>
                <Option value="90">Últimos 3 meses</Option>
                <Option value="180">Últimos 6 meses</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                showSizeChanger
                onChange={changePagination}
                onShowSizeChange={changePerPage}
                pageSize={query.perPage}
                pageSizeOptions={['10', '20', '30', '50', '100']}
                total={count}
                current={query.page}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`orders-table ${className}`}
        data-test="orders-table"
        columns={columns}
        dataSource={orders}
        loading={isLoading}
        size="medium"
        rowKey={record => record.id}
        pagination={false}
        onChange={(pagination, filter, sorter) => {
          const hasSortOrder = () => {
            return `[${sorter.columnKey}]=${sorter.order.replace('end', '')}`;
          };
          const onChangeQuery = {
            query: {
              ...query,
              page: 1,
              sort: sorter.order ? hasSortOrder() : ''
            }
          };
          populateOrdersTable(onChangeQuery);
        }}
        style={{ paddingTop: isLoading ? '4rem' : '0' }}
        rowClassName={record => {
          if (record?.fraudulent) return 'banned';
          if (record?.hasAlert) return 'has-alert';
          return '';
        }}
      />
      <Row type="flex" gutter={16} justify="end" style={{ marginTop: 20 }}>
        <Col>
          <Pagination
            showSizeChanger
            onChange={changePagination}
            onShowSizeChange={changePerPage}
            pageSize={query.perPage}
            pageSizeOptions={['10', '20', '30', '50', '100']}
            total={count}
            current={query.page}
          />
        </Col>
      </Row>
    </>
  );
};

OrdersTable.defaultProps = {
  orders: [],
  count: 0,
  total: 0,
  query: {
    page: 1,
    perPage: 20,
    daysAgo: '1',
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  isLoading: false,
  className: ''
};

OrdersTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({})),
  count: PropTypes.number,
  total: PropTypes.number,
  query: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    daysAgo: PropTypes.string,
    search: PropTypes.string,
    sort: PropTypes.string,
    url: PropTypes.string,
    filters: PropTypes.shape({})
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string
    })
  }).isRequired,
  isLoading: PropTypes.bool,
  populateOrdersTable: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default OrdersTable;
