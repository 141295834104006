import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Row, Card, Radio } from 'antd';

import { getCustomerAddresses } from '@/src/store/modules/customers/slice';
import style from '@/src/pages/Subscriptions/Subscriptions.module.scss';

const AddressModal = props => {
  const dispatch = useDispatch();

  const [aux, setAux] = useState({});
  const [defaultAddress, setDefaultAddress] = useState({});

  const {
    showAddressesModal,
    setShowAddressesModal,
    customer,
    editNewAddress,
    setEditNewAddress,
    subscriptionAddress
    // setSubscriptionAddress
  } = props;

  const { address: addresses } = useSelector(state => {
    return state.customers;
  });

  const getAdressesHandler = useCallback(() => {
    if (customer.id) dispatch(getCustomerAddresses(customer.id));
  }, [dispatch, customer]);

  useEffect(() => {
    if (customer.id) {
      getAdressesHandler();
    }
    // eslint-disable-next-line
  }, [customer]);

  const handleSelect = id => {
    setAux(editNewAddress);
    const selected = addresses.filter(
      address => id === address.attributes.id
    )[0];
    setEditNewAddress({ ...selected.attributes });
  };

  const getDefaultSelected = () => {
    const selected = addresses.filter(
      address =>
        address.attributes.street === subscriptionAddress.street &&
        address.attributes.zipcode === subscriptionAddress.zipcode &&
        address.attributes.number === subscriptionAddress.number
    )[0];

    if (selected) setEditNewAddress(selected?.attributes);
    setDefaultAddress(selected);
  };

  useEffect(() => {
    if (subscriptionAddress) getDefaultSelected();
    // eslint-disable-next-line
  }, [addresses]);

  const handleOnOk = () => {
    // setSubscriptionAddress(editNewAddress);
    setShowAddressesModal(false);
  };

  return (
    <Modal
      title="ALTERAR ENDEREÇO"
      visible={showAddressesModal}
      width={400}
      height={600}
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      className="centered-footer"
      // footer={null}
      onOk={() => handleOnOk()}
      onCancel={() => {
        if (aux?.id) setEditNewAddress(aux);
        setShowAddressesModal(false);
      }}
    >
      <Row />
      <Row type="flex">
        <Radio.Group
          name="cards"
          onChange={e => handleSelect(e.target.value)}
          defaultValue={defaultAddress?.id}
          value={editNewAddress?.id}
          className={style.creditCardModal__radio}
          size="large"
        >
          {addresses.map(address => {
            const { attributes } = address;
            return (
              // <>
              <Card
                size="small"
                className={style.creditCardModal__card}
                style={{ marginBottom: 18 }}
                key={attributes.id}
              >
                <Radio key={attributes.id} value={attributes.id}>
                  <div className={style.creditCardModal__radio_item}>
                    {/* <span style={{ fontWeight: 'bold' }}>
                      {attributes?.nickname}
                    </span> */}
                    <span style={{ fontWeight: 'bold' }}>
                      {attributes?.street}, {attributes?.number}{' '}
                    </span>
                    <span>
                      {attributes?.city} - {attributes?.state} -{' '}
                      {attributes?.zipcode}{' '}
                    </span>
                  </div>
                </Radio>
              </Card>
              // </>
            );
          })}
        </Radio.Group>
      </Row>
    </Modal>
  );
};

AddressModal.propTypes = {
  showAddressesModal: PropTypes.bool.isRequired,
  setShowAddressesModal: PropTypes.func.isRequired,
  customer: PropTypes.any.isRequired,
  subscriptionAddress: PropTypes.any.isRequired,
  editNewAddress: PropTypes.any.isRequired,
  setEditNewAddress: PropTypes.func.isRequired
  // setSubscriptionAddress: PropTypes.func.isRequired
};

export default AddressModal;
