import { presentDay } from '@/src/utils/dateUtils';

export const linesQuery = {
  query: {
    include: 'order_requests,hub',
    filters: [
      { key: 'updated_at', type: 'gte', value: presentDay },
      { key: 'scheduled', type: 'eq', value: true },
      { key: 'status', type: 'not_eq', value: 'delivered' }
    ],
    fields: [
      {
        key: 'orders',
        value:
          'id,number,status,created_at,has_alert,has_return_request,has_cancelation_request,total,has_eta_alert,eta_max_value,sale_invoice_number,sale_invoice_pdf,scheduled,deliver_at,embark_require_invoice,customer_name,address_street,address_number,address_complement,address_district,address_city,address_state,address_zipcode,volume_count,volumes_ids,item_count,hub_name,sale_channel_name,user_name,user_image_url,deliverer_name,deliverer_image_url,remarks,pickup_code'
      },
      { key: 'order_requests', value: 'id,kind,request_resolved,rejected' },
      {
        key: 'hubs',
        value: 'id,workflow'
      }
    ],
    extra_fields: [
      {
        key: 'orders',
        value: 'customer_today_orders_count'
      }
    ],
    sort: 'created_at',
    perPage: 300
  }
};

export const linesHubQuery = {
  query: {
    include: 'order_requests',
    filters: [
      // { key: 'status', type: 'not_eq', value: 'canceled' },
      { key: 'updated_at', type: 'gte', value: presentDay },
      { key: 'scheduled', type: 'eq', value: true },
      { key: 'status', type: 'not_eq', value: 'delivered' }
    ],
    fields: [
      {
        key: 'orders',
        value:
          'id,number,has_eta_alert,eta_max_value,created_at,updated_at,has_alert,has_return_request,has_cancelation_request,total,status,sale_invoice_number,sale_invoice_pdf,customer_name,address_street,address_number,address_complement,address_district,address_city,address_state,address_zipcode,volume_count,item_count,hub_name,sale_channel_name,user_name,user_image_url'
      }
    ],
    extra_fields: [
      {
        key: 'orders',
        value: 'customer_today_orders_count'
      }
    ],
    sort: 'created_at',
    perPage: 300
  }
};

export const userQuery = id => {
  return {
    query: {
      ...linesQuery.query,
      filters: [
        ...linesQuery.query.filters,
        { key: 'user_id', type: 'eq', value: id }
      ]
    }
  };
};

export const waitingQuery = {
  query: {
    ...linesQuery.query,
    filters: [
      ...linesQuery.query.filters,
      { key: 'status', type: 'eq', value: 'waiting' }
    ],
    perPage: 100
  }
};

// export const emptyQuery = {
//   query: {
//     filters: [],
//     page: 1,
//     perPage: 20,
//     stats: [{ key: 'total', value: 'count' }]
//   }
// };

// export const countQuery = {
//   query: {
//     filters: [{ key: 'sale_channel_id', type: 'eq', value: '2' }],
//     fields: [{ key: 'orders', value: 'id' }],
//     stats: [{ key: 'total', value: 'count' }]
//   }
// };

export const tableQuery = {
  query: {
    include: 'order_requests',
    filters: [],
    fields: [
      {
        key: 'orders',
        value:
          'number,created_at,updated_at,total,status,customer_name,hub_name,sale_channel_name,user_name'
      },
      { key: 'order_requests', value: 'id,kind,request_resolved' }
    ],
    sort: '-updated_at',
    page: 1,
    perPage: 20,
    stats: [{ key: 'total', value: 'count' }]
  }
};

export const drawerQuery = {
  query: {
    include: 'order_items,trackings,order_invoices,order_requests,hub',
    // necessário quando abre modal de devolução
    // order_requests.returned_items.order_item
    fields: [
      {
        key: 'orders',
        value:
          'id,number,created_at,has_alert,has_return_request,has_cancelation_request,status,total,subtotal,service_fee,discount,shipping,sale_invoice_number,sale_invoice_pdf,scheduled,deliver_at,customer_name,address_street,address_number,address_complement,address_district,address_city,address_state,address_zipcode,hub_name,sale_channel_name,user_name,user_image_url,remarks,pickup_code,volumes_ids'
      },
      { key: 'order_requests', value: 'id,kind,request_resolved' },
      {
        key: 'order_items',
        value:
          'id,sku_code,sku_external_id,sku_ean,sku_name,sku_image,quantity,base_price,list_price,returned_quantity,missing_quantity,lost_quantity,restock_quantity,gift'
      },
      {
        key: 'order_invoices',
        value: 'id,number,pdf_url'
      },
      {
        key: 'hubs',
        value: 'id,workflow'
      }
    ],
    extra_fields: [
      {
        key: 'orders',
        value: 'customer_today_orders_count'
      }
    ]
  }
};

export const drawerQueryByNumber = orderNumber => {
  return {
    query: {
      ...drawerQuery.query,
      filters: [{ key: 'search', type: 'match', value: orderNumber }],
      sort: '-created_at',
      page: 1,
      perPage: 1
    }
  };
};
