import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Calendar, Radio, Button, InputNumber } from 'antd';
import moment from '@/src/services/moment';
import style from '@/src/pages/Subscriptions/Subscriptions.module.scss';

const FrequencyModal = props => {
  const [isCustomFrequency, setIsCustomFrequency] = useState(false);
  const [calendarRange, setCalendarRange] = useState(false);
  const [isInRange, setIsInRange] = useState(true);
  const {
    showFrequencyModal,
    setShowFrequencyModal,
    editNewBillingDate,
    setEditNewBillingDate,
    editNewFrequency,
    setEditNewFrequency,
    subscriptionInterval,
    subscriptionNextBilling,
    setSubscriptionNextBilling,
    setSubscriptionInterval
  } = props;

  const handleOnOk = () => {
    setSubscriptionNextBilling(editNewBillingDate);
    setSubscriptionInterval(editNewFrequency);
    setShowFrequencyModal(false);
  };

  const handleCancel = () => {
    setEditNewBillingDate(subscriptionNextBilling);
    setEditNewFrequency(subscriptionInterval);
    setShowFrequencyModal(false);
  };

  const freqOptions = [15, 20, 30, 45, 60, 90, 105, 120];

  const checkIfIsInRange = (currentDate, range) => {
    return moment(currentDate).isBetween(range[0], range[1]);
  };

  useEffect(() => {
    if (subscriptionNextBilling) {
      const range = [
        moment(new Date()).add(1, 'days'),
        moment(new Date())
          .add(1, 'days')
          .add(editNewFrequency, 'days')
      ];
      setCalendarRange(range);

      setIsInRange(checkIfIsInRange(editNewBillingDate, range));
    }
    // eslint-disable-next-line
  }, [editNewFrequency, editNewBillingDate]);

  return (
    <Modal
      title="ALTERAR FREQUÊNCIA"
      visible={showFrequencyModal}
      width={350}
      height={600}
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      className={`centered-footer ${style.frequencyModal}`}
      // footer={null}
      okButtonProps={{
        disabled: !isInRange
      }}
      onOk={() => handleOnOk()}
      onCancel={() => handleCancel()}
    >
      <p className={style.frequencyModal__title}>
        1. Escolha a nova data de cobrança
      </p>
      <Row type="flex" style={{ marginBottom: 20, justifyContent: 'center' }}>
        <Calendar
          mode="month"
          fullscreen={false}
          defaultValue={moment(subscriptionNextBilling)}
          value={moment(editNewBillingDate)}
          validRange={calendarRange}
          onChange={e => setEditNewBillingDate(moment(e).format('YYYY-MM-DD'))}
        />
        <p style={{ fontSize: 12 }}>
          Data de cobrança:
          <span
            style={
              subscriptionNextBilling !== editNewBillingDate
                ? { fontWeight: 'bold', marginLeft: 4, color: '#33d6ef' }
                : { marginLeft: 4 }
            }
          >
            {moment(editNewBillingDate).format('DD/MM/YYYY')}
          </span>
        </p>
        {!isInRange && (
          <span style={{ fontSize: 12, color: '#ff365c' }}>
            A data selecionada está fora da frequência atual.
          </span>
        )}
      </Row>

      <p className={style.frequencyModal__title}>
        2. Escolha a nova frequência de envio
      </p>
      <Row type="flex">
        {isCustomFrequency ? (
          <InputNumber
            value={editNewFrequency}
            placeholder="Insira a frequência"
            onChange={e => {
              setEditNewFrequency(e);
            }}
          />
        ) : (
          <Radio.Group
            name="frequency"
            onChange={e => setEditNewFrequency(e.target.value)}
            defaultValue={subscriptionInterval}
            value={editNewFrequency}
            className={style.frequencyModal__radio}
            size="large"
          >
            {freqOptions.map(option => (
              <Radio.Button key={option} value={option}>
                {option}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      </Row>
      <Row style={{ width: '100%' }}>
        <Button
          type="link"
          style={{
            textTransform: 'none',
            padding: 0,
            fontWeight: 'normal',
            color: '#33d6ef'
          }}
          onClick={() => setIsCustomFrequency(!isCustomFrequency)}
        >
          {isCustomFrequency
            ? 'Ver frequências sugeridas'
            : 'Inserir outro valor'}
        </Button>
      </Row>
    </Modal>
  );
};

FrequencyModal.propTypes = {
  showFrequencyModal: PropTypes.bool.isRequired,
  setShowFrequencyModal: PropTypes.func.isRequired,
  setSubscriptionNextBilling: PropTypes.func.isRequired,
  setSubscriptionInterval: PropTypes.func.isRequired,
  editNewBillingDate: PropTypes.string.isRequired,
  setEditNewBillingDate: PropTypes.func.isRequired,
  editNewFrequency: PropTypes.number.isRequired,
  setEditNewFrequency: PropTypes.func.isRequired,
  subscriptionInterval: PropTypes.number.isRequired,
  subscriptionNextBilling: PropTypes.string.isRequired
};

export default FrequencyModal;
