import React, { useState } from 'react';
import {
  Select,
  Button,
  Form,
  Card,
  Divider,
  Icon,
  Tooltip,
  Alert,
  Dropdown,
  Menu
} from 'antd';
import PropTypes from 'prop-types';

import CustomFieldModal from '@/src/components/CustomFields/Modal/index';

import { handleSelectSearch } from '@/src/utils/searchUtils';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

const { Option } = Select;

const CategoryCustomFields = ({ data, value, setValue, editPage }) => {
  const [showModal, setShowModal] = useState('');

  const { editPermission } = usePermissions();
  const customFieldsPermission = editPermission(modules.CUSTOM_FIELDS);

  const cardExtra = (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="0" onClick={() => setShowModal('edit')}>
            Editar
          </Menu.Item>
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      <CustomFieldModal
        content={showModal}
        setContent={setShowModal}
        data={data}
      />
      <Card
        className="no-shadow"
        title="CAMPOS CUSTOMIZADOS"
        bordered={false}
        style={{ flexGrow: 1 }}
        extra={customFieldsPermission ? cardExtra : null}
      >
        <p>
          Selecione os campos customizados disponíveis para produtos desta
          categoria. Se necessário, cadastre um novo campo.
        </p>
        {editPage && (
          <span
            style={{
              display: 'block',
              margin: '8px 0 18px'
            }}
          >
            <Alert
              message="Ao remover um campo já
                    associado e salvar a alteração, ele também será removido
                    de todos os produtos da categoria."
              type="info"
              className="properties-alert"
            />
          </span>
        )}
        <Form.Item>
          <Tooltip
            placement="bottomLeft"
            title={
              customFieldsPermission
                ? ''
                : 'Apenas usuários com permissão podem editar campos customizados'
            }
          >
            <Select
              placeholder="Buscar pelo nome"
              mode="multiple"
              value={value}
              onChange={e => setValue(e)}
              disabled={!customFieldsPermission}
              filterOption={handleSelectSearch}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: '0' }} />
                  <Button
                    type="link"
                    className="ant-btn-select"
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setShowModal('new')}
                  >
                    <Icon type="plus-circle" /> Novo Campo
                  </Button>
                </>
              )}
            >
              {[...data]
                .sort((a, b) =>
                  a.attributes.name.localeCompare(b.attributes.name)
                )
                .map(item => (
                  <Option key={item.id}>{item.attributes.name}</Option>
                ))}
            </Select>
          </Tooltip>
        </Form.Item>
      </Card>
    </>
  );
};

CategoryCustomFields.defaultProps = {
  data: [],
  value: [],
  setValue: () => {},
  editPage: false
};

CategoryCustomFields.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func,
  editPage: PropTypes.bool
};

const CategoryCustomFieldsForm = Form.create({ name: 'CategoryCustomFields' })(
  CategoryCustomFields
);

export default React.memo(CategoryCustomFieldsForm);
