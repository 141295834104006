/* eslint-disable prefer-destructuring */
function getSelectedId(value, selected_values) {
  const selectedID = selected_values.filter(selected => {
    const valuePropertyId = value.propertyId || value.property_id;
    const selectedPropertyId = selected.propertyId || selected.property_id;
    return (
      valuePropertyId === selectedPropertyId && value.value === selected.value
    );
  });

  if (selectedID?.length) {
    return selectedID[0]?.id;
  }

  return null;
}

export function normalizeHubPrice(hub, priceHubs, priceTable) {
  if (!priceHubs || !priceTable) return {};
  let filter_price = priceHubs.filter(skuItem => skuItem.hub_id === hub.hub_id);

  if (!filter_price[0].price.length) return {};

  filter_price = filter_price[0].price[0];

  const priceTableFiltered = priceTable.filter(
    price => price.id === filter_price.price_table_id
  )[0];
  return {
    active: priceTableFiltered.active,
    table_name: priceTableFiltered.name,
    discount: filter_price.discount,
    list_price: filter_price.list_price,
    sale_price: filter_price.sale_price
  };
}

export default function skuRelationshipList(dataList, relation, type) {
  const idList = dataList.map(item => item.id);

  const relationArray = relation.filter(item => {
    const itemExist = idList.filter(id => id === item.id);
    // type === 'hubs_sku'
    //   ? idList.filter(id => Number(id) === item.attributes.sku_internal_id)
    //   : idList.filter(id => id === item.id);
    return itemExist.length;
  });

  if (type === 'hubs_sku') {
    return relationArray.map(hub => {
      return hub.attributes;
    });
  }

  return relationArray.map(sku => {
    return { id: sku.id, attributes: sku.attributes };
  });
}

export function handleProperties(productDetails, attributesData) {
  const { selectedValues } = productDetails;
  if (attributesData) {
    const newProperties = [];

    attributesData.forEach(attributeData => {
      attributeData.attributes.allowed_values.forEach(allowedValue => {
        newProperties.push({
          id: allowedValue.id,
          property_id: allowedValue.property_id,
          selected_id: getSelectedId(allowedValue, selectedValues),
          allowedValue: allowedValue.value
        });
      });
    });
    return newProperties;
  }
  return [];
}

export function skuRelatioshipSaga(productObj, item, hubsSkus, isMultiple) {
  const product = productObj;
  const priceHubs = item.attributes.prices_hubs;
  const priceTable = item.attributes.price_table;

  const hubsRelated = skuRelationshipList(
    product.relationships.hub_skus.data,
    hubsSkus,
    'hubs_sku'
  );

  const hubsAvailable = hubsRelated.filter(
    hub => hub.sku_internal_id === Number(item.attributes.internal_id)
  );
  let stockQuantity = 0;
  let stockAvailable = 0;
  let stockReserved = 0;

  hubsAvailable.forEach((hub, index) => {
    stockQuantity += hub.stock_quantity || 0;
    stockAvailable += hub.available_quantity || 0;
    stockReserved += hub.reserved_quantity || 0;
    hubsAvailable[index].hub_price = normalizeHubPrice(
      hub,
      priceHubs,
      priceTable
    );
  });

  return {
    ...item,
    hubsAvailable,
    stockQuantity,
    stockAvailable,
    stockReserved
  };
}
