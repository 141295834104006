import React, { useState, useEffect } from 'react';
import {
  Alert,
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  Menu,
  Icon,
  Modal,
  Spin,
  Form,
  Breadcrumb
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Audit from '@/src/components/Audit/index';

import Permissions from '@/src/pages/Users/components/Permissions';
import UserForm from '@/src/pages/Users/components/UserForm';

import {
  setUser,
  getUser,
  updateUser,
  updateUserBan
} from '@/src/store/modules/users/slice';

import { getRecoverPassword } from '@/src/store/modules/forgot-password/slice';

import { getHubs } from '@/src/store/modules/hubs-data/slice';

import { formatDDDPhone } from '@/src/utils/formatPhone';
import buildJsonData from '@/src/utils/buildJsonData';

import styles from '../Users.module.scss';

const { confirm } = Modal;

const User = ({ match, form }) => {
  const { validateFields, getFieldValue } = form;

  const { userId } = useParams();
  const dispatch = useDispatch();

  const [permissionsVisible, setPermissionsVisible] = useState([]);

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [editUser, setEditUser] = useState(false);
  const [userHubs, setUserHubs] = useState('');
  const [hubs, setHubs] = useState([]);
  const [showAudit, setShowAudit] = useState(false);

  const { user, userIsLoading, userPermissions } = useSelector(
    state => state.users
  );

  const hubsList = useSelector(state => state.hubsData.list);

  const permissionsKind = {
    office: 'Administrador',
    stockist: 'Estoquista',
    deliverer: 'Entregador',
    tv: 'TV'
  };

  useEffect(() => {
    return () => {
      dispatch(setUser({}));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      getHubs({
        query: {
          fields: [{ key: 'hubs', value: 'id,name,internal_id' }],
          perPage: 500,
          sort: 'name'
        }
      })
    );
    dispatch(getUser(match.params.userId));
    // eslint-disable-next-line
  }, [match.params.userId]);

  useEffect(() => {
    if (hubs.length && user.hubs) {
      if (user.hubs === 'all') {
        setUserHubs('Todos');
      } else {
        const userHubsArr = user.hubs?.split(',') || [];
        const userHubsName = userHubsArr.map(hub => {
          const hubItem = hubs.find(item => item.internalId === hub);
          return hubItem?.name;
        });
        setUserHubs(userHubsName.join(', '));
      }
    }
    // eslint-disable-next-line
  }, [hubs, user]);

  useEffect(() => {
    const hubsObj = buildJsonData(hubsList, 'hubs');
    setHubs(hubsObj);
  }, [hubsList]);

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) return;
      const payload = {
        data: {
          type: 'users',
          id: userId,
          attributes: {
            name: values.name || '',
            email: values.email || '',
            kind: values.kind || '',
            phone: values.phone || '',
            hubs: values.hubs?.length ? values.hubs.join(',') : '',
            occupation: values.occupation || '',
            image: values.image || '',
            document: values.document || '',
            vehicle: values.vehicle || '',
            plate: values.plate || ''
          }
        }
      };
      if (selectedPermissions.length) {
        const newPermissions =
          selectedPermissions.filter(
            selected =>
              !userPermissions?.find(
                current =>
                  current.attributes.full_name === selected.split('/')[0]
              )
          ) || [];

        const newPermissionsArr = newPermissions.map(item => {
          return {
            type: 'permissions',
            id: item.split('/')[1],
            method: 'update'
          };
        });
        const deletedPermissions =
          userPermissions?.filter(
            current =>
              !selectedPermissions.find(selected =>
                selected.includes(current.attributes.full_name)
              ) &&
              permissionsVisible.find(
                visible => visible.permission === current.attributes.full_name
              )
          ) || [];
        const deletedPermissionsArr = deletedPermissions.map(item => {
          return {
            type: 'permissions',
            id: item.id,
            method: 'disassociate'
          };
        });
        const data = [...newPermissionsArr, ...deletedPermissionsArr];
        payload.data.relationships = {
          permissions: {
            data
          }
        };
      } else if (userPermissions?.length) {
        const data = userPermissions?.map(item => {
          return {
            type: 'permissions',
            id: item.id,
            method: 'disassociate'
          };
        });
        payload.data.relationships = {
          permissions: {
            data
          }
        };
      }
      dispatch(updateUser(payload));
      setEditUser(false);
    });
  };

  const showConfirmBan = () => {
    confirm({
      title: 'DESATIVAR USUÁRIO',
      content: 'Tem certeza que deseja desativar esse usuário?',
      okText: 'Desativar',
      okType: 'primary',
      cancelText: 'Cancelar',
      cancelButtonProps: {
        type: 'secondary'
      },
      // className: 'white-bg',
      onOk() {
        const payload = {
          data: {
            type: 'users',
            id: userId,
            attributes: {
              banned: true
            }
          }
        };
        dispatch(updateUserBan(payload));
      }
    });
  };

  const showEditUser = () => {
    if (editUser) {
      const permissions = userPermissions.map(
        item => `${item.attributes.full_name}/${item.id}`
      );
      setSelectedPermissions(permissions);
    }
    setEditUser(prevValue => !prevValue);
  };

  const showConfirmActivate = () => {
    confirm({
      title: 'ATIVAR USUÁRIO',
      content: 'Tem certeza que deseja ativar esse usuário?',
      okText: 'ativar',
      okType: 'primary',
      cancelText: 'Cancelar',
      cancelButtonProps: {
        type: 'secondary'
      },
      // className: 'white-bg',
      onOk() {
        const payload = {
          data: {
            type: 'users',
            id: userId,
            attributes: {
              banned: false
            }
          }
        };
        dispatch(updateUserBan(payload));
      }
    });
  };

  const showConfirmReset = () => {
    confirm({
      title: `RESETAR SENHA DO USUÁRIO ${user?.name.toUpperCase()}`,
      content: `Deseja realmente resetar a senha deste usuário? Ao confirmar, o usuário receberá um e-mail com as instruções para nova senha.`,
      okText: 'Resetar',
      okType: 'primary',
      cancelText: 'Cancelar',
      cancelButtonProps: {
        type: 'secondary'
      },
      // className: 'white-bg',
      onOk() {
        const payload = {
          data: {
            type: 'users',
            attributes: {
              email: user.email
            }
          }
        };
        dispatch(getRecoverPassword(payload));
      }
    });
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={showEditUser}>
        {!editUser ? 'Editar usuário' : 'Cancelar edição'}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={showConfirmReset}>
        Resetar senha
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={showConfirmBan}>
        Desativar usuário
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const bannedActions = (
    <Menu>
      <Menu.Item key="1" onClick={showConfirmActivate}>
        Ativar usuário
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const userActions = (
    <Dropdown overlay={user?.banned ? bannedActions : actions}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      <Audit
        id={userId}
        title="USUÁRIO"
        type="User"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Editar usuário</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/usuarios">Usuários</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar usuário</Breadcrumb.Item>
      </Breadcrumb>
      {user?.banned && <Alert message="Usuário banido" type="error" showIcon />}
      <div
        style={{
          borderRadius: '16px',
          padding: '7px',
          marginTop: '10px',
          background: '#ebebeb'
        }}
      >
        <Row>
          <Col span={8}>
            <Card className="no-shadow" title="Dados" extra={userActions}>
              <Spin spinning={userIsLoading}>
                {editUser ? (
                  <>
                    <UserForm form={form} permissions={selectedPermissions} />
                    <Row
                      type="flex"
                      justify="end"
                      gutter={10}
                      style={{ marginTop: 16 }}
                    >
                      <Col>
                        <Button
                          onClick={() => setEditUser(prevValue => !prevValue)}
                          disabled={user?.banned}
                          type="secondary"
                        >
                          Cancelar
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={onSubmit}
                          disabled={user?.banned}
                          type="primary"
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <div className={styles.imageWrapper}>
                      {user.image ? (
                        <img src={user.image} alt="user" />
                      ) : (
                        <Icon type="camera" theme="filled" />
                      )}
                    </div>
                    <h4>
                      <strong>Nome</strong>
                    </h4>
                    <p>{user?.name}</p>
                    <h4>
                      <strong>E-mail</strong>
                    </h4>
                    <p>
                      <a
                        href={`mailto:${user?.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-bold text-blue text-underline"
                      >
                        {user?.email}
                      </a>
                    </p>
                    <h4>
                      <strong>Tipo de Permissão</strong>
                    </h4>
                    <p>{permissionsKind[user?.kind] || ''}</p>
                    <h4>
                      <strong>Função</strong>
                    </h4>
                    <p>{user?.occupation || 'Sem função definida'}</p>
                    <h4>
                      <strong>Documento</strong>
                    </h4>
                    <p>{user?.document || '-'}</p>
                    {(user.kind === 'deliverer' ||
                      userPermissions?.find(
                        item =>
                          item.attributes.full_name === 'dispatch.orders.manage'
                      )) && (
                      <>
                        <h4>
                          <strong>Hubs para a Expedição</strong>
                        </h4>
                        <p>{userHubs || '-'}</p>
                      </>
                    )}
                    {(user.kind === 'office' || user.kind === 'deliverer') && (
                      <>
                        <h4>
                          <strong>Telefone</strong>
                        </h4>
                        <p>{user.phone ? formatDDDPhone(user.phone) : '-'}</p>
                      </>
                    )}
                    {user.kind === 'deliverer' && (
                      <>
                        <h4>
                          <strong>Veículo</strong>
                        </h4>
                        <p>{user.vehicle || '-'}</p>
                        <h4>
                          <strong>Placa</strong>
                        </h4>
                        <p>{user.plate || '-'}</p>
                      </>
                    )}
                  </>
                )}
              </Spin>
            </Card>
          </Col>
          <Col span={16}>
            <Permissions
              setPermissionsVisible={setPermissionsVisible}
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
              isBanned={user?.banned}
              disabled={!editUser}
              kind={getFieldValue('kind') || user.kind}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

User.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string
    })
  }).isRequired,
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
};

const EditUserForm = Form.create({ name: 'edit_user_form' })(User);

export default EditUserForm;
