import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import moment from 'moment';

import { ReactComponent as EditIcon } from '@/src/assets/images/edit.svg';

import Searchbar from '@/src/components/Searchbar/Searchbar';
import PriceTableModal from '@/src/pages/Policies/containers/PriceTableModal/index';
import PriceTableEditModal from '@/src/pages/Policies/containers/PriceTableEditModal/index';
import PriceTableExportModal from '@/src/pages/Policies/containers/PriceTableExportModal/index';
import Tables from '@/src/components/Tables';

import {
  getPriceTables,
  getPriceTableProducts
} from '@/src/store/modules/price-tables/slice';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import style from '../Policy.module.scss';

export const AllPriceTablesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState({});
  const [tableData, setTableData] = useState({});
  const [exportModal, setExportModal] = useState(false);

  const {
    priceTables,
    priceTablesCount,
    priceTablesQuery,
    priceTablesLoading
  } = useSelector(state => {
    return state.priceTables;
  });

  const getPriceTablesHandler = useCallback(
    urlQuery => {
      dispatch(getPriceTables({ ...urlQuery }));
    },
    [dispatch]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);
    getPriceTablesHandler({ query: params });
  }, [history, getPriceTablesHandler]);

  const handleOpenTable = table => {
    setShowModal(true);
    dispatch(
      getPriceTableProducts({
        query: { price_table_id: table.id, page: 1, perPage: 10 }
      })
    );
    setTableData(table.attributes);
  };

  const columns = [
    {
      title: 'Código Tabela ERP',
      align: 'center',
      dataIndex: 'attributes.id',
      width: 100,
      render: text => text || '-'
    },
    {
      title: 'Nome Tabela',
      align: 'left',
      dataIndex: 'attributes.name',
      render: (text, record) => {
        return (
          <button
            type="button"
            key={record.id}
            onClick={() => handleOpenTable(record)}
            style={{
              border: 'none',
              textDecoration: 'underline',
              cursor: 'pointer',
              background: 'transparent',
              color: 'rgba(0, 0, 0, 0.65)',
              outline: 'none',
              textAlign: 'left'
            }}
          >
            {text || '-'}
          </button>
        );
      }
    },
    {
      title: 'Hubs Associados',
      align: 'left',
      key: 'hubs',
      dataIndex: 'attributes.pricing_rules',
      render: rules => {
        const hubs = [];
        rules.forEach(rule => {
          rule.hubs.forEach(hub => {
            if (!hubs.includes(hub.name)) {
              hubs.push(hub.name);
            }
          });
        });

        return <span>{hubs.length ? hubs.join(', ') : '-'}</span>;
      }
    },
    {
      title: 'Políticas Associadas',
      align: 'left',
      key: 'policies',
      dataIndex: 'attributes.pricing_rules',
      render: rules => {
        const policies = [];
        rules.forEach(rule => {
          if (!policies.includes(rule.pricing_rule.name)) {
            policies.push(rule.pricing_rule.name);
          }
        });

        return <span>{policies.length ? policies.join(', ') : '-'}</span>;
      }
    },
    {
      title: 'Vigência',
      align: 'left',
      key: 'validity',
      render: (text, record) => {
        const { start_date, end_date } = record.attributes;
        const startDate = start_date
          ? moment(start_date).format('DD/MM/YYYY (HH:mm)')
          : '';
        const endDate = end_date
          ? moment(end_date).format('DD/MM/YYYY (HH:mm)')
          : 'Indefinido';
        const validity = startDate ? `${startDate} - ${endDate}` : endDate;
        return validity;
      }
    },
    {
      key: 'action',
      render: (text, record) => {
        return (
          <div className={style.tableEdit__button}>
            <button
              type="button"
              onClick={e => {
                e.stopPropagation();
                setEditModal(record.attributes);
              }}
              aria-label="Editar tabela de preço"
            >
              <EditIcon />
            </button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <PriceTableEditModal showModal={editModal} setShowModal={setEditModal} />
      <PriceTableExportModal
        showModal={exportModal}
        setShowModal={setExportModal}
      />
      <PriceTableModal
        showModal={showModal}
        setShowModal={setShowModal}
        tableData={tableData}
        setTableData={setTableData}
      />
      <Row type="flex" justify="space-between" style={{ marginBottom: 20 }}>
        <Col>
          <h1 style={{ marginBottom: 0 }}>Tabelas</h1>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          minHeight: '48px'
        }}
      >
        <Row type="flex" justify="space-between" style={{ marginBottom: 20 }}>
          <Col span={16} className="ant-searchbar-filter">
            <Searchbar
              placeholder="Buscar por nome"
              populateResult={getPriceTablesHandler}
              query={priceTablesQuery}
              value={priceTablesQuery.search}
            />
          </Col>
          <Col>
            <Button
              onClick={() => setExportModal(true)}
              size="large"
              className="ant-btn-secondary"
            >
              Exportar CSV
            </Button>
          </Col>
        </Row>

        <Tables
          data={priceTables}
          className="even-gray"
          columnsDefaults={columns}
          count={priceTablesCount}
          query={priceTablesQuery}
          visiblePaginationUp
          visiblePaginationDown
          rowKey={record => record.id}
          history={history}
          isLoading={priceTablesLoading}
          populateTables={getPriceTablesHandler}
        />
      </div>
    </>
  );
};

export default AllPriceTablesPage;
