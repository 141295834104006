import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Select, Form, Spin } from 'antd';

import { exportCSVHubSkus } from '@/src/store/modules/hubs/slice';

import { handleSelectSearch } from '@/src/utils/searchUtils';

const { Option } = Select;

const ModalHubSkus = ({
  visible,
  setVisible,
  form,
  populateHubs,
  hubsLoading
}) => {
  const [hubsList, setHubsList] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const dispatch = useDispatch();

  const { hubs } = useSelector(state => state.hubs);

  useEffect(() => {
    const unsorted = hubs?.length > 0 ? [...hubs] : [];
    const sorted = unsorted.sort((a, b) =>
      a.attributes.name.localeCompare(b.attributes.name)
    );
    setHubsList(sorted);
  }, [hubs]);

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err || !values.hubs) return;
      dispatch(exportCSVHubSkus(values.hubs));
      setVisible(false);
    });
  };

  return (
    <Modal
      title={<h2>Estoque por Hub</h2>}
      okText="Exportar"
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => setVisible(false)}
      className="ant-modal-hubs centered-footer"
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      destroyOnClose
    >
      <Form>
        <div style={{ margin: '24px 0' }}>
          <p>
            Selecione de 1 a 5 hubs para exportar as planilhas com estoque e
            detalhes dos SKUs.
          </p>
          <p>Os arquivos serão processados e enviados por e-mail.</p>
        </div>
        <Row>
          <Col span={24}>
            <Form.Item>
              {form.getFieldDecorator('hubs', {
                rules: [
                  {
                    required: true,
                    message: 'Campo obrigatório'
                  },
                  {
                    validator: (rule, value) => value.length <= 5,
                    message: 'Selecione até 5 hubs'
                  }
                ]
              })(
                <Select
                  placeholder="Hubs"
                  mode="multiple"
                  filterOption={handleSelectSearch}
                  allowClear
                  onChange={value => setSelectValue(value)}
                  onFocus={populateHubs}
                  notFoundContent={hubsLoading ? <Spin size="small" /> : null}
                  loading={hubsLoading}
                >
                  {hubsList.map(item => (
                    <Option
                      key={item.attributes.internal_id}
                      value={item.attributes.internal_id}
                      disabled={
                        selectValue.length >= 5
                          ? !selectValue.includes(item.attributes.internal_id)
                          : false
                      }
                    >
                      {item.attributes.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ModalHubSkus.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  populateHubs: PropTypes.func.isRequired,
  hubsLoading: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired
};

const ModalHubSkusForm = Form.create({ name: 'new-region' })(ModalHubSkus);

export default ModalHubSkusForm;
