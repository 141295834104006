import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Upload } from 'antd';

import styles from '@/src/components/UploadImage/UploadImage.module.scss';

const SingleUpload = ({
  uploadImage,
  withoutImage,
  handleBeforeUpload,
  handleUploadChange,
  handleDeleteImage,
  handleImageError,
  isRound,
  isSmall,
  recommendedSize,
  editPermission
}) => {
  const [imgError, setImgError] = useState(false);

  const hasSize = recommendedSize.length === 2;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // alignItems: isRound && 'center',
        width: 'fit-content',
        maxWidth: '100%',
        position: 'relative'
      }}
    >
      {uploadImage?.length ? (
        <>
          <div
            className={
              isRound ? styles['image-upload-content-holder-round'] : ''
            }
            style={{
              width: hasSize ? recommendedSize[0] : 350,
              height: hasSize ? recommendedSize[1] : 350,
              maxWidth: '100%',
              backgroundColor: '#f9f9f9',
              position: 'relative'
            }}
          >
            {imgError && (
              <Icon
                style={{
                  fontSize: '36px',
                  opacity: '0.2',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
                type="file-unknown"
              />
            )}
            <img
              src={uploadImage}
              alt="products"
              onError={e => {
                handleImageError(e);
                setImgError(true);
              }}
              className={`${
                isRound
                  ? styles['image-upload-content-round']
                  : styles['image-upload-content']
              } ${isSmall ? styles['image-upload-content-small'] : ''}`}
            />
          </div>
          {editPermission && (
            <div style={{ marginTop: 16 }}>
              <Button type="secondary" onClick={handleDeleteImage}>
                <Icon type="delete" />
                Remover imagem
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={`${
              isRound
                ? styles['image-upload-default-round']
                : styles['image-upload-default']
            } ${isSmall ? styles['image-upload-small'] : ''}`}
            style={{
              width: hasSize ? recommendedSize[0] : 350,
              height: hasSize ? recommendedSize[1] : 350,
              maxWidth: '100%',
              backgroundColor: '#f9f9f9'
            }}
          >
            <Icon
              style={{ fontSize: '36px', opacity: '0.2' }}
              type="camera"
              theme="filled"
            />
          </div>
          {editPermission && (
            <>
              <Upload
                className="upload-photos-category"
                accept="image/*"
                listType="picture"
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
                onChange={file => {
                  handleUploadChange(file);
                  setImgError(false);
                }}
              >
                <Button>
                  <Icon type="upload" />
                  Upload Image
                </Button>
              </Upload>
            </>
          )}
        </>
      )}
      {withoutImage ? (
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            marginBottom: 0,
            marginTop: 10
          }}
        >
          {editPermission ? 'Por favor, adicione uma imagem' : 'Sem imagem'}
        </p>
      ) : (
        <span
          style={{
            fontSize: 12,
            position: !hasSize || recommendedSize[0] >= 350 ? 'absolute' : '',
            right: 0,
            bottom: 16,
            marginTop: !hasSize || recommendedSize[0] >= 350 ? 0 : 12
          }}
        >
          {hasSize
            ? `Tamanho ideal: ${recommendedSize[0]} x ${recommendedSize[1]}`
            : 'Tamanho livre'}
        </span>
      )}
    </div>
  );
};

SingleUpload.defaultProps = {
  isRound: false,
  isSmall: false,
  recommendedSize: [],
  handleImageError: () => {},
  editPermission: true
};

SingleUpload.propTypes = {
  handleBeforeUpload: PropTypes.func.isRequired,
  handleUploadChange: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleImageError: PropTypes.func,
  withoutImage: PropTypes.bool.isRequired,
  uploadImage: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.string
  ]).isRequired,
  isRound: PropTypes.bool,
  isSmall: PropTypes.bool,
  recommendedSize: PropTypes.arrayOf(PropTypes.number),
  editPermission: PropTypes.bool
};

export default SingleUpload;
