import React, { useState, useRef, useEffect } from 'react';
import { Row, Button, Icon, Input, Alert } from 'antd';

import PropTypes from 'prop-types';

const AttributesValue = props => {
  const { attributes, attributeValuesRef, editPage } = props;
  const [attributesArray, setAttributesArray] = useState(attributes);
  const [inputValue, setInputValue] = useState('');
  const attributesRef = useRef([]);

  useEffect(() => {
    setAttributesArray(attributes);
  }, [attributes]);

  const handleValidateAttributes = () => {
    let isValidated = true;
    attributesRef.current.forEach(attribute => {
      const el = attribute;
      if (el && el.state.value === '') {
        el.input.classList.add('input-alert');
        isValidated = false;
      } else if (el) {
        el.input.classList.remove('input-alert');
      }
    });
    return isValidated;
  };

  const handleAddAttribute = () => {
    const isValidated = handleValidateAttributes();
    if (!isValidated) return;
    const newAttributesArray = [];
    attributesRef.current.forEach(refItem => {
      if (refItem) {
        newAttributesArray.push({
          name: refItem.state.value
        });
      }
    });
    newAttributesArray.push({ name: '' });
    setAttributesArray(newAttributesArray);
    setInputValue('');
    attributeValuesRef.current = attributesRef.current;
  };

  const handleDeleteAttribute = value => {
    const newAttributesArray = attributesArray
      .filter(item => item !== value)
      .map(item => {
        const newValue = item;
        if (item.name === '') newValue.name = inputValue;
        return newValue;
      });
    setAttributesArray(newAttributesArray);
    setInputValue('');
    attributeValuesRef.current = attributesRef.current;
  };

  const handleChangeAttribute = (evt, item) => {
    const { value } = evt.target;
    attributesRef.current[item].value = value;
    if (value !== '') {
      attributesRef.current[item].input.classList.remove('input-alert');
    }
    attributeValuesRef.current = attributesRef.current;
    setInputValue(value);
  };

  return (
    <>
      <Row>
        <span
          style={{
            display: 'block',
            margin: '8px 0'
          }}
        >
          <Alert
            message="Após salvar um valor do atributo, ele não poderá ser
          editado. Para alterar, será necessário deletar o valor atual, criar um
          novo e remarcar os produtos associados a ele."
            type="info"
            className="properties-alert"
          />
        </span>
      </Row>
      {attributesArray.map((item, index) => {
        return (
          <Row
            key={`${item.name}_${index * 1}`}
            style={{
              padding: '.25rem 0',
              margin: '.25rem 0'
            }}
            type="flex"
          >
            <Input
              placeholder="Digite o valor do atributo"
              defaultValue={item.name || ''}
              ref={el => {
                attributesRef.current[index] = el;
                attributeValuesRef.current[index] = el;
              }}
              onChange={evt => handleChangeAttribute(evt, index)}
              disabled={
                editPage && attributes.find(attr => attr.name === item.name)
              }
              style={{ width: 'auto', flexGrow: 1 }}
            />
            <Button
              className={`ant-btn ant-btn-link${
                attributesArray.length === 1 ? ' hidden' : ''
              }`}
              style={{
                textTransform: 'initial',
                color: '#0A0A0A'
              }}
              onClick={() => handleDeleteAttribute(item)}
            >
              <Icon type="delete" />
            </Button>
          </Row>
        );
      })}
      <Row>
        <Button
          className="ant-btn ant-btn-link"
          style={{
            textTransform: 'initial',
            color: '#0A0A0A',
            padding: '0'
          }}
          onClick={handleAddAttribute}
        >
          <Icon type="plus-circle" />
          Adicionar valor
        </Button>
      </Row>
    </>
  );
};

AttributesValue.defaultProps = {
  editPage: false
};

AttributesValue.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  attributeValuesRef: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  editPage: PropTypes.bool
};

export default AttributesValue;
