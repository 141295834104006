import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Table,
  // Select,
  Pagination,
  Row,
  Col,
  Icon,
  Button,
  Popover
} from 'antd';
import { useHistory, Link } from 'react-router-dom';
import moment from '@/src/services/moment';
import { dispatchStatus, subscriptionStatus } from '@/src/utils/localeData';

// import formatCPF from '@/src/utils/formatCPF';
// import formatCurrency from '@/src/utils/formatCurrency';
// import orderStatusInfo from '@/src/globals/orderStatusInfo';
// import StatusPill from '@/src/components/StatusPill';

// import usePermissions from '@/src/hooks/usePermissions';
// import * as modules from '@/src/globals/permissionsModules';

import style from '@/src/pages/Subscriptions/Subscriptions.module.scss';

// const { Option } = Select;

const CyclesTable = props => {
  const { populateCyclesTable, handleRetryBilling } = props;
  const history = useHistory();

  const {
    cycles,
    cyclesCount,
    cyclesQuery,
    isCycleLoading
    // subscription
  } = useSelector(state => {
    return state.subscriptions;
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // console.log('cycles', cycles);
    // console.log('subscription', subscription);

    const data = cycles.map(item => {
      const orders = [...item.attributes.orders];
      const cycle = {
        id: item.id,
        attempts: orders.length,
        order: {
          number: orders[0]?.number,
          id: orders[0]?.id,
          status: orders[0]?.status
        },
        items: item.attributes.items,
        items_count: item.attributes.items_count,
        status: item.attributes.status,
        created_at: orders[0]?.created_at
      };
      if (orders.length > 1) {
        cycle.children = orders
          .filter((order, index) => index > 0)
          .map((order, index) => ({
            id: order.id,
            attempts: orders.length - 1 - index,
            order: { number: order.number, id: order.id, status: order.status },
            items: '',
            items_count: '',
            status: '',
            created_at: order.created_at
          }));
      }
      return cycle;
    });
    setTableData(data);
  }, [cycles]);

  const changePagination = (page, perPage) =>
    populateCyclesTable({
      query: {
        ...cyclesQuery,
        page,
        perPage
      }
    });
  const changePerPage = (current, perPage) =>
    populateCyclesTable({
      query: {
        ...cyclesQuery,
        page: cyclesQuery.perPage !== perPage ? 1 : current,
        perPage
      }
    });

  // const { routePermission } = usePermissions();
  // const customersPermission = routePermission(modules.CUSTOMERS);

  // console.log(subscriptions);
  // const mock = [
  //   {
  //     id: '223',
  //     type: 'subscription_cycle',
  //     attributes: {
  //       id: 1,
  //       subscription_id: 371,
  //       status: 'completed',
  //       created_at: '2024-04-17T11:59:30.059-03:00',
  //       items_count: 1,
  //       orders: [
  //         {
  //           id: 129,
  //           number: '777080988',
  //           created_at: '2024-04-17T11:59:32.415-03:00'
  //         }
  //       ]
  //     }
  //   }
  // ];

  // const handleExpandedRow = (clickedItem, index, indent, expanded) => {
  //   console.log(indent);
  //   console.log(expanded);
  //   const { orders } = clickedItem.attributes;
  //   const formattedOrders = [...orders];
  //   formattedOrders.shift();

  //   console.log(formattedOrders);

  //   const expandedRowColumn = [
  //     {
  //       title: 'Ciclo',
  //       key: 'try_cycle',
  //       // align: 'center',
  //       dataIndex: '',
  //       width: '10%'
  //     },
  //     {
  //       title: 'Tentativas',
  //       dataIndex: '',
  //       key: 'try_number',
  //       width: '15%',
  //       sorter: true,
  //       // align: 'center',
  //       render: (text, record, indx) => {
  //         return orders.length - 1 - indx;
  //       }
  //       // render: createdAt => moment(createdAt).format('DD-MM-YY / HH:mm')
  //     },
  //     {
  //       title: 'Pedido',
  //       // dataIndex: 'attributes',
  //       key: 'try_order_number',
  //       width: '20%',
  //       // sorter: true
  //       render: record => {
  //         console.log(record);
  //         return <Link to={`/pedidos/${record?.id}`}> #{record?.number} </Link>;
  //       }
  //     },
  //     {
  //       title: 'Itens',
  //       dataIndex: 'attributes',
  //       key: 'try_itens',
  //       width: '10%'
  //       // align: 'center'
  //       // render: record => {
  //       //   return record.items_count
  //       // }
  //     },
  //     {
  //       title: 'Status',
  //       dataIndex: 'status',
  //       key: 'try_status',
  //       width: '18%',
  //       sorter: true,
  //       // align: 'center',
  //       render: record => {
  //         return dispatchStatus[record];
  //       }
  //     },
  //     {
  //       title: 'Data Cobrança',
  //       dataIndex: 'attributes',
  //       key: 'try_created_at',
  //       width: '20%',
  //       // align: 'center',
  //       // sorter: true,
  //       render: record => {
  //         return moment(record?.created_at).format('DD/MM/YY');
  //       }
  //     }
  //   ];

  //   console.log(clickedItem?.attributes?.orders.length > 1);
  //   const hideTable = clickedItem?.attributes?.orders.length <= 1;
  //   if (clickedItem?.attributes?.orders.length > 1) {
  //     return (
  //       <Table
  //         showHeader={false}
  //         style={{
  //           margin: '-12px -14px ',
  //           border: 'none',
  //           display: `${hideTable ? 'hidden' : ''}`
  //         }}
  //         rowKey={record => record.id}
  //         columns={expandedRowColumn}
  //         dataSource={formattedOrders}
  //         pagination={false}
  //       />
  //     );
  //   }

  //   return false;
  // };

  const handleImgError = event => {
    event.persist();
    event.target.classList.add('hidden');
  };

  const columns = [
    {
      title: 'Ciclo',
      key: 'cycle',
      // align: 'center',
      dataIndex: 'id'
      // width: '10%'
    },
    {
      title: 'Tentativas',
      dataIndex: 'attempts',
      key: 'attempts'
      // width: '15%'
      // sorter: true,
      // align: 'center',
      // render: record => {
      //   if (record?.orders) {
      //     const { orders } = record;
      //     // const lastOrder = orders[orders.length - 1];
      //     return orders.length;
      //   }
      //   return '';
      // }
    },
    {
      title: 'Pedido',
      dataIndex: 'order',
      key: 'order_number',
      // width: '20%',
      render: record => {
        // const { orders } = record;
        // const lastOrder = record[0];

        if (record?.number)
          return <Link to={`/pedidos/${record.id}`}> #{record?.number} </Link>;
        return '-';
      }
    },
    {
      title: 'Itens',
      dataIndex: 'items',
      key: 'items_count',
      // width: '10%',
      // align: 'center',
      render: (record, data) => {
        // const { items } = record;
        if (!record) return '-';
        const products = record.map(item => (
          <li key={`popover-${item.id}`} className={style.itensPopover}>
            <div
              style={{
                width: '30px',
                height: '30px',
                backgroundColor: '#ebebeb',
                borderRadius: 4
              }}
            >
              {item.thumb && (
                <img
                  style={{ width: '100%', height: '100%', objectFit: 'fill' }}
                  src={item.thumb}
                  alt={item.product_name}
                  onError={event => handleImgError(event)}
                />
              )}
            </div>
            <div className={style.itensPopover_content}>
              <span className={style.itensPopover_content__id}>
                {item.sku_external_id}
              </span>
              <p className={style.itensPopover_title}>{item.product_name}</p>
              <p className={style.itensPopover_content__details}>
                <span>qtd: </span> {item.quantity} | <span>sku:</span>{' '}
                {item.sku_name}
              </p>
            </div>
          </li>
        ));
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {data.items_count}
            <Popover
              content={<ul style={{ padding: 0, margin: 0 }}> {products} </ul>}
              title="Itens da assinatura"
            >
              <button type="button" className={style.itensPopover__infoButton}>
                <Icon
                  style={{ fontSize: 16, color: '#0a0a0a', marginTop: '2px' }}
                  type="info-circle"
                />
              </button>
            </Popover>
          </div>
        );
      }
    },
    {
      title: 'Status Ciclo',
      dataIndex: 'status',
      render: (record, cycle) => subscriptionStatus[cycle.status] || '-',
      key: 'status'
      // width: '18%',
      // sorter: true,
      // align: 'center',
      // render: record => {
      //   console.log(record.status);
      //   // if (record?.orders) {
      //   const { orders } = record;
      //   const lastOrder = orders[0];
      //   return lastOrder?.status === 'delivered'
      //     ? dispatchStatus[lastOrder.status]
      //     : subscriptionStatus[record.status];
      //   // }
      //   // return '';
      // }
    },
    {
      title: 'Status Pedido',
      dataIndex: 'order',
      key: 'order_status',
      render: (record, cycle, index) => {
        // console.log('cycle',cycle)
        const hasPaymentError = cycle.status === 'payment_error';
        return (
          <>
            {dispatchStatus[record.status] || '-'}

            {index === 0 && hasPaymentError && (
              <Button
                onClick={() => handleRetryBilling(cycle.id)}
                className="ant-btn-primary"
                style={{
                  borderRadius: '100px',
                  marginLeft: '10px',
                  padding: '3px 7px 0'
                }}
              >
                <Icon type="sync" />
              </Button>
            )}
          </>
        );
      }
    },
    {
      title: 'Data Cobrança',
      dataIndex: 'created_at',
      key: 'created_at',
      // width: '20%',
      // align: 'center',
      // sorter: true,
      render: record => {
        // const { orders } = record;
        // const lastOrder = orders[0];
        return (
          <span>
            {record ? moment(record).format('DD/MM/YY - HH:mm') : '-'}
          </span>
        );
      }
      // render: createdAt => moment(createdAt).format('DD-MM-YY / HH:mm')
    }
  ];

  const columnSort = () => {
    const urlQuery = history.location.search;
    if (urlQuery.includes('&sort')) {
      const sort = urlQuery.split('&sort')[1];
      const sortParams = {
        column: sort
          .split('=')[0]
          .replace('[', '')
          .replace(']', ''),
        order: sort
          .split('=')[1]
          .replace('desc', 'descend')
          .replace('asc', 'ascend')
      };
      columns.forEach(el => {
        if (el.key === sortParams.column) {
          Object.assign(el, { defaultSortOrder: sortParams.order });
        }
      });
    }
  };
  columnSort();

  // const changePagination = (page, perPage) =>
  //   populateSubscriptionsTable({
  //     query: {
  //       ...query,
  //       page,
  //       perPage
  //     }
  //   });
  // const changePerPage = (current, perPage) =>
  //   populateSubscriptionsTable({
  //     query: {
  //       ...query,
  //       page: query.perPage !== perPage ? 1 : current,
  //       perPage
  //     }
  //   });

  // const handleDaysAgoChange = value =>
  //   populateSubscriptionsTable({
  //     query: {
  //       ...query,
  //       page: 1,
  //       daysAgo: value
  //     }
  //   });

  return (
    <>
      <Row
        type="flex"
        gutter={16}
        justify="space-between"
        style={{ marginBottom: 20 }}
      >
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="table-results">
            {cycles.length > 0 && (
              <p data-test-id="filtered-orders-length">
                {/* <span>{count}</span>{' '} */}
                {/* {count === 1 ? 'pedido filtrado ' : 'pedidos filtrados '} */}
                {/* <span>({formatCurrency(total)})</span> */}
              </p>
            )}
          </div>
        </Col>
        {/* <Col>
          <Row type="flex" gutter={16}>
            <Col>
              <Select
                style={{ width: 150 }}
                value={query.daysAgo}
                onChange={handleDaysAgoChange}
              >
                <Option value="all">Todos</Option>
                <Option value="1">Do dia</Option>
                <Option value="7">Últimos 7 dias</Option>
                <Option value="30">Últimos 30 dias</Option>
                <Option value="60">Últimos 2 meses</Option>
                <Option value="90">Últimos 3 meses</Option>
                <Option value="180">Últimos 6 meses</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                showSizeChanger
                onChange={changePagination}
                onShowSizeChange={changePerPage}
                pageSize={query.perPage}
                pageSizeOptions={['10', '20', '30', '50', '100']}
                total={count}
                current={query.page}
              />
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Table
        // className={`orders-table ${className}`}
        // data-test="orders-table"
        // expandedRowRender={handleExpandedRow}
        // onRow={record => {
        //   // if (!rowClick) return false;
        //   return {
        //     onClick: () => {
        //       history.push(`/assinaturas/${record.id}`);
        //     }
        //   };
        // }}
        // rowClassName="has-click"
        expandIconAsCell={false}
        expandIconColumnIndex={1}
        columns={columns}
        dataSource={tableData}
        loading={isCycleLoading}
        size="medium"
        rowKey={record => record.id}
        pagination={false}
        // onChange={(pagination, filter, sorter) => {
        //   const hasSortOrder = () => {
        //     return `[${sorter.columnKey}]=${sorter.order.replace('end', '')}`;
        //   };
        //   const onChangeQuery = {
        //     query: {
        //       ...query,
        //       page: 1,
        //       sort: sorter.order ? hasSortOrder() : ''
        //     }
        //   };
        //   populateCyclesTable(onChangeQuery);
        // }}
        // style={{ paddingTop: isLoading ? '4rem' : '0' }}
        // rowClassName={record => {
        //   if (record?.fraudulent) return 'banned';
        //   if (record?.hasAlert) return 'has-alert';
        //   return '';
        // }}
      />

      <Row type="flex" gutter={16} justify="end" style={{ marginTop: 20 }}>
        <Col>
          <Pagination
            showSizeChanger
            onChange={changePagination}
            onShowSizeChange={changePerPage}
            pageSize={cyclesQuery.perPage}
            pageSizeOptions={['10', '20', '30', '50', '100']}
            total={cyclesCount}
            current={cyclesQuery.page}
          />
        </Col>
      </Row>
    </>
  );
};

CyclesTable.propTypes = {
  populateCyclesTable: PropTypes.func.isRequired,
  handleRetryBilling: PropTypes.func.isRequired
};

export default CyclesTable;
