import React from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Row, Form, Tooltip, Icon } from 'antd';

import validateJson from '@/src/utils/validateJson';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

const MetadataModal = props => {
  const {
    image,
    setImage,
    handleImageMetadata,
    handleImageError,
    form
  } = props;

  const { editPermission } = usePermissions();
  const customFieldsPermission = editPermission(modules.CUSTOM_FIELDS);

  const handleCloseModal = () => {
    setImage({});
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) return;
      handleImageMetadata(image, values.metadata);
      handleCloseModal();
    });
  };

  return (
    <Modal
      title="ATRIBUTOS DE IMAGEM"
      okText="Salvar"
      visible={!!image.url}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
      className="ant-modal-hubs centered-footer"
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      destroyOnClose
      centered
    >
      <Form>
        <p style={{ marginBottom: 16 }}>
          Dados da imagem no formato JSON para uso interno
        </p>
        <Row>
          <h3>Imagem</h3>
          <Form.Item>
            <img
              style={{ maxWidth: '100%', height: 'auto' }}
              src={image.url}
              alt="preview"
              onError={handleImageError}
            />
          </Form.Item>
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5em'
            }}
          >
            <h3 style={{ marginBottom: 0 }}>Metadados</h3>
            <Tooltip
              placement="right"
              title={
                <span>
                  Apenas usuários com permissão em <i>Campos customizados</i>{' '}
                  podem editar
                </span>
              }
            >
              <Icon type="info-circle" />
            </Tooltip>
          </div>
          <Form.Item>
            {form.getFieldDecorator('metadata', {
              validateFirst: true,
              rules: [
                {
                  validator: (rules, value) => {
                    if (!value) return true;
                    return validateJson(value);
                  },
                  message: 'Entrada inválida'
                }
              ],
              initialValue: image.metadata || ''
            })(
              <Input.TextArea
                style={{
                  resize: 'vertical',
                  transition: 'scale 0s'
                }}
                disabled={!customFieldsPermission}
                placeholder='Exemplo: {"key": "value"}'
              />
            )}
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

MetadataModal.propTypes = {
  image: PropTypes.bool.isRequired,
  setImage: PropTypes.func.isRequired,
  handleImageMetadata: PropTypes.func.isRequired,
  handleImageError: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired
};

const MetadataModalForm = Form.create({ name: 'custom-field-form' })(
  MetadataModal
);

export default MetadataModalForm;
