import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getCustomFields = payload => {
  const getUrl = queryBuilder(payload, '/custom_fields');
  return apiPortal.get(getUrl);
};

export const createCustomField = payload =>
  apiPortal.post('/custom_fields', payload);

export const updateCustomField = payload =>
  apiPortal.patch(`/custom_fields/${payload.id}`, payload.data);
