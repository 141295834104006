import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Switch } from 'antd';
import style from '@/src/pages/Subscriptions/Subscriptions.module.scss';

const PauseModal = props => {
  const {
    isPauseAll,
    showPauseModal,
    setShowPauseModal,
    pausedFor,
    setPausedFor,
    pausedQty,
    setPausedQty,
    handlePauseItem,
    itemInfo,
    isSkip
  } = props;

  const [cycleNotDefined, setCycleNotDefined] = useState(false);

  useEffect(() => {
    if (isSkip) {
      // setPausedFor(1);
      setPausedQty(itemInfo.qty);
    }
    // eslint-disable-next-line
  }, [isSkip]);

  const reset = () => {
    setPausedFor(0);
    setCycleNotDefined(false);
    setPausedQty(itemInfo.qty);
  };

  return (
    <Modal
      title={`${
        isSkip
          ? 'PAUSAR ITEM'
          : `${isPauseAll ? 'PAUSAR ASSINATURA' : 'PAUSAR ITEM'}`
      }`}
      visible={showPauseModal}
      width={400}
      cancelButtonProps={{
        className: 'ant-btn-secondary',
        onClick: () => {
          setShowPauseModal(false);
          reset();
        }
      }}
      className="centered-footer"
      okText="Confirmar"
      onOk={() => {
        handlePauseItem();
        reset();
      }}
      maskClosable
      onCancel={() => {
        setShowPauseModal(false);
        reset();
      }}
      okButtonProps={{
        disabled: isPauseAll
          ? !pausedFor || pausedFor === 0
          : !pausedFor || !pausedQty || pausedQty === 0 || pausedFor === 0
      }}
    >
      <div className={style.pauseModal}>
        {!isPauseAll ? (
          <>
            {isSkip && (
              <p
                className={style.pauseModal__title}
                style={{ margin: '0 0 15px 0', lineHeight: 1.5 }}
              >
                {cycleNotDefined
                  ? `Tem certeza que deseja pausar esse item por tempo indefinido?`
                  : `Tem certeza que deseja pausar ${
                      pausedFor > 0 ? pausedFor : ' o '
                    } 
                ${pausedFor > 1 ? 'ciclos' : 'ciclo'} desse produto?`}
                {/* Tem certeza que deseja pular {pausedFor > 0 ? pausedFor : ''}{' '}
                {pausedFor > 1 ? 'ciclos' : 'ciclo'} desse produto? */}
              </p>
            )}
            <p
              className={`${
                isSkip ? style.pauseModal__subtitle : style.pauseModal__title
              }`}
            >
              Produto:
            </p>
            <span style={{ fontSize: 12 }}>{itemInfo.name}</span>
          </>
        ) : (
          <>
            <p className={style.pauseModal__title}>
              Tem certeza que deseja pausar a assinatura?
            </p>
            <span style={{ fontSize: 10 }}>Todos os items serão pausados.</span>
          </>
        )}

        <div className={style.pauseModal__input}>
          <p style={{ marginBottom: 6 }}>Ciclos em pausa</p>
          <Input
            disabled={cycleNotDefined}
            type="number"
            onChange={e => setPausedFor(Number(e.target.value))}
            placeholder="Ex.: 1"
            min={1}
            value={cycleNotDefined ? 0 : pausedFor}
          />
        </div>

        <span style={{ fontWeight: 'bold', fontSize: 10, paddingLeft: 5 }}>
          OU
        </span>

        <div style={{ marginTop: 5 }} className={style.pauseModal__input}>
          <p style={{ marginBottom: 8 }}>Pausar ciclos por tempo indefinido</p>
          <Switch
            checked={cycleNotDefined}
            onChange={value => {
              setCycleNotDefined(value);
              if (value) setPausedFor(9999999);
              else setPausedFor(0);
            }}
            // disabled={record.hubWorkflow === 'petz' ? disabledPetz : disabledZn}
          />
        </div>
        {!isPauseAll && (
          <>
            <hr />
            <div style={{ marginTop: 8 }} className={style.pauseModal__input}>
              <p style={{ marginBottom: 8 }}>Unidades do produto</p>
              <Input
                defaultValue={itemInfo.pausedQty}
                value={pausedQty}
                onChange={e => setPausedQty(Number(e.target.value))}
                placeholder="Ex.: 1"
                min={1}
                type="number"
                max={itemInfo.canPauseQty}
              />
              {/* <Row type="flex" justify="space-between" align="middle">
              <Col span={16}>
                <Slider
                  style={{ width: '100%' }}
                  // onChange={v => console.log(v)}
                  onChange={value => setPausedQty(Number(value))}
                  // onChange={(v) => console.log(v)}
                  min={0}
                  max={itemInfo.canPauseQty}
                  defaultValue={itemInfo.pausedQty}
                  type="number"
                  value={pausedQty}
                />
              </Col>
              <Col span={6}>
                <Input
                  style={{ width: 80 }}
                  onChange={e => setPausedQty(Number(e.target.value))}
                  // onChange={(v) => console.log(v)}
                  defaultValue={itemInfo.pausedQty}
                  value={pausedQty}
                  type="number"
                  min={0}
                  max={itemInfo.canPauseQty}
                  disabled={isSkip}
                />
              </Col>
            </Row> */}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

PauseModal.propTypes = {
  isPauseAll: PropTypes.bool.isRequired,
  showPauseModal: PropTypes.bool.isRequired,
  isSkip: PropTypes.bool.isRequired,
  setShowPauseModal: PropTypes.func.isRequired,
  pausedFor: PropTypes.number.isRequired,
  setPausedFor: PropTypes.func.isRequired,
  pausedQty: PropTypes.number.isRequired,
  setPausedQty: PropTypes.func.isRequired,
  handlePauseItem: PropTypes.func.isRequired,
  itemInfo: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PauseModal;
