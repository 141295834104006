import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  product: {},
  gifts: [],
  selectProduct: {},
  isLoading: false,
  isLoadingGifts: false,
  updated: false,
  hubSkus: []
};

const productSlice = createSlice({
  name: '@product',
  initialState,
  reducers: {
    resetState() {
      const state = {
        ...initialState
      };
      return state;
    },
    setProduct(prevState, action) {
      const state = prevState;
      state.product = action.payload;
      state.gifts = action.payload.skus
        .filter(item => item.gift)
        .map(item => {
          const giftObj = {
            requirementable_name: item.name,
            requirementable_id: item.id,
            gift_product_name: item.gift.productName,
            gift_product_id: item.gift.productId,
            gift_id: item.gift.giftId,
            gift_name: item.gift.giftName,
            id: item.gift.id
          };
          return giftObj;
        });
      return state;
    },
    setSelectProduct(prevState, action) {
      const state = prevState;
      state.selectProduct = action.payload;
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setIsLoadingGifts(prevState, action) {
      const state = prevState;
      state.isLoadingGifts = action.payload;
      return state;
    },
    setUpdated(prevState, action) {
      const state = prevState;
      state.updated = action.payload;
      return state;
    },
    setHubSkus(prevState, action) {
      const state = prevState;
      state.hubSkus =
        action.payload.length === 0
          ? []
          : [
              ...state.hubSkus.filter(
                item => !action.payload.find(i => i.id === item.id)
              ),
              ...action.payload
            ];
      return state;
    },
    getProduct(state) {
      return state;
    },
    getSelectProduct(state) {
      return state;
    },
    getUpdateProduct(state) {
      return state;
    },
    getProductSkuVisibility(state) {
      return state;
    },
    createGift(state) {
      return state;
    },
    updateGift(state) {
      return state;
    },
    deleteGift(state) {
      return state;
    }
  }
});

export const {
  setProduct,
  setSelectProduct,
  setIsLoading,
  setIsLoadingGifts,
  setUpdated,
  setHubSkus,
  getProduct,
  getSelectProduct,
  getUpdateProduct,
  getProductSkuVisibility,
  createGift,
  updateGift,
  deleteGift,
  resetState
} = productSlice.actions;

export default productSlice.reducer;
