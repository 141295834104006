import { takeEvery, put, call } from 'redux-saga/effects';
import { errorAlert, successAlert } from '@/src/utils/notifications';

import {
  setCustomFields,
  setNewCustomField,
  setUpdateCustomField,
  setLoading,
  getCustomFields,
  createCustomField,
  updateCustomField
} from '@/src/store/modules/custom-fields/slice';
import {
  getCustomFields as getCustomFieldsRequest,
  createCustomField as createCustomFieldRequest,
  updateCustomField as updateCustomFieldRequest
} from '@/src/api/custom-fields';

function* getCustomFieldsSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getCustomFieldsRequest, payload);
    yield put(setCustomFields(response.data.data));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os campos customizados');
  }
}

function* createCustomFieldSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(createCustomFieldRequest, payload);
    yield put(setNewCustomField(response.data.data));
    yield put(setLoading(false));
    successAlert('Novo campo customizado criado');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível criar campo customizado');
  }
}

function* updateCustomFieldSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(updateCustomFieldRequest, payload);
    yield put(setUpdateCustomField(response.data.data));
    yield put(setLoading(false));
    successAlert('Os dados do campo customizado foram atualizados');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível atualizar campo customizado');
  }
}

function* highlightsSaga() {
  yield takeEvery(getCustomFields, getCustomFieldsSaga);
  yield takeEvery(createCustomField, createCustomFieldSaga);
  yield takeEvery(updateCustomField, updateCustomFieldSaga);
}

export default highlightsSaga;
