import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const getSubscriptions = payload => {
  const getUrl = queryBuilder(payload, '/subscriptions');
  return apiPortal.get(getUrl);
};

export const getSubscription = id => apiPortal.get(`/subscriptions/${id}`);

export const exportSubscriptions = payload => {
  const getUrl = queryBuilder(payload, '/export_subscriptions');
  return apiPortal.get(getUrl);
};

export const getSubscriptionCycles = payload => {
  const getUrl = queryBuilder(
    payload,
    `/subscriptions/${payload.id}/subscription_cycles`
  );
  return apiPortal.get(getUrl);
};

export const retryCycleBilling = payload =>
  apiPortal.post(`/subscriptions/${payload.id}/cycle_billing_retries`, {
    id: payload.cycle_id
  });

export const pauseSubscriptionItem = payload =>
  apiPortal.patch(`/subscriptions/${payload.subscriptionId}/item_pauses`, {
    id: payload.item_id,
    paused_for: payload.paused_for,
    paused_quantity: payload.paused_quantity
  });

export const unpauseAllSubscriptionItems = id =>
  apiPortal.delete(`/subscriptions/${id}/pauses`);

export const pauseAllSubscriptionItems = payload =>
  apiPortal.post(`/subscriptions/${payload.id}/pauses`, {
    id: payload.id,
    paused_for: payload.paused_for
  });

export const requestCancelSubscription = id =>
  apiPortal.post(`/subscriptions/${id}/cancelations/create_code`, {
    id
  });

export const cancelSubscription = payload =>
  apiPortal.post(`/subscriptions/${payload.id}/cancelations`, {
    id: payload.id,
    security_code: payload.code,
    cancelation_reason: payload.cancelation_reason,
    cancelation_remarks: payload.cancelation_remarks
  });

export const editSubscription = payload =>
  apiPortal.put(`/subscriptions/${payload.id}`, payload);
