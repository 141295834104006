import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Input, Row, Radio } from 'antd';
import style from '@/src/pages/Subscriptions/Subscriptions.module.scss';

export const cancelationSubscriptionOptions = [
  { text: 'Cachorro(a) faleceu', value: 'dog_passed_away' },
  { text: 'Mudança na embalagem (ZDK)', value: 'packaging_change' },
  { text: 'Mudança na textura (ZDK)', value: 'texture_change' },
  { text: 'Não tenho interesse', value: 'customer_is_not_interested' },
  { text: 'Mudança no adesivo (Zee.Pad)', value: 'sticker_change' },
  { text: 'Assinatura duplicada', value: 'duplicated_subscription' },
  { text: 'Valor', value: 'price' },
  { text: 'Atraso recorrente', value: 'recurring_delay' },
  { text: 'Estoque', value: 'stock' },
  { text: 'Outros', value: 'other' }
];

const CancelSubscriptionModal = props => {
  const {
    handleCancel,
    handleRequestCancel,
    showCancelModal,
    setShowCancelModal,
    setCodeToCancel,
    codeToCancel,
    setIsCodeCancelModal,
    isCodeCancelModal,
    selectedMotive,
    setSelectedMotive,
    step,
    setOtherMotive,
    otherMotive,
    setStep
  } = props;

  return (
    <Modal
      title="CANCELAR ASSINATURA"
      visible={showCancelModal}
      width={400}
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      className="centered-footer"
      footer={
        step === 'motive' && (
          <Button
            disabled={
              selectedMotive === 'other'
                ? otherMotive === '' || selectedMotive === ''
                : selectedMotive === ''
            }
            onClick={() => setStep('requestCode')}
            className="ant-btn-primary"
          >
            Confirmar
          </Button>
        )
      }
      onCancel={() => {
        setShowCancelModal(false);
        setStep('motive');
        // setSelectedMotive(null);
      }}
    >
      <div className={style.cancelModal}>
        {step !== 'motive' && (
          <p>
            Tem certeza que deseja cancelar assinatura?{' '}
            {isCodeCancelModal
              ? 'Insira o código de confirmação enviado para o seu celular que está cadastrado no Portal.'
              : 'Um código de confirmação será enviado para o seu celular que está cadastrado no Portal.'}
          </p>
        )}

        {step === 'motive' && (
          <>
            <p>
              Ao cancelar esta assinatura, os produtos serão retornados à sua
              devida base. Apenas com uma nova assinatura o cliente poderá ter
              acesso aos produtos.
            </p>

            <p>Selecione o motivo do cancelamento:</p>

            <Radio.Group
              value={selectedMotive}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onChange={e => {
                setSelectedMotive(e.target.value);
                console.log(e.target.value);
              }}
            >
              {cancelationSubscriptionOptions.map(option => (
                <Radio key={option.value} value={option.value}>
                  {option.text}
                </Radio>
              ))}
            </Radio.Group>

            {selectedMotive === 'other' && (
              <Input.TextArea
                name="Descreva o motivo..."
                style={{ borderRadius: '4px', height: '100%', marginTop: 12 }}
                rows={2}
                onChange={e => setOtherMotive(e.target.value)}
                value={otherMotive}
              />
            )}
            {/* <Button
              disabled={
                selectedMotive === 'Outros'
                  ? otherMotive === '' && selectedMotive === ''
                  : selectedMotive === ''
              }
              onClick={() => setStep('requestCode')}
              className="ant-btn-primary"
            >
              Confirmara
            </Button> */}
          </>
        )}

        {step === 'requestCode' && (
          <Row
            className="flex center"
            style={{ justifyContent: 'space-evenly' }}
          >
            <Button
              onClick={() => {
                setShowCancelModal(false);
                setStep('motive');
              }}
              className="cancel-button"
            >
              Não
            </Button>
            <Button
              onClick={() => {
                handleRequestCancel();
                setStep('codeCancel');
              }}
              className="ant-btn-primary"
            >
              Sim
            </Button>
          </Row>
        )}

        {step === 'codeCancel' && (
          <>
            <Input
              type="number"
              placeholder="Código de confirmação"
              style={{ marginBottom: '10px' }}
              value={codeToCancel}
              onChange={e => setCodeToCancel(e.target.value)}
            />
            <div
              className="flex center"
              style={{ justifyContent: 'space-evenly' }}
            >
              <Button
                onClick={() => {
                  setShowCancelModal(false);
                  setStep('motive');
                }}
                className="cancel-button"
              >
                Não
              </Button>
              <Button
                disabled={codeToCancel === '' || !codeToCancel}
                onClick={() => handleCancel()}
                className="ant-btn-primary"
              >
                Sim
              </Button>
            </div>
          </>
        )}

        {/* {!isCodeCancelModal ? (
          <Row
            className="flex center"
            style={{ justifyContent: 'space-evenly' }}
          >
            <Button
              onClick={() => setShowCancelModal(false)}
              className="cancel-button"
            >
              Não
            </Button>
            <Button onClick={handleRequestCancel} className="ant-btn-primary">
              Sim
            </Button>
          </Row>
        ) : (
          <>
            <Input
              type="number"
              placeholder="Código de confirmação"
              style={{ marginBottom: '10px' }}
              value={codeToCancel}
              onChange={e => setCodeToCancel(e.target.value)}
            />
            <div
              className="flex center"
              style={{ justifyContent: 'space-evenly' }}
            >
              <Button
                onClick={() => setShowCancelModal(false)}
                className="cancel-button"
              >
                Não
              </Button>
              <Button
                disabled={codeToCancel === '' || !codeToCancel}
                onClick={() => handleCancel()}
                className="ant-btn-primary"
              >
                Sim
              </Button>
            </div>
          </>
        )} */}

        {step !== 'motive' && (
          <div className="flex center">
            <button
              type="button"
              onClick={() => {
                setIsCodeCancelModal(!isCodeCancelModal);
                if (!isCodeCancelModal) setStep('codeCancel');
                else setStep('requestCode');
              }}
              className={style.cancelModal__hasCode}
            >
              {isCodeCancelModal
                ? 'Ainda não tenho um código'
                : 'Já tenho um código'}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

CancelSubscriptionModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleRequestCancel: PropTypes.func.isRequired,
  showCancelModal: PropTypes.bool.isRequired,
  setShowCancelModal: PropTypes.func.isRequired,
  setCodeToCancel: PropTypes.func.isRequired,
  codeToCancel: PropTypes.any.isRequired,
  setIsCodeCancelModal: PropTypes.func.isRequired,
  isCodeCancelModal: PropTypes.bool.isRequired,

  setOtherMotive: PropTypes.func.isRequired,
  otherMotive: PropTypes.string.isRequired,
  selectedMotive: PropTypes.any.isRequired,
  setSelectedMotive: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired
};

export default CancelSubscriptionModal;
