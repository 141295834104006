import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Breadcrumb,
  Col,
  Card,
  Form,
  Button,
  Switch,
  Input,
  Menu,
  Dropdown,
  Tooltip,
  Icon
} from 'antd';
import PropTypes from 'prop-types';

import UploadImage from '@/src/components/UploadImage';
import Audit from '@/src/components/Audit/index';

import {
  getManufacturer,
  updateManufacturer
} from '@/src/store/modules/manufacturers/slice';

const EditManufacturer = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { form } = props;
  const { getFieldDecorator, validateFields, getFieldsValue } = form;
  const fieldValues = getFieldsValue([
    'name',
    'highlight',
    'search_metadata',
    'search_slug'
  ]);
  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [showAudit, setShowAudit] = useState(false);

  const { manufacturer, isLoading } = useSelector(state => state.manufacturers);

  const checkIfHasImage = () => {
    if (!uploadImage.length) {
      setWithoutImage(true);
      return false;
    }
    setWithoutImage(false);
    return true;
  };

  const handleSubmitWithAllValues = values => {
    if (checkIfHasImage()) {
      const removeData = dataString =>
        dataString.replace(/data:image\/[\w]+;base64,/g, '');

      let imageChange = '';

      if (uploadImage.includes('data:image')) {
        imageChange = removeData(uploadImage);
      }

      const data = {
        name: values.name,
        highlight: values.highlight,
        search_metadata: values.search_metadata || '',
        title: values.title || '',
        meta_title: values.meta_title || '',
        meta_description: values.meta_description || ''
      };

      if (imageChange !== '') {
        data.image = imageChange;
      }
      const payload = {
        id,
        data
      };
      dispatch(updateManufacturer(payload));
    }
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        checkIfHasImage();
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  useEffect(() => {
    dispatch(getManufacturer(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (manufacturer?.attributes?.image)
      setUploadImage(manufacturer.attributes.image);
  }, [manufacturer]);

  useEffect(() => {
    if (manufacturer.attributes) {
      const {
        name,
        highlight,
        search_metadata,
        search_slug,
        title,
        meta_title,
        meta_description
      } = manufacturer.attributes;
      if (
        uploadImage.includes('data:image') ||
        !uploadImage.length ||
        fieldValues.name !== name ||
        fieldValues.highlight !== highlight ||
        fieldValues.search_metadata !== search_metadata ||
        fieldValues.search_slug !== search_slug ||
        fieldValues.title !== title ||
        fieldValues.meta_title !== meta_title ||
        fieldValues.meta_description !== meta_description
      ) {
        setHasUpdate(true);
      } else {
        setHasUpdate(false);
      }
    }
  }, [uploadImage, fieldValues, manufacturer]);

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      <Audit
        id={id}
        title="FABRICANTE"
        type="Manufacturer"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row>
        <h1>Editar Fabricante</h1>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/fabricantes">Fabricantes</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {manufacturer?.attributes?.name || 'Editar Fabricante'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb'
        }}
      >
        <Card
          className="no-shadow"
          title="DETALHES DO FABRICANTE"
          extra={actionsExtra}
        >
          <Row gutter={24}>
            <Col span={12}>
              <div style={{ width: '80%' }}>
                <Form>
                  <Row type="flex" align="middle" style={{ marginBottom: 24 }}>
                    <p
                      style={{
                        margin: '0 12px 0 0',
                        fontWeight: 'bold',
                        color: 'rgba(0, 0, 0, 0.85)'
                      }}
                    >
                      Exibir
                    </p>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator('highlight', {
                        valuePropName: 'checked',
                        initialValue:
                          manufacturer?.attributes?.highlight || false
                      })(<Switch />)}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item label="Código Marketing" colon={false}>
                      <Input
                        value={manufacturer?.attributes?.internal_id || '–'}
                        disabled
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item label="Nome" colon={false}>
                      {getFieldDecorator('name', {
                        initialValue: manufacturer?.attributes?.name || '',
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, preencha este campo'
                          }
                        ]
                      })(<Input placeholder="Nome do fabricante" />)}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label="Keywords (separadas por vírgula)"
                      colon={false}
                    >
                      {getFieldDecorator('search_metadata', {
                        initialValue:
                          manufacturer?.attributes?.search_metadata || ''
                      })(
                        <Input.TextArea placeholder="Exemplo: zeedog,zeenow" />
                      )}
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item label="Código Deep Link" colon={false}>
                      <Input
                        value={manufacturer?.attributes?.internal_id || '–'}
                        disabled
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item label="Slug" colon={false}>
                      {getFieldDecorator('search_slug', {
                        initialValue:
                          manufacturer?.attributes?.search_slug || '',
                        rules: [
                          {
                            // required: true,
                            message: 'Por favor, preencha este campo'
                          }
                        ]
                      })(<Input placeholder="Slug de fabricante" disabled />)}
                    </Form.Item>
                  </Row>
                  <h2 style={{ marginBottom: 16 }}>SEO (WEB)</h2>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 8,
                        color: 'rgba(0, 0, 0, 0.85)'
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Title
                      </p>
                      <Tooltip
                        placement="right"
                        title="Título da vitrine exibido na página do fabricante"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator('title', {
                        initialValue: manufacturer?.attributes?.title || ''
                      })(<Input placeholder="Título da vitrine" />)}
                    </Form.Item>
                    <span style={{ display: 'block', marginBottom: 24 }}>
                      *Caso não preenchido, será o nome do fabricante
                    </span>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 8,
                        color: 'rgba(0, 0, 0, 0.85)'
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Meta Title
                      </p>
                      <Tooltip
                        placement="right"
                        title="Título da vitrine exibido em ferramentas de busca"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('meta_title', {
                        initialValue: manufacturer?.attributes?.meta_title || ''
                      })(
                        <Input placeholder="Exemplo: Zee.Now - Nome do fabricante" />
                      )}
                    </Form.Item>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 8,
                        color: 'rgba(0, 0, 0, 0.85)'
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Meta Description
                      </p>
                      <Tooltip
                        placement="right"
                        title="Descrição da vitrine exibida em ferramentas de busca"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('meta_description', {
                        initialValue:
                          manufacturer?.attributes?.meta_description || ''
                      })(
                        <Input.TextArea placeholder="Breve descrição do fabricante" />
                      )}
                    </Form.Item>
                  </Row>
                </Form>
                <Button
                  className="ant-btn ant-btn-primary"
                  onClick={onSubmit}
                  disabled={!hasUpdate}
                  loading={isLoading}
                >
                  Salvar
                </Button>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ width: '80%' }}>
                <UploadImage
                  setUploadImage={setUploadImage}
                  uploadImage={uploadImage || []}
                  withoutImage={withoutImage}
                  setWithoutImage={setWithoutImage}
                  recommendedSize={[250, 141]}
                  isSmall
                />
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

EditManufacturer.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    getFieldsValue: PropTypes.func
  }).isRequired
};

const EditManufacturerForm = Form.create({ name: 'EditManufacturer' })(
  EditManufacturer
);

export default EditManufacturerForm;
