import React from 'react';
import PropTypes from 'prop-types';
import { Table, Select, Pagination, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import moment from '@/src/services/moment';

import formatCPF from '@/src/utils/formatCPF';
import formatCurrency from '@/src/utils/formatCurrency';
// import orderStatusInfo from '@/src/globals/orderStatusInfo';
import StatusPill from '@/src/components/StatusPill';

// import usePermissions from '@/src/hooks/usePermissions';
// import * as modules from '@/src/globals/permissionsModules';

const { Option } = Select;

const SubscriptionsTable = props => {
  const {
    subscriptions,
    count,
    query,
    history,
    isLoading,
    populateSubscriptionsTable
  } = props;
  // const { routePermission } = usePermissions();
  // const customersPermission = routePermission(modules.CUSTOMERS);
  const columns = [
    {
      title: 'ID',
      key: 'alerts',
      align: 'center',
      dataIndex: 'id',
      render: (text, record) => (
        <Link
          data-test="subscription-number"
          to={`/assinaturas/${record.id}`}
          style={{
            border: '0',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'transparent',
            color: 'inherit'
          }}
        >
          {text}
        </Link>
      )
    },
    {
      title: 'Data Criação',
      dataIndex: 'attributes',
      key: 'created_at',
      width: '12%',
      // sorter: true,
      render: record => {
        return moment(record.created_at).format('DD/MM/YY - HH:mm');
      }
    },
    {
      title: 'Nome Cliente',
      dataIndex: 'attributes',
      key: 'customer_name',
      // sorter: true,
      render: record => {
        return (
          <Link
            data-test="subscription-customer"
            to={`/clientes/${record.customer_id}`}
            style={{
              border: '0',
              textDecoration: 'underline',
              cursor: 'pointer',
              background: 'transparent',
              color: 'inherit'
            }}
          >
            {record.customer_name}
          </Link>
        );
      }
    },
    {
      title: 'CPF',
      dataIndex: 'attributes',
      key: 'customer_document',
      width: '12%',
      render: record => {
        return record.customer_document
          ? formatCPF(record.customer_document)
          : '-';
      }
    },
    {
      title: 'Itens',
      dataIndex: 'attributes',
      key: 'items_count',
      width: '8%',
      // sorter: true,
      align: 'center',
      render: record => {
        return record.items_count;
      }
    },
    {
      title: 'Ciclo Atual',
      dataIndex: 'attributes',
      key: 'current_cycle',
      width: '8%',
      align: 'center',
      // sorter: true,
      render: record => {
        // console.log(record);
        return record?.current_cycle;
      }
    },
    {
      title: 'Frequência',
      dataIndex: 'attributes',
      key: 'interval',
      width: '10%',
      // sorter: true,
      render: record => {
        return record.interval || '-';
      }
    },
    {
      title: 'Nome',
      dataIndex: 'attributes',
      key: 'name',
      // sorter: true,
      render: record => {
        return record.name || '';
      }
    },
    {
      title: 'Total',
      dataIndex: 'attributes',
      key: 'total',
      // sorter: true,
      render: record => {
        return formatCurrency(record.total || 0) || '-';
      }
    },
    {
      title: 'Status',
      dataIndex: 'attributes',
      key: 'status',
      // sorter: true,
      render: record => {
        return <StatusPill status={record?.status} centered />;
        // let status = '';
        // let color = 'red';
        // switch (record?.status) {
        //   case 'active':
        //     color = '#33d6ef';
        //     status = 'Ativa';
        //     break;
        //   case 'inactive':
        //     color = '#b3b3b3';
        //     status = 'Inativa';
        //     break;
        //   default:
        //     break;
        // }

        // return (
        //   <span style={{ color, fontWeight: 'bold' }}>
        //     {status || record?.status || '-'}
        //   </span>
        // );
      }
    }
  ];

  const columnSort = () => {
    const urlQuery = history.location.search;
    if (urlQuery.includes('&sort')) {
      const sort = urlQuery.split('&sort')[1];
      const sortParams = {
        column: sort
          .split('=')[0]
          .replace('[', '')
          .replace(']', ''),
        order: sort
          .split('=')[1]
          .replace('desc', 'descend')
          .replace('asc', 'ascend')
      };
      columns.forEach(el => {
        if (el.key === sortParams.column) {
          Object.assign(el, { defaultSortOrder: sortParams.order });
        }
      });
    }
  };
  columnSort();

  const changePagination = (page, perPage) =>
    populateSubscriptionsTable({
      query: {
        ...query,
        page,
        perPage
      }
    });
  const changePerPage = (current, perPage) =>
    populateSubscriptionsTable({
      query: {
        ...query,
        page: query.perPage !== perPage ? 1 : current,
        perPage
      }
    });

  const handleDaysAgoChange = value =>
    populateSubscriptionsTable({
      query: {
        ...query,
        page: 1,
        daysAgo: value
      }
    });

  return (
    <>
      <Row
        type="flex"
        gutter={16}
        justify="space-between"
        style={{ marginBottom: 20 }}
      >
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="table-results">
            {subscriptions.length > 0 && (
              <p data-test-id="filtered-orders-length">
                <span>{count}</span>{' '}
                {count === 1 ? 'assinatura ' : 'assinaturas '}
                {/* <span>({formatCurrency(total)})</span> */}
              </p>
            )}
          </div>
        </Col>
        <Col>
          <Row type="flex" gutter={16}>
            <Col>
              <Select
                style={{ width: 150 }}
                value={query.daysAgo}
                onChange={handleDaysAgoChange}
              >
                <Option value="all">Todos</Option>
                <Option value="1">Do dia</Option>
                <Option value="7">Últimos 7 dias</Option>
                <Option value="30">Últimos 30 dias</Option>
                <Option value="60">Últimos 2 meses</Option>
                <Option value="90">Últimos 3 meses</Option>
                <Option value="180">Últimos 6 meses</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                showSizeChanger
                onChange={changePagination}
                onShowSizeChange={changePerPage}
                pageSize={query.perPage}
                pageSizeOptions={['10', '20', '30', '50', '100']}
                total={count}
                current={query.page}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className="even-gray"
        // className={`orders-table ${className}`}
        // data-test="orders-table"
        // onRow={record => {
        //   // if (!rowClick) return false;
        //   return {
        //     onClick: () => {
        //       history.push(`/assinaturas/${record.id}`);
        //     }
        //   };
        // }}
        // rowClassName="has-click"
        columns={columns}
        dataSource={subscriptions}
        loading={isLoading}
        size="medium"
        rowKey={record => record.id}
        pagination={false}
        onChange={(pagination, filter, sorter) => {
          const hasSortOrder = () => {
            return `[${sorter.columnKey}]=${sorter.order.replace('end', '')}`;
          };
          const onChangeQuery = {
            query: {
              ...query,
              page: 1,
              sort: sorter.order ? hasSortOrder() : ''
            }
          };
          populateSubscriptionsTable(onChangeQuery);
        }}
        style={{ paddingTop: isLoading ? '4rem' : '0' }}
        // rowClassName={record => {
        //   if (record?.fraudulent) return 'banned';
        //   if (record?.hasAlert) return 'has-alert';
        //   return '';
        // }}
      />

      <Row type="flex" gutter={16} justify="end" style={{ marginTop: 20 }}>
        <Col>
          <Pagination
            showSizeChanger
            onChange={changePagination}
            onShowSizeChange={changePerPage}
            pageSize={query.perPage}
            pageSizeOptions={['10', '20', '30', '50', '100']}
            total={count}
            current={query.page}
          />
        </Col>
      </Row>
    </>
  );
};

SubscriptionsTable.defaultProps = {
  subscriptions: [],
  count: 0,
  query: {
    page: 1,
    perPage: 20,
    daysAgo: 'all',
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  isLoading: false
};

SubscriptionsTable.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
  populateSubscriptionsTable: PropTypes.func.isRequired,
  count: PropTypes.number,
  query: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    daysAgo: PropTypes.string,
    search: PropTypes.string,
    sort: PropTypes.string,
    url: PropTypes.string,
    filters: PropTypes.shape({})
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string
    })
  }).isRequired,
  isLoading: PropTypes.bool
};

export default SubscriptionsTable;
