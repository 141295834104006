import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Button, Col } from 'antd';

import Tables from '@/src/components/Tables/index';
import Searchbar from '@/src/components/Searchbar/Searchbar';

import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';

import {
  getUsers,
  setPagination,
  setSort
  // setUsersNew
} from '@/src/store/modules/users/slice';

export const UsersPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const switchTypeContentToShow = 'users';
  const visiblePaginationUp = true;
  const visiblePaginationDown = true;

  const { users, query, usersIsLoading, count } = useSelector(
    state => state.users
  );

  const { permissions, account } = useSelector(state => state.account);

  const getUsersHandler = useCallback(
    urlQuery => {
      let filters = urlQuery.query?.filters;
      const hasSearch = urlQuery.query?.filters.filter(
        item => item.key === 'search'
      );

      if (hasSearch.length > 0) {
        filters = urlQuery.query?.filters.filter(item => item.key !== 'banned');
        console.log('withoutBannedFilter', filters);
      } else {
        filters = [...filters, { key: 'banned', type: 'eq', value: false }];
      }
      const formattedQuery = { ...urlQuery.query, filters };

      dispatch(
        getUsers({
          query: {
            ...formattedQuery,
            stats: [{ key: 'total', value: 'count' }]
          }
        })
      );
    },
    [dispatch]
  );

  const setPaginationHandler = useCallback(
    payload => {
      dispatch(setPagination({ ...payload }));
    },
    [dispatch]
  );

  const setSortHandler = useCallback(
    payload => {
      dispatch(setSort({ ...payload }));
    },
    [dispatch]
  );

  useEffect(() => {
    const params = buildQueryFromUrl(history);

    const authKinds = [];
    const filters = [];
    let showUsers = true;
    // const permissionTest = [
    //   {name: 'auth.users.manage', kind: 'none'},
    //   {name: 'auth.users.manage_admin', kind: 'edit'},
    //   {name: 'auth.users.manage_stockist', kind: 'none'},
    //   {name: 'auth.users.manage_deliverer', kind: 'none'},
    //   {name: 'admin_storefront.gateway_stores.manage', kind: 'edit'}
    // ]
    const usersManageAuth = permissions.filter(
      permission => permission.name.indexOf('auth') !== -1
    );
    if (account.kind !== 'office') {
      usersManageAuth.forEach(auth => {
        if (auth.kind === 'edit') {
          let kind = '';

          if (auth.name.indexOf('deliverer') !== -1) kind = 'deliverer';
          if (auth.name.indexOf('admin') !== -1) kind = 'office';
          if (auth.name.indexOf('stockist') !== -1) kind = 'stockist';

          if (kind !== '') {
            authKinds.push(kind);
          }
        }
      });
      if (authKinds.length < 1) {
        showUsers = false;
      }
    }

    if (authKinds.length > 0) {
      filters.push({
        key: 'kind',
        type: 'eq',
        value: authKinds
      });
    }

    if (showUsers) {
      getUsersHandler({
        query: {
          ...params,
          filters
        }
      });
    }
    // eslint-disable-next-line
  }, [dispatch]);

  // const createNewUser = payload => dispatch(setUsersNew(payload));

  return (
    <>
      <Row type="flex" justify="space-between" className="mb-20">
        <h1 className="m-0">Usuários</h1>
        <Button type="primary" onClick={() => history.push('/usuarios/novo')}>
          Novo usuário
        </Button>
      </Row>
      <div className="section-wrapper">
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{ marginBottom: 20 }}
        >
          <Col span={16}>
            <Searchbar
              placeholder="Buscar nome ou e-mail do usuário "
              populateResult={getUsersHandler}
              query={query}
              value={query.search}
              dispatchModule
            />
          </Col>
          {/* <Col span={7}>
            <Button type="secondary">Exportar CSV</Button>
          </Col> */}
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <Tables
              contentToShow={switchTypeContentToShow}
              visiblePaginationUp={visiblePaginationUp}
              visiblePaginationDown={visiblePaginationDown}
              showCountFilters
              isLoading={usersIsLoading}
              data={users}
              history={history}
              count={count}
              query={query}
              pagination={false}
              setPaginationState={setPaginationHandler}
              setSortState={setSortHandler}
              populateTables={getUsersHandler}
              rowClassName={record =>
                record?.attributes.banned ? 'banned has-click' : 'has-click'
              }
              rowClick={id => history.push(`/usuarios/${id}`)}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UsersPage;
