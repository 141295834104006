import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Tooltip,
  Form,
  Icon,
  Input,
  Select,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Menu,
  Alert
} from 'antd';
import PropTypes from 'prop-types';

import Attributes from '@/src/components/Attributes';
import CategoryCustomFields from '@/src/components/CustomFields/Category/index';
import UploadImage from '@/src/components/UploadImage';
import Audit from '@/src/components/Audit/index';

import {
  setAllCategories,
  getArchiveandUnarchive,
  getCatalogAttributes,
  getDepartments,
  getEdit,
  getUpdateCategory
} from '@/src/store/modules/catalog-categories/slice';
import { getCustomFields } from '@/src/store/modules/custom-fields/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

const { Option } = Select;

const EditCategory = props => {
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;

  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);
  // const [uploadWebImage, setUploadWebImage] = useState([]);
  // const [withoutWebImage, setWithoutWebImage] = useState(false);
  const [propertiesValue, setPropertiesValue] = useState([]);
  const [customFieldsValue, setCustomFieldsValue] = useState([]);
  const [showAudit, setShowAudit] = useState(false);

  const [inputDepartment, setInputDepartment] = useState('');

  const [slugPrefix, setSlugPrefix] = useState('');
  const [slugWithoutPrefix, setSlugWithoutPrefix] = useState('');

  const dispatch = useDispatch();
  const { id } = useParams();

  const { editPermission } = usePermissions();
  const catalogPermission = editPermission(modules.CATALOG);

  const {
    attributes,
    departments,
    editCategory,
    updated,
    isLoading
  } = useSelector(state => state.catalogCategories);
  const { customFields } = useSelector(state => state.customFields);

  // console.log(customFields);

  const archiveAisleHandle = () => {
    dispatch(getArchiveandUnarchive({ id, type: 'archive' }));
  };

  const unarchiveAisleHandle = () => {
    dispatch(getArchiveandUnarchive({ id, type: 'unarchive' }));
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
      {!editCategory?.data?.attributes?.archived_at ? (
        <Menu.Item key="1" onClick={archiveAisleHandle}>
          Arquivar
        </Menu.Item>
      ) : (
        <Menu.Item key="2" onClick={unarchiveAisleHandle}>
          Desarquivar
        </Menu.Item>
      )}
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  const checkIfHasImage = () => {
    setWithoutImage(uploadImage.length === 0);
    // setWithoutWebImage(uploadWebImage.length === 0);
    // if (uploadImage.length === 0 || uploadWebImage.length === 0) return false;
    if (uploadImage.length === 0) return false;
    return true;
  };

  const handleSubmitWithAllValues = values => {
    if (checkIfHasImage()) {
      const removeData = dataString =>
        dataString.replace(/data:image\/[\w]+;base64,/g, '');

      let imageChange = '';
      // let webImageChange = '';

      if (uploadImage.includes('data:image')) {
        imageChange = removeData(uploadImage);
      }

      // if (uploadWebImage.includes('data:image')) {
      //   webImageChange = removeData(uploadWebImage);
      // }

      const departamentId = departments.find(
        attr => attr.attributes.name === values.department_id
      );
      const slug = editCategory.data?.attributes?.archived_at
        ? `${slugWithoutPrefix}`
        : values.search_slug;

      const payload = {
        categoryId: id,
        data: {
          department_id: departamentId
            ? departamentId.attributes.id
            : Number(values.department_id),
          name: values.name,
          description: values.description,
          search_metadata: values.search_metadata || '',
          image: imageChange,
          // web_image: webImageChange,
          search_slug: slug,
          title: values.title || '',
          meta_title: values.meta_title || '',
          meta_description: values.meta_description || '',
          properties: propertiesValue,
          custom_fields: customFieldsValue.map(item => ({ id: item }))
        }
      };
      dispatch(getUpdateCategory(payload));
    }
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        checkIfHasImage();
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  // const takeAttributes = attributes_id => {
  //   dispatch(
  //     getCatalogAttributes({
  //       query: {
  //         perPage: 20,
  //         page: 1,
  //         search: '',
  //         value: '',
  //         filters: {
  //           by_department: attributes_id
  //         }
  //       }
  //     })
  //   );
  // };

  useEffect(() => {
    if (inputDepartment && editCategory.data?.attributes?.archived_at) {
      const dep = departments.find(item => item.id === inputDepartment);
      setSlugPrefix(`${dep.attributes.name}/`);
    }
    // eslint-disable-next-line
  }, [inputDepartment]);

  const populateProperties = values => {
    setPropertiesValue(values || []);
  };

  useEffect(() => {
    dispatch(getEdit(id));
    dispatch(getDepartments());
    dispatch(getCustomFields({ query: { perPage: 200 } }));
    dispatch(
      setAllCategories({
        categories: [],
        query: {},
        count: 0
      })
    );
  }, [id, dispatch]);

  useEffect(() => {
    const categoryCustomFields =
      editCategory?.included?.filter(item => item.type === 'custom_fields') ||
      [];
    setCustomFieldsValue(categoryCustomFields.map(item => item.id));

    if (Object.keys(editCategory).length > 0) {
      if (editCategory?.data?.attributes?.department_id) {
        dispatch(getCatalogAttributes());
      }
      setUploadImage(editCategory?.data?.attributes?.image);
      // setUploadWebImage(editCategory?.data?.attributes?.web_image);
      const { search_slug } = editCategory?.data?.attributes;
      setSlugPrefix(`${search_slug.split('/')[0]}/`);
      setSlugWithoutPrefix(
        search_slug
          .split('/')
          .slice(1)
          .join('/')
      );
    } else {
      setUploadImage([]);
      // setUploadWebImage([]);
    }
  }, [dispatch, editCategory]);

  return (
    <>
      <Audit
        id={id}
        title="CATEGORIA"
        type="Category"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Editar {editCategory?.data?.attributes?.name}</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/categorias">Categorias</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar categoria</Breadcrumb.Item>
      </Breadcrumb>
      <Form>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '16px',
            border: '7px solid #ebebeb',
            backgroundColor: '#ebebeb',
            marginBottom: '60px'
          }}
        >
          <Row className="card-margin">
            <Card
              className="no-shadow"
              title="DETALHES DA CATEGORIA"
              bordered={false}
              extra={catalogPermission && actionsExtra}
            >
              <Col span={12} className="no-shadow">
                <div
                  style={{
                    width: '80%'
                  }}
                >
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Código Marketing
                      </p>
                    </div>
                    <Form.Item>
                      <Input
                        value={
                          editCategory?.data?.attributes?.internal_id || '–'
                        }
                        disabled
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Nome do Departamento
                      </p>
                      <Tooltip
                        placement="right"
                        title="Nome do departamento que esta categoria pertence"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('department_id', {
                        initialValue:
                          editCategory?.data?.attributes?.department_name,
                        rules: [
                          {
                            required: true,
                            message: 'Selecione um departamento'
                          }
                        ]
                      })(
                        <Select
                          placeholder="Selecione um departamento"
                          onChange={value => setInputDepartment(value)}
                          disabled={!catalogPermission}
                        >
                          {departments?.map(department => (
                            <Option key={department.attributes.id}>
                              {department.attributes.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Nome da Categoria
                      </p>
                      <Tooltip
                        placement="right"
                        title="Nome da categoria que irá aparecer no app"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('name', {
                        initialValue: editCategory?.data?.attributes?.name,
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, preencha o nome da categoria'
                          }
                        ]
                      })(
                        <Input
                          placeholder="Nome da categoria"
                          disabled={!catalogPermission}
                        />
                      )}
                    </Form.Item>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Descrição
                      </p>
                      <Tooltip
                        placement="right"
                        title="Descrição da categoria que irá aparecer no app"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('description', {
                        initialValue:
                          editCategory?.data?.attributes?.description,
                        rules: [
                          {
                            required: true,
                            message: 'Por favor, preencha uma descrição'
                          }
                        ]
                      })(
                        <Input
                          placeholder="Escreva um descrição"
                          disabled={!catalogPermission}
                        />
                      )}
                    </Form.Item>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Palavras-chave
                      </p>
                      <Tooltip
                        placement="right"
                        title="Palavras que facilitam encontrar categoria através da busca"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('search_metadata', {
                        initialValue:
                          editCategory?.data?.attributes?.search_metadata
                      })(
                        <Input.TextArea
                          placeholder="Ex.: alimentos, ração"
                          disabled={!catalogPermission}
                        />
                      )}
                    </Form.Item>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Código Deep Link
                      </p>
                    </div>
                    <Form.Item>
                      <Input
                        value={
                          editCategory?.data?.attributes?.internal_id || '–'
                        }
                        disabled
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Slug
                      </p>
                      <Tooltip
                        placement="right"
                        title="URL da página de categoria na web"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {editCategory.data?.attributes?.archived_at && (
                        <span style={{ marginRight: 4 }}>{slugPrefix}</span>
                      )}
                      <Form.Item style={{ marginBottom: 0 }}>
                        {getFieldDecorator('search_slug', {
                          initialValue:
                            editCategory?.data?.attributes?.search_slug,
                          rules: [
                            {
                              required: true,
                              message: 'Por favor, preencha o slug'
                            }
                          ]
                        })(
                          <>
                            <Input
                              placeholder="Escreva o slug da categoria"
                              // disabled={!catalogPermission}
                              value={
                                editCategory.data?.attributes?.archived_at
                                  ? slugWithoutPrefix
                                  : editCategory?.data?.attributes?.search_slug
                              }
                              onChange={e =>
                                setSlugWithoutPrefix(e.target.value)
                              }
                              disabled={
                                !editCategory.data?.attributes?.archived_at
                              }
                            />
                          </>
                        )}
                      </Form.Item>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col span={12} className="no-shadow">
                <div style={{ width: '80%' }}>
                  <Row style={{ marginBottom: 24 }}>
                    <p style={{ marginBottom: 8, fontWeight: 'bold' }}>
                      Imagem Categoria
                    </p>
                    <UploadImage
                      setUploadImage={setUploadImage}
                      uploadImage={uploadImage}
                      withoutImage={withoutImage}
                      setWithoutImage={setWithoutImage}
                      recommendedSize={[400, 400]}
                      editPermission={catalogPermission}
                    />
                  </Row>
                  {/* <Row>
                  <p style={{ marginBottom: 8, fontWeight: 'bold' }}>
                    Imagem Web (Menu)
                  </p>
                  <UploadImage
                    setUploadImage={setUploadWebImage}
                    uploadImage={uploadWebImage}
                    withoutImage={withoutWebImage}
                    setWithoutImage={setWithoutWebImage}
                    recommendedSize={[400, 400]}
                    editPermission={catalogPermission}
                  />
                </Row> */}
                </div>
              </Col>
            </Card>
          </Row>
          <Row gutter={7} type="flex">
            <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Card
                className="no-shadow card-margin"
                title="SEO (WEB)"
                bordered={false}
              >
                <div
                  style={{
                    width: '80%'
                  }}
                >
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Title
                      </p>
                      <Tooltip
                        placement="right"
                        title="Título da vitrine exibido na página da categoria. Deve conter nome da categoria e do departamento"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item style={{ marginBottom: 2 }}>
                      {getFieldDecorator('title', {
                        initialValue: editCategory?.data?.attributes?.title
                      })(
                        <Input
                          placeholder="Exemplo: Alimentos para Cães"
                          disabled={!catalogPermission}
                        />
                      )}
                    </Form.Item>
                    <span style={{ display: 'block', marginBottom: 24 }}>
                      *Caso não preenchido, será o nome da categoria
                    </span>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Meta Title
                      </p>
                      <Tooltip
                        placement="right"
                        title="Título da vitrine exibido em ferramentas de busca"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('meta_title', {
                        initialValue: editCategory?.data?.attributes?.meta_title
                      })(
                        <Input
                          placeholder="Exemplo: Zee.Now - Nome da categoria"
                          disabled={!catalogPermission}
                        />
                      )}
                    </Form.Item>
                  </Row>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 4
                      }}
                    >
                      <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                        Meta Description
                      </p>
                      <Tooltip
                        placement="right"
                        title="Descrição da vitrine exibida em ferramentas de busca"
                      >
                        <Icon style={{ marginLeft: 6 }} type="info-circle" />
                      </Tooltip>
                    </div>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator('meta_description', {
                        initialValue:
                          editCategory?.data?.attributes?.meta_description
                      })(
                        <Input.TextArea
                          placeholder="Breve descrição da categoria"
                          disabled={!catalogPermission}
                        />
                      )}
                    </Form.Item>
                  </Row>
                </div>
              </Card>
              <CategoryCustomFields
                data={customFields}
                value={customFieldsValue}
                setValue={setCustomFieldsValue}
                editPage
              />
            </Col>
            <Col span={12} style={{ display: 'flex', flexGrow: 1 }}>
              <Card
                className="no-shadow w-100"
                title="ATRIBUTOS"
                bordered={false}
              >
                <p>
                  Selecione os atributos disponíveis para produtos desta
                  categoria
                </p>
                <span
                  style={{
                    display: 'block',
                    margin: '8px 0'
                  }}
                >
                  <Alert
                    message="Ao remover um atributo já
                      associado e salvar a alteração, ele também será removido
                      de todos os produtos da categoria."
                    type="info"
                    className="properties-alert"
                  />
                </span>
                <Attributes
                  attributesList={attributes}
                  settedAttributes={
                    editCategory?.included
                      ? editCategory?.included
                          ?.filter(item => item.type === 'property')
                          .map(item => item.id)
                      : []
                  }
                  populateProperties={populateProperties}
                  loading={isLoading}
                  editPermission={catalogPermission}
                />
              </Card>
            </Col>
          </Row>
        </div>
        {catalogPermission && (
          <footer className="footer-form-save">
            <Button
              className="ant-btn ant-btn-primary"
              style={{ left: '80%' }}
              onClick={onSubmit}
              loading={updated}
            >
              Salvar Alterações
            </Button>
          </footer>
        )}
      </Form>
    </>
  );
};

EditCategory.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const NewCategoryFrom = Form.create({ name: 'EditCategory' })(EditCategory);

export default NewCategoryFrom;
