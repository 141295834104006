import React, { useState } from 'react';
import { Spin } from 'antd';

import styles from '@/src/pages/Reports/Reports.module.scss';

const ReportOperationalCancellations = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoader = () => {
    console.log('loaded');
    setIsLoading(false);
  };
  return (
    <>
      {isLoading ? (
        <Spin
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
      ) : null}
      <iframe
        className={styles.Report}
        title="reportsBI"
        // width={isLoading ? '0' : '100%'}
        // height={isLoading ? '0' : '100%'}
        src="https://app.powerbi.com/view?r=eyJrIjoiMzk3MGViMWYtNjY1Ni00MzMzLWI1NDYtNWQ2MTlmODIzNTVmIiwidCI6IjJkZTQwZmNhLTFhMmYtNDFlYS04ZTc1LTQxZThmY2VhNGRmMCJ9"
        frameBorder="0"
        allowFullScreen
        onLoad={handleLoader}
        id="reports"
      />
    </>
  );
};

export default ReportOperationalCancellations;
