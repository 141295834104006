import IMMUTABLE_PROMOS from '@/src/globals/immutablePromos';

// const isImmutablePromo = type =>
//   IMMUTABLE_PROMOS.some(promoType => promoType === type);

const isImmutablePromo = promotion =>
  IMMUTABLE_PROMOS.some(promoType => promoType === promotion.promotion_type) ||
  !promotion.applicability_type;

export default isImmutablePromo;
