import React from 'react';
import PropTypes from 'prop-types';
import { notification, Modal } from 'antd';
// import { message } from 'antd';

import { ZEENOW_STORE } from '@/src/globals/constants';

import getBase64 from '@/src/utils/getImageBase64';
import SingleUpload from './singleUpload';
import MultipleUpload from './multipleUpload';
import MiniatureUpload from './miniatureUpload';

const UploadImage = ({
  uploadImage,
  setUploadImage,
  withoutImage,
  setWithoutImage,
  handleDeleteImage,
  handleImageError,
  isMultiple,
  type,
  isRound,
  isSmall,
  isMiniature,
  recommendedSize,
  editPermission
}) => {
  const getFile = async file => {
    const image = file;
    if (!image.url && !image.preview) {
      image.preview = await getBase64(image);
    }
    return image;
  };

  const checkImgType = file =>
    file.type === 'image/jpeg' ||
    file.type === 'image/jpg' ||
    file.type === 'image/png';

  // const checkImgSize = file => file.size / 1024 / 1024 < 2;

  const handleBeforeUpload = file => {
    if (!checkImgType(file)) {
      // message.error('Apenas arquivos JPG/PNG são aceitos');
      notification.error({
        message: `Erro`,
        duration: 4,
        className: 'error',
        description: 'Apenas arquivos JPG/PNG são aceitos'
      });
      return false;
    }
    // if (!checkImgSize(file)) {
    //   message.error('Imagem deve ser menor que 2MB!');
    //   return false;
    // }
    // setUploadImage([file]);
    return false;
  };

  const handleMultipleUploadChange = async data => {
    const { file } = data;
    const image = await getFile(file);
    const b64 = image.preview;
    const newImg = { url: b64, filename: file.name };
    if (type === 'ifood') {
      const imageEl = new Image();
      imageEl.src = b64;
      imageEl.onload = function() {
        const { width, height } = this;
        const aspectRatio = width / height;
        if (Math.round(aspectRatio * 1000) / 1000 === 1060 / 800) {
          setUploadImage([newImg]);
        } else {
          // message.error('Apenas imagens no tamanho 1060px x 800px são aceitas');
          notification.error({
            message: `Erro`,
            duration: 4,
            className: 'error',
            description:
              'A imagem iFood deve estar no tamanho 1060px x 800px ou mesma proporção'
          });
        }
      };
    } else if (type === 'thumbnail') {
      setUploadImage([newImg]);
    } else {
      setUploadImage(prev => [...prev, newImg]);
    }
    setWithoutImage(false);
  };

  const handleUploadChange = async file => {
    if (checkImgType(file.file)) {
      const image = await getFile(file.file);
      const b64 = image.preview;
      setUploadImage(b64);
      setWithoutImage(false);
    }
    return false;
  };

  const removeAllImages = () => {
    setUploadImage([]);
    setWithoutImage(false);
  };

  const handleMultipleUploadDelete = deleteImage => {
    Modal.confirm({
      title: 'DELETAR IMAGEM',
      okText: 'Sim',
      cancelText: 'Não',
      centered: true,
      content: 'Tem certeza que deseja remover esta imagem?',
      onOk() {
        setUploadImage(prev => {
          return prev.filter(image => image.url !== deleteImage.url);
        });
        handleDeleteImage(deleteImage.id);
        setWithoutImage(false);
      },
      onCancel() {}
    });
  };

  const handleMultipleUploadMetadata = (image, value) => {
    setUploadImage(prev => {
      return prev.map(item => {
        if (item.url === image.url) {
          return { ...item, metadata: value };
        }
        return item;
      });
    });
  };

  const handleSortImages = images => setUploadImage(images);

  if (isMultiple)
    return (
      <>
        <MultipleUpload
          type={type}
          editPermission={editPermission}
          uploadImages={uploadImage || []}
          handleSortImages={handleSortImages}
          handleBeforeUpload={handleBeforeUpload}
          handleUploadChange={handleMultipleUploadChange}
          handleDeleteImage={handleMultipleUploadDelete}
          handleImageMetadata={handleMultipleUploadMetadata}
          handleImageError={handleImageError}
        />
        {uploadImage?.length > 0 && (
          <span
            style={{
              display: 'block',
              width: '100%',
              textAlign: 'center',
              lineHeight: '16px'
            }}
          >
            {type !== 'thumbnail' && type !== 'ifood'
              ? `Após carregar as imagens, arraste para ordenar como deverá aparecer no ${
                  ZEENOW_STORE ? 'app/site' : 'site'
                }`
              : 'Apenas uma imagem poderá ser carregada neste campo'}
          </span>
        )}
      </>
    );

  if (isMiniature)
    return (
      <MiniatureUpload
        uploadImage={uploadImage}
        withoutImage={withoutImage}
        handleBeforeUpload={handleBeforeUpload}
        handleUploadChange={handleUploadChange}
        handleImageError={handleImageError}
      />
    );

  return (
    <SingleUpload
      uploadImage={uploadImage}
      withoutImage={withoutImage}
      handleBeforeUpload={handleBeforeUpload}
      handleUploadChange={handleUploadChange}
      handleDeleteImage={removeAllImages}
      handleImageError={handleImageError}
      isRound={isRound}
      isSmall={isSmall}
      recommendedSize={recommendedSize}
      editPermission={editPermission}
    />
  );
};

UploadImage.defaultProps = {
  isMultiple: false,
  type: '',
  handleDeleteImage: () => {},
  handleImageError: () => {},
  // handleImageError: event => {
  //   event.persist();
  //   event.target.classList.add('hidden');
  // },
  isRound: false,
  isSmall: false,
  isMiniature: false,
  recommendedSize: [],
  editPermission: true
};

UploadImage.propTypes = {
  isMultiple: PropTypes.bool,
  type: PropTypes.string,
  setUploadImage: PropTypes.func.isRequired,
  setWithoutImage: PropTypes.func.isRequired,
  withoutImage: PropTypes.bool.isRequired,
  handleDeleteImage: PropTypes.oneOfType([PropTypes.func]),
  handleImageError: PropTypes.func,
  uploadImage: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.string
  ]).isRequired,
  isRound: PropTypes.bool,
  isSmall: PropTypes.bool,
  isMiniature: PropTypes.bool,
  recommendedSize: PropTypes.arrayOf(PropTypes.string),
  editPermission: PropTypes.bool
};

export default UploadImage;
