import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Modal, Row, Form, Radio, Select, Tooltip, Icon } from 'antd';

import {
  createCustomField,
  updateCustomField
} from '@/src/store/modules/custom-fields/slice';

const ModalCustomField = props => {
  const { data, content, setContent, form } = props;

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setContent('');
    form.setFieldsValue({
      name: undefined,
      media_type: undefined,
      field_type: undefined
    });
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) return;
      if (content === 'edit') {
        const payload = {
          id: values.id,
          data: {
            name: values.name,
            media_type: values.media_type,
            field_type: values.field_type
          }
        };
        dispatch(updateCustomField(payload));
      } else {
        const payload = {
          ...values
        };
        dispatch(createCustomField(payload));
      }
      handleCloseModal();
    });
  };

  const handleSelectField = value => {
    const field = data.find(item => item.id === value);
    form.setFieldsValue({
      name: field.attributes.name,
      media_type: field.attributes.media_type,
      field_type: field.attributes.field_type
    });
    // setDisabled(false);
  };

  return (
    <Modal
      title={
        <h2>{`${content === 'edit' ? 'EDITAR' : 'NOVO'} CAMPO CUSTOMIZADO`}</h2>
      }
      okText="Salvar"
      visible={content !== ''}
      onOk={handleSubmit}
      onCancel={handleCloseModal}
      className="ant-modal-hubs centered-footer"
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      destroyOnClose
    >
      <Form>
        {content === 'edit' ? (
          <Row>
            <p style={{ marginBottom: 32 }}>
              Selecione o campo que deseja editar
            </p>
            <Form.Item>
              {form.getFieldDecorator('id', {
                rules: [
                  {
                    required: true,
                    message: 'Campo obrigatório'
                  }
                ]
              })(
                <Select
                  onChange={handleSelectField}
                  placeholder="Selecione um campo customizado"
                >
                  {[...data]
                    .sort((a, b) =>
                      a.attributes.name.localeCompare(b.attributes.name)
                    )
                    .map(item => (
                      <Select.Option key={item.id}>
                        {item.attributes.name}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Row>
        ) : (
          <p style={{ marginBottom: 32 }}>
            Insira os dados do campo customizado
          </p>
        )}
        <Row>
          <h3>Nome</h3>
          <Form.Item>
            {form.getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Campo obrigatório'
                }
              ]
            })(
              <Input
                disabled={content === 'edit' && !form.getFieldValue('id')}
                placeholder="Digite o nome"
              />
            )}
          </Form.Item>
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5em'
            }}
          >
            <h3 style={{ marginBottom: 0 }}>Canal</h3>
            <Tooltip
              placement="right"
              title="Campo disponível para site e/ou app"
            >
              <Icon type="info-circle" />
            </Tooltip>
          </div>
          <Form.Item>
            {form.getFieldDecorator('media_type', {
              rules: [
                {
                  required: true,
                  message: 'Campo obrigatório'
                }
              ]
            })(
              <Select
                disabled={content === 'edit' && !form.getFieldValue('id')}
                placeholder="Selecione um ou mais canais"
                mode="multiple"
              >
                <Select.Option value="web">Site</Select.Option>
                <Select.Option value="mobile">App</Select.Option>
              </Select>
            )}
          </Form.Item>
        </Row>
        <Row>
          <h3>Tipo</h3>
          <Form.Item>
            {form.getFieldDecorator('field_type', {
              rules: [
                {
                  required: true,
                  message: 'Campo obrigatório'
                }
              ]
            })(
              <Radio.Group
                disabled={content === 'edit' && !form.getFieldValue('id')}
              >
                <Radio value="text">Texto</Radio>
                <Radio value="json">JSON</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

ModalCustomField.defaultProps = {
  data: []
};

ModalCustomField.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  content: PropTypes.bool.isRequired,
  setContent: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired
};

const ModalCustomFieldForm = Form.create({ name: 'custom-field-form' })(
  ModalCustomField
);

export default ModalCustomFieldForm;
