import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Upload, Tooltip } from 'antd';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import withScrolling from 'react-dnd-scrolling';

import ImagePreview from './imagePreview';
import MetadataModal from './metadataModal';

const ScrollingComponent = withScrolling('div');

const MultipleUpload = ({
  type,
  editPermission,
  uploadImages,
  handleSortImages,
  handleBeforeUpload,
  handleUploadChange,
  handleDeleteImage,
  handleImageMetadata,
  handleImageError
}) => {
  const isMultiple = type !== 'thumbnail' && type !== 'ifood';

  const [modalImage, setModalImage] = useState({});

  const {
    product: { id }
  } = useSelector(state => state.productDetails);

  const handleUpload = file => {
    handleUploadChange(file, uploadImages);
  };

  const handleDownload = async () => {
    let imgType = 'thumb';
    if (type === 'carousel') imgType = 'carrossel';
    if (type === 'special_content') imgType = 'conteudo_especial';
    if (type === 'ifood') imgType = 'ifood';

    const zip = new JSZip();
    const folder = zip.folder('collection');

    const imagesArr = [];

    const promises = uploadImages
      .filter(item => item.url)
      .map(async (item, index) => {
        const imgBlob = await fetch(item.url)
          .then(response => response.blob())
          .catch(err => console.log(err));
        if (imgBlob) {
          const imgFile = new File([imgBlob], 'filename.jpg');
          const fileName =
            imgType === 'thumb'
              ? `product_${id}_${imgType}.jpg`
              : `product_${id}_${imgType}_${index + 1}.jpg`;
          folder.file(fileName, imgFile);
          imagesArr.push(item.id);
        }
      });
    Promise.all(promises).then(() => {
      if (imagesArr.length > 0) {
        folder
          .generateAsync({ type: 'blob' })
          .then(content => saveAs(content, `product_${id}_${imgType}`));
      }
    });
  };

  const moveImage = useCallback((dragIndex, hoverIndex) => {
    handleSortImages(prevCards =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    );
    // eslint-disable-next-line
  }, []);

  const renderImage = useCallback((image, index) => {
    return (
      <div key={index} className="multiple-preview-container">
        <ImagePreview
          image={image}
          index={index}
          moveImage={moveImage}
          editPermission={editPermission}
          type={type}
          handleImageError={handleImageError}
          handleDeleteImage={handleDeleteImage}
          setModalImage={setModalImage}
        />
      </div>
    );
    // eslint-disable-next-line
  }, []);

  const container = document.querySelector('.multiple-preview-list');
  const preview = document.querySelector('.multiple-preview-container');

  let noScroll = true;

  if (container && preview) {
    const previewWidth = preview.clientWidth * uploadImages.length;
    if (previewWidth > container.clientWidth) noScroll = false;
  }

  return (
    <>
      <MetadataModal
        image={modalImage}
        setImage={setModalImage}
        handleImageMetadata={handleImageMetadata}
        handleImageError={handleImageError}
      />
      <div
        style={
          isMultiple
            ? { display: 'flex' }
            : { display: 'flex', flexDirection: 'column', alignItems: 'center' }
        }
      >
        {editPermission && (
          <>
            <Upload
              className="multiple-image-upload"
              accept="image/*"
              listType="picture"
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              onChange={handleUpload}
              multiple={isMultiple}
              fileList={uploadImages}
            >
              <Tooltip
                title={isMultiple ? 'Cadastrar imagens' : 'Cadastrar imagem'}
              >
                <Button>
                  <Icon type="upload" />
                </Button>
              </Tooltip>
            </Upload>
            <div className="multiple-image-upload download">
              <Tooltip title={isMultiple ? 'Baixar imagens' : 'Baixar imagem'}>
                <Button onClick={handleDownload}>
                  <Icon type="download" />
                </Button>
              </Tooltip>
            </div>
          </>
        )}

        {uploadImages.length ? (
          <>
            {isMultiple ? (
              <DndProvider backend={HTML5Backend}>
                <ScrollingComponent
                  className={`multiple-preview-list ${
                    noScroll ? 'multiple-preview-no-scroll' : ''
                  }`}
                >
                  {uploadImages.map((image, i) => renderImage(image, i))}
                </ScrollingComponent>
              </DndProvider>
            ) : (
              <div className="multiple-preview-container">
                <div className="multiple-preview-image">
                  {uploadImages[0].url && (
                    <img
                      src={uploadImages[0].url}
                      alt={type}
                      onError={handleImageError}
                    />
                  )}
                  <div className="multiple-preview-image-buttons">
                    {/* {type !== 'thumbnail' && (
                      <Button
                        type="link"
                        onClick={() => setModalImage(uploadImages[0])}
                      >
                        <Icon type="info-circle" />
                      </Button>
                    )} */}
                    <Button
                      type="link"
                      onClick={() => handleDeleteImage(uploadImages[0])}
                    >
                      <Icon type="delete" />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="no-image-preview">
              <Icon
                style={{ fontSize: '24px', color: '#B3B3B3' }}
                type="camera"
                theme="filled"
              />
              <span className="no-image-text">Nenhuma imagem cadastrada</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

MultipleUpload.defaultProps = {
  type: '',
  uploadImages: [],
  editPermission: true,
  handleImageError: () => {}
};

MultipleUpload.propTypes = {
  type: PropTypes.string,
  editPermission: PropTypes.bool,
  uploadImages: PropTypes.oneOfType([PropTypes.array]),
  handleSortImages: PropTypes.func.isRequired,
  handleBeforeUpload: PropTypes.func.isRequired,
  handleUploadChange: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleImageMetadata: PropTypes.func.isRequired,
  handleImageError: PropTypes.func
};

export default MultipleUpload;
