import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const ModalConfirm = ({ visible, setVisible, data }) => {
  return (
    <Modal
      title={<h2>{data?.title}</h2>}
      okText={data?.okText || 'Salvar'}
      visible={visible}
      onOk={data?.onOk}
      onCancel={() => setVisible(false)}
      className="ant-modal-hubs centered-footer"
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
    >
      <p>{data?.body}</p>
    </Modal>
  );
};

ModalConfirm.defaultProps = {
  data: {}
};

ModalConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any)
};

export default ModalConfirm;
