import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';

import Tables from '@/src/components/Tables/index';

const Subscriptions = props => {
  const {
    subscriptionsCustomer,
    isLoading,
    count,
    query,
    getSubscriptionsHandler,
    setPaginationHandler,
    setSortHandler
  } = props;

  return (
    <Card className="no-shadow" title={<h2>Assinaturas</h2>}>
      <Tables
        className="table-LastOrders"
        contentToShow="customerSubscriptions"
        data={subscriptionsCustomer}
        loading={isLoading}
        count={count}
        visiblePaginationUp
        showExpandedRow={false}
        query={query}
        populateTables={getSubscriptionsHandler}
        setPaginationState={setPaginationHandler}
        setSortState={setSortHandler}
      />
    </Card>
  );
};

Subscriptions.defaultProps = {
  query: {
    page: 1,
    perPage: 20,
    daysAgo: '30',
    search: '',
    sort: '',
    url: '',
    filters: {}
  }
};

Subscriptions.propTypes = {
  subscriptionsCustomer: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  query: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    daysAgo: PropTypes.string,
    search: PropTypes.string,
    sort: PropTypes.string,
    url: PropTypes.string,
    filters: PropTypes.shape({})
  }),
  getSubscriptionsHandler: PropTypes.func.isRequired,
  setPaginationHandler: PropTypes.func.isRequired,
  setSortHandler: PropTypes.func.isRequired
};

export default Subscriptions;
