import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import normalize from 'json-api-normalizer';

import { getOrders as getOrdersRequest } from '@/src/api/orders';
import getHubsRequest from '@/src/api/hubs';
import { getDeliverers as getDeliverersRequest } from '@/src/api/deliverers';
import { getCoupons as getCouponsRequest } from '@/src/api/promotions';
import {
  getCustomerLastOrders as getCustomerLastOrdersRequest,
  getCustomerSubscriptions as getCustomerSubscriptionsRequest
} from '@/src/api/customers';
import { exportCSVOrders as exportCSVOrdersRequest } from '@/src/api/export-file';

import {
  setIsLoading,
  setOrders,
  setHubs,
  setDeliverers,
  setCoupons,
  setLastOrderCustomer,
  setSubscriptionsCustomer,
  getOrders,
  getHubs,
  getDeliverers,
  getCoupons,
  goToOrder,
  getLastOrders,
  getSubscriptions,
  exportCSV
} from '@/src/store/modules/orders/slice';

import { getOrderQueryUrlState, getRouterState } from '@/src/store/selectors';

import { errorAlert } from '@/src/utils/notifications';

function* getOrdersSaga(params) {
  const { payload } = params;
  try {
    yield put(setIsLoading(true));
    const response = yield call(getOrdersRequest, payload);
    const normalized = normalize(response.data, {
      endpoint: '/order'
    });
    const count = response.data.meta.count > 0 ? response.data.meta.count : 0;
    const total = response.data.meta.total > 0 ? response.data.meta.total : 0;
    yield put(
      setOrders({
        orders: normalized,
        deliverer_names: response.data.meta.deliverer_names || {},
        hub_names: response.data.meta.hub_names || {},
        count,
        total,
        query: payload.query
      })
    );
    const queryUrl = yield select(getOrderQueryUrlState);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* goToOrderSaga(action) {
  const { payload } = action;
  yield put(push(`/pedidos/${payload.id}`));
}

function* getHubsSaga() {
  try {
    const response = yield call(getHubsRequest);
    yield put(setHubs(response.data.data));
  } catch (err) {
    errorAlert(err);
  }
}

function* getDeliverersSaga(action) {
  const { payload } = action;
  try {
    const response = yield call(getDeliverersRequest, payload);
    yield put(setDeliverers(response.data.data));
  } catch (err) {
    errorAlert(err);
  }
}

function* getCouponsSaga(action) {
  const { payload } = action;
  try {
    const response = yield call(getCouponsRequest, payload);
    yield put(setCoupons(response.data.data));
  } catch (err) {
    errorAlert(err);
  }
}

function* getLastOrderCustomerSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoading(true));
    const response = yield call(getCustomerLastOrdersRequest, payload);
    yield put(
      setLastOrderCustomer({
        lastOrdersCustomer: response.data.data,
        query: payload.query,
        count: response.data.meta.count
      })
    );
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}
function* getSubscriptionsCustomerSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoading(true));
    const response = yield call(getCustomerSubscriptionsRequest, payload);
    yield put(
      setSubscriptionsCustomer({
        subscriptionsCustomer: response.data.data,
        query: payload.query,
        count: response.data.meta.count
      })
    );
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

export function* exportCSVSaga(action) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(exportCSVOrdersRequest, action.payload);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${url.slice(27)}.csv`;
    document.body.appendChild(a);
    a.click();
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
  return yield put(setIsLoading(false));
}

function* ordersSaga() {
  yield takeEvery(getOrders, getOrdersSaga);
  yield takeEvery(goToOrder, goToOrderSaga);
  yield takeEvery(getHubs, getHubsSaga);
  yield takeEvery(getDeliverers, getDeliverersSaga);
  yield takeEvery(getCoupons, getCouponsSaga);
  yield takeEvery(getLastOrders, getLastOrderCustomerSaga);
  yield takeEvery(getSubscriptions, getSubscriptionsCustomerSaga);
  yield takeEvery(exportCSV, exportCSVSaga);
}

export default ordersSaga;
