import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { errorAlert, successAlert } from '@/src/utils/notifications';
import {
  createSkuPetz,
  exportCSV,
  getSkuPetz,
  getSkusPetzList,
  setLoading,
  setSkuPetz,
  setSkusPetzList,
  updateSkuPetz,
  setWarehouseSaleChannels,
  getWarehouseSaleChannels
} from '@/src/store/modules/skus-petz/slice';
import {
  createSkuPetz as createSkuPetzRequest,
  getSkuPetz as getSkuPetzRequest,
  getSkusPetzList as getSkusPetzListRequest,
  updateSkuPetz as updateSkuPetzRequest,
  listWarehouseSaleChannels as listWarehouseSaleChannelsRequest
} from '@/src/api/skus-petz';

import {
  getRouterState,
  getSkusPetzQueryUrlState
} from '@/src/store/selectors';

import { exportCSVPetzSkus as exportCSVSkusPetzRequest } from '@/src/api/export-file';

function* getSkusPetzListSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getSkusPetzListRequest, payload);
    yield put(
      setSkusPetzList({
        data: response.data.data,
        count: response.data.pagy.count,
        query: payload.query
      })
    );
    const queryUrl = yield select(getSkusPetzQueryUrlState);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }
    yield put(setLoading(false));
  } catch (err) {
    console.log(err);
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar a lista de skus');
  }
}

function* createSkuPetzSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(createSkuPetzRequest, payload);
    yield put(setLoading(false));
    yield put(push('/skus-petz'));
    successAlert('Novo sku criado');
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível criar o sku');
  }
}

function* getSkuPetzSaga({ payload }) {
  try {
    yield put(setLoading(true));
    const response = yield call(getSkuPetzRequest, payload);
    yield put(setSkuPetz(response.data));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os dados do sku');
  }
}

function* updateSkuPetzSaga({ payload }) {
  try {
    yield put(setLoading(true));
    yield call(updateSkuPetzRequest, payload);
    yield put(push('/skus-petz'));
    successAlert('Os dados do sku foram atualizados');
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível salvar as alterações');
  }
}

export function* exportCSVSaga(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(exportCSVSkusPetzRequest, action.payload);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${url.slice(27)}.csv`;
    document.body.appendChild(a);
    a.click();
    yield put(setLoading(false));
  } catch (err) {
    errorAlert(err);
  }
  return yield put(setLoading(false));
}

function* getWarehouseSaleChannelsSaga() {
  try {
    const response = yield call(listWarehouseSaleChannelsRequest);
    yield put(setWarehouseSaleChannels(response.data.data));
  } catch (err) {
    yield put(setLoading(false));
    errorAlert(err, 'Não foi possível carregar os canais de venda');
  }
}

function* skusPetzSaga() {
  yield takeEvery(getSkusPetzList, getSkusPetzListSaga);
  yield takeEvery(createSkuPetz, createSkuPetzSaga);
  yield takeEvery(getSkuPetz, getSkuPetzSaga);
  yield takeEvery(updateSkuPetz, updateSkuPetzSaga);
  yield takeEvery(exportCSV, exportCSVSaga);
  yield takeEvery(getWarehouseSaleChannels, getWarehouseSaleChannelsSaga);
}

export default skusPetzSaga;
