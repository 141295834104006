/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Form,
  Input,
  Icon,
  Select,
  Button,
  Card,
  Modal,
  Tooltip,
  Spin,
  Avatar,
  Breadcrumb,
  Switch,
  Table
} from 'antd';
import PropTypes from 'prop-types';

import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import Searchbar from '@/src/components/Searchbar/Searchbar';
import Tables from '@/src/components/Tables';
import UploadImage from '@/src/components/UploadImage';

import {
  getProductsByCategory,
  getAddProductsToSave,
  getDeleteProducts,
  getCreateAisle,
  setAddProductsToSave,
  setAisles
} from '@/src/store/modules/aisles/slice';
import { getCategories } from '@/src/store/modules/categories/slice';
import { getDepartments } from '@/src/store/modules/departments/slice';

import dragIcon from '@/src/assets/images/drag-n-drop.svg';

import './newAisle.scss';

const { OptGroup, Option } = Select;

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({
  // eslint-disable-next-line react/prop-types
  index,
  // eslint-disable-next-line react/prop-types
  moveRow,
  // eslint-disable-next-line react/prop-types
  className,
  // eslint-disable-next-line react/prop-types
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

const NewAisle = props => {
  const {
    form: { getFieldDecorator, validateFields }
  } = props;

  const [showProductsModal, setShowProductsModal] = useState(false);

  const [editAisleProducts, setEditAisleProducts] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [aisleProducts, setAisleProducts] = useState([]);

  const [selectedAisleProducts, setSelectedAisleProducts] = useState([]);
  const [selectedCategoryProducts, setSelectedCategoryProducts] = useState([]);
  const [
    selectedCategoryProductsDetails,
    setSelectedCategoryProductsDetails
  ] = useState([]);
  const [searchSelected, setSearchSelected] = useState('');

  const [editDrag, setEditDrag] = useState(false);
  const [editDragGreaterThan25, setEditDragGreaterThan25] = useState(false);

  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);

  const typeTableModal = 'newAisleModal';

  const dispatch = useDispatch();

  const {
    aisle,
    products,
    productsCount,
    productsQuery,
    addedProducts,
    isLoading
  } = useSelector(state => state.aisles);

  const { categories } = useSelector(state => state.categories);
  const { departments } = useSelector(state => state.departments);

  const searchSelectRef = useRef();

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchSelectRef}
          placeholder="Nome do produto"
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchSelected(e.target.value || '');
          }}
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={confirm}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          id="aisle-products-clear-filter"
          onClick={clearFilters}
          size="small"
          style={{ width: 90 }}
        >
          Limpar
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <div
        style={{
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          color: filtered ? '#33d6ef' : '#333333'
        }}
      >
        <span style={{ fontSize: 14, padding: '0 8px' }}>
          {filtered ? searchSelected : 'BUSCAR'}
        </span>
        <Icon
          type={filtered ? 'close' : 'search'}
          style={{
            fontSize: filtered ? 16 : 18,
            paddingRight: 8
          }}
          onClick={e => {
            if (filtered) {
              e.stopPropagation();
              const clearBtn = document.getElementById(
                'aisle-products-clear-filter'
              );
              if (clearBtn) clearBtn.click();
            }
          }}
        />
      </div>
    ),
    onFilter: (value, record) =>
      record.attributes.name
        .toString()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchSelectRef.current.select());
      }
    }
  });

  const column = [
    {
      dataIndex: 'attributes.image',
      render: record => (
        <Avatar
          shape="square"
          size={60}
          src={record}
          icon="camera"
          className="img-placeholder"
        />
      ),
      width: 88
    },
    {
      dataIndex: 'attributes.name',
      colSpan: 2,
      ...getColumnSearchProps()
    }
  ];
  if (editDrag) {
    column.push({
      dataIndex: 'sort',
      width: 60,
      align: 'center',
      colSpan: 0,
      render: () => <img src={dragIcon} alt="" style={{ fontSize: 20 }} />
    });
  }

  const disabledProduct = (
    <div className="disabled-hall">
      <Icon style={{ marginLeft: 6 }} type="info-circle" />
      <p style={{ marginBottom: 0, marginLeft: 5 }}>
        Escolha uma categoria para adicionar produtos.
      </p>
    </div>
  );

  const rowSelectionModal = {
    selectedRowKeys: selectedCategoryProducts,
    onChange: (selectedRowKeys, selectedRowObj) => {
      setSelectedCategoryProducts(selectedRowKeys);
      setSelectedCategoryProductsDetails(prev => {
        const newArr = [...prev, ...selectedRowObj];
        const ids = newArr.map(o => o.id);
        const filtered = newArr.filter(
          (item, index) => !ids.includes(item.id, index + 1)
        );
        return filtered;
      });
    },

    getCheckboxProps: record => ({
      disabled: record.attributes.present_on_aisle
    })
  };

  const rowSelectionTable = {
    selectedRowKeys: selectedAisleProducts,
    onChange: selectedRowKeys => setSelectedAisleProducts(selectedRowKeys)
  };

  const saveAllConfirm = value => {
    Modal.confirm({
      title: 'SALVAR ALTERAÇÕES NO APP',
      okText: 'Confirmar',
      centered: true,
      content:
        'Tem certeza que deseja salvar as alterações? Todas as mudanças feitas serão espelhadas no app.',
      onOk() {
        const image = uploadImage?.includes('data:image')
          ? uploadImage.replace(/data:image\/[\w]+;base64,/g, '')
          : '';
        const productsAdded = aisleProducts.map(product => product.id);
        dispatch(
          getCreateAisle({
            ...value,
            search_metadata: value.search_metadata || '',
            title: value.title || '',
            meta_title: value.meta_title || '',
            meta_description: value.meta_description || '',
            products_id: productsAdded,
            image
          })
        );
      },
      onCancel() {}
    });
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!err) {
        saveAllConfirm(values);
      }
    });
  };

  const handleCloseProductsModal = () => {
    setShowProductsModal(false);
    setSelectedCategoryProducts([]);
    setSelectedCategoryProductsDetails([]);
  };

  const handleOkProductsModal = () => {
    const itemsToSave = selectedCategoryProductsDetails.filter(product =>
      selectedCategoryProducts.includes(product.id)
    );
    dispatch(getAddProductsToSave(itemsToSave));
    handleCloseProductsModal();
  };

  const handleCategorySelect = value => {
    setCategoryId(value);
  };

  const handleDeleteAisleProducts = items => {
    const itemsToDelete = addedProducts.filter(tableProducts =>
      items.includes(tableProducts.id)
    );
    dispatch(getDeleteProducts(itemsToDelete));
    setSelectedAisleProducts([]);
    if (addedProducts.length === itemsToDelete.length) {
      setAisleProducts([]);
    }
  };

  const handleGetCategoryProducts = useCallback(
    urlQuery => {
      const category = categoryId || aisle?.attributes?.category_id;
      dispatch(getProductsByCategory({ ...urlQuery, category }));
    },
    [dispatch, categoryId, aisle]
  );

  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  const manager = useRef(RNDContext);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = aisleProducts[dragIndex];
      setAisleProducts(
        update(aisleProducts, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      );
    },
    [aisleProducts]
  );

  useEffect(() => {
    if (addedProducts.length > 1) {
      setEditDrag(!(addedProducts.length > 25));
      setEditDragGreaterThan25(addedProducts.length > 25);
    } else {
      setEditDrag(false);
      setEditDragGreaterThan25(false);
    }
    setAisleProducts(addedProducts);
  }, [addedProducts]);

  useEffect(() => {
    if (selectedAisleProducts.length > 0) {
      setEditAisleProducts(true);
    } else {
      setEditAisleProducts(false);
    }
  }, [selectedAisleProducts]);

  useEffect(() => {
    dispatch(setAddProductsToSave([]));
    dispatch(getDepartments());
    dispatch(
      getCategories({
        query: {
          sort: '',
          page: 1,
          perPage: 200,
          search: ''
        }
      })
    );
    dispatch(
      setAisles({
        aisles: [],
        query: {},
        count: 0
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* <ModalCSV
        titleModal="CONFIGURAR PRODUTOS POR PLANILHA"
        isVisible={showCsvModal}
        setIsVisibleModalCSV={() => setShowCsvModal(false)}
        components={contentModalCSV}
        buttonDisabled={csvFileFail}
        CSVFileHandle={handleImportCsv}
      /> */}
      <Modal
        centered
        title="ADICIONAR PRODUTOS"
        visible={showProductsModal}
        className="user-logTable"
        width={900}
        onOk={() => {}}
        onCancel={() => handleCloseProductsModal()}
        footer={[
          <Button
            key="submit"
            onClick={handleOkProductsModal}
            className="ant-btn ant-btn-primary"
          >
            ADICIONAR
          </Button>
        ]}
      >
        <Searchbar
          placeholder="Buscar produto na categoria selecionada"
          populateResult={handleGetCategoryProducts}
          query={productsQuery}
          value={productsQuery.search}
        />
        <p style={{ marginTop: 20, marginBottom: 4, fontWeight: 'bold' }}>
          {selectedCategoryProducts.length} produtos selecionados
        </p>
        <span>
          *Produtos já adicionados não podem ser selecionados novamente
        </span>
        <Tables
          data={products}
          contentToShow={typeTableModal}
          rowKey={record => record.id}
          rowSelection={rowSelectionModal}
          rowClassName={record =>
            record?.attributes.present_on_aisle && 'disabled'
          }
          isLoading={isLoading}
          hasScroll
          query={productsQuery}
          count={productsCount}
          visiblePaginationDown
          populateTables={handleGetCategoryProducts}
        />
      </Modal>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1 className="mb-20">Novo corredor</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/corredores">Corredores</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Novo corredor</Breadcrumb.Item>
      </Breadcrumb>
      <section
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          marginBottom: 60
        }}
      >
        <Card className="no-shadow" title="DETALHES">
          <Form style={{ marginTop: 10 }}>
            <Row gutter={24}>
              <Col span={12}>
                <Row
                  style={{
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Nome</p>
                    <Tooltip
                      placement="right"
                      title="Nome do corredor que irá aparecer no app"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha o nome do corredor'
                        }
                      ]
                    })(<Input placeholder="Nome do corredor" />)}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Palavras-chave
                    </p>
                    <Tooltip
                      placement="right"
                      title="Palavras que facilitam encontrar produtos pelo cliente através da busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('search_metadata')(
                      <Input.TextArea placeholder="Ex.: novo, corredor" />
                    )}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                      Categoria
                    </p>
                    <Tooltip
                      placement="right"
                      title="Um corredor deve estar atrelado a apenas uma categoria de um departamento"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('category_id')(
                      <Select
                        placeholder="Selecione uma categoria"
                        disabled={aisleProducts.length > 0}
                        onSelect={handleCategorySelect}
                      >
                        {departments.map(item => {
                          const categoriesArr = categories.filter(
                            obj =>
                              obj.attributes.department_id === Number(item.id)
                          );
                          return (
                            <OptGroup
                              label={item.attributes.name}
                              key={`department-${item.id}`}
                            >
                              {categoriesArr?.map(obj => {
                                return (
                                  <Option key={Number(obj.attributes.id)}>
                                    {obj.attributes.name} para{' '}
                                    {item.attributes.name}
                                  </Option>
                                );
                              })}
                            </OptGroup>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  {aisleProducts.length > 0 && (
                    <span style={{ marginBottom: 24 }}>
                      *Caso queira trocar de categoria, todos os produtos
                      adicionados abaixo devem ser excluídos
                    </span>
                  )}
                </Row>
                <Row style={{ width: '80%', marginBottom: 16 }}>
                  <h2>SEO (WEB)</h2>
                </Row>
                <Row
                  style={{
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 0
                      }}
                    >
                      Title
                    </p>
                    <Tooltip
                      placement="right"
                      title="Título da vitrine exibido na página do corredor"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item style={{ marginBottom: 2 }}>
                    {getFieldDecorator('title')(
                      <Input placeholder="Título da vitrine" />
                    )}
                  </Form.Item>
                  <span style={{ marginBottom: 24 }}>
                    *Caso não preenchido, será o nome do corredor
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                      Meta Title
                    </p>
                    <Tooltip
                      placement="right"
                      title="Título da vitrine exibido em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_title')(
                      <Input placeholder="Exemplo: Zee.Now - Nome do corredor" />
                    )}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                      Meta Description
                    </p>
                    <Tooltip
                      placement="right"
                      title="Descrição da vitrine exibida em ferramentas de busca"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <Form.Item>
                    {getFieldDecorator('meta_description')(
                      <Input.TextArea placeholder="Breve descrição do corredor" />
                    )}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                      É subcategoria?
                    </p>
                    <Tooltip
                      placement="right"
                      title="Selecione caso corredor seja uma subcategoria exibida no menu do site"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                    <Form.Item style={{ marginBottom: 0, marginLeft: 16 }}>
                      {getFieldDecorator('subcategory')(
                        <Switch
                          checkedChildren={
                            <Icon style={{ display: 'block' }} type="check" />
                          }
                          unCheckedChildren={
                            <Icon style={{ display: 'block' }} type="close" />
                          }
                          // checked={isSubcategory}
                          // onChange={value => setIsSubcategory(value)}
                          // disabled={!catalogPermission}
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 4
                    }}
                  >
                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                      Não indexar
                    </p>
                    <Tooltip
                      placement="right"
                      title="Selecione caso corredor NÃO precise ser incluído no sitemap"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                    <Form.Item style={{ marginBottom: 0, marginLeft: 16 }}>
                      {getFieldDecorator('no_index')(
                        <Switch
                          // checked={isNoIndex}
                          // onChange={value => setIsNoIndex(value)}
                          // disabled={!catalogPermission}
                          checkedChildren={
                            <Icon style={{ display: 'block' }} type="check" />
                          }
                          unCheckedChildren={
                            <Icon style={{ display: 'block' }} type="close" />
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                </Row>
              </Col>
              <Col span={12}>
                <Row style={{ width: '80%' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 8
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 0
                      }}
                    >
                      Imagem
                    </p>
                    <Tooltip
                      placement="right"
                      title="Imagem exibida no menu do site e em ferramentas de pesquisa"
                    >
                      <Icon style={{ marginLeft: 6 }} type="info-circle" />
                    </Tooltip>
                  </div>
                  <UploadImage
                    setUploadImage={setUploadImage}
                    uploadImage={uploadImage}
                    withoutImage={withoutImage}
                    setWithoutImage={setWithoutImage}
                    recommendedSize={[400, 400]}
                  />
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
        <span style={{ margin: '8px 0', display: 'block' }} />
        <Row>
          <Spin spinning={!categoryId} indicator={disabledProduct}>
            <Card
              className="no-shadow"
              style={{ paddingTop: 16, minHeight: '48px' }}
            >
              <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 16 }}
              >
                <Col>
                  <h2>PRODUTOS</h2>
                  <span>Selecione o(s) produto(s) para o corredor.</span>
                </Col>
                <Col>
                  {/* <Button
                      className="ant-btn-secondary-copy"
                      disabled={csvDisabled}
                      onClick={() => setShowCsvModal(true)}
                      style={{ marginRight: 20 }}
                    >
                      Produtos por planilha
                    </Button> */}
                  <Button
                    className="ant-btn-primary"
                    onClick={() => {
                      setShowProductsModal(true);
                      handleGetCategoryProducts({
                        query: { page: 1, perPage: 20 }
                      });
                    }}
                  >
                    Adicionar produto
                  </Button>
                </Col>
              </Row>
              {editDragGreaterThan25 && (
                <Row>
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: editAisleProducts ? 10 : 0
                    }}
                  >
                    <Icon type="info-circle" />
                    <p
                      style={{
                        marginLeft: 5,
                        fontSize: 16,
                        marginBottom: 0,
                        fontWeight: 'bold'
                      }}
                    >
                      A ordenação dos produtos só é possível com máximo de 25
                      itens. Use a planilha para fazer a ordenação dos produtos
                      acima de 25 itens.
                    </p>
                  </Col>
                </Row>
              )}
              {editAisleProducts && (
                <Row type="flex" justify="start">
                  <Col span={12} className="delete-products">
                    <Col span={11}>
                      <p style={{ marginBottom: 0 }}>
                        {selectedAisleProducts.length} produtos selecionados
                      </p>
                    </Col>
                    <Col span={5}>
                      <Button
                        onClick={() =>
                          handleDeleteAisleProducts(selectedAisleProducts)
                        }
                      >
                        Excluir
                      </Button>
                    </Col>
                  </Col>
                </Row>
              )}
              <DndProvider manager={manager.current.dragDropManager}>
                <Table
                  style={{ marginTop: 24 }}
                  columns={column}
                  rowKey={record => record.id}
                  dataSource={aisleProducts}
                  pagination={false}
                  className="ant-table-select-drag"
                  rowSelection={rowSelectionTable}
                  components={editDrag ? components : {}}
                  isLoading={isLoading}
                  onRow={
                    editDrag
                      ? (record, index) => ({
                          index,
                          moveRow
                        })
                      : null
                  }
                />
              </DndProvider>
            </Card>
          </Spin>
        </Row>
      </section>
      <footer className="footer-form-save">
        <Button
          className="ant-btn ant-btn-primary"
          onClick={onSubmit}
          style={{ left: '90%' }}
          disabled={addedProducts <= 0}
        >
          Salvar
        </Button>
      </footer>
    </>
  );
};

NewAisle.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const NewAisleForm = Form.create({ name: 'NewAisle' })(NewAisle);

export default NewAisleForm;
