import React, { useEffect, useCallback } from 'react';
import { Row, Col, notification, Alert, Breadcrumb, Card } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ZEEDOG_STORE } from '@/src/globals/constants';

import Details from '@/src/pages/Customers/Customer/components/Details/index';
import Access from '@/src/pages/Customers/Customer/components/Access/index';
import Pets from '@/src/pages/Customers/Customer/components/Pets/index';
import LastOrders from '@/src/pages/Customers/Customer/components/LastOrders/index';
import Subscriptions from '@/src/pages/Customers/Customer/components/Subscriptions/index';
import Wallet from '@/src/pages/Customers/Customer/components/Wallet/index';
import CreditCards from '@/src/pages/Customers/Customer/components/CreditCards/index';
import Addresses from '@/src/pages/Customers/Customer/components/Addresses/index';
import CustomersTags from '@/src/pages/Customers/Customer/components/Tags/index';
// import ZeeManAlert from '@/src/pages/Customers/Customer/components/ZeeManAlert/index';

import { callCustomer } from '@/src/api/customers';

import {
  getLastOrders,
  getSubscriptions,
  setPagination,
  setSubscriptionPagination,
  setSort,
  setSubscriptionSort
} from '@/src/store/modules/orders/slice';

import {
  addToWallet,
  removeFromWallet
} from '@/src/store/modules/wallet/slice';

import {
  getCustomer,
  getCustomerAddresses,
  getCustomerWalletTransactions,
  getEditCustomers,
  logoutCustomer,
  deactivateCustomer,
  activateCustomer,
  banishReferralCustomer,
  activateCustomerOnReferral,
  resetCustomerPassword
} from '@/src/store/modules/customers/slice';

import { addTag, removeTag } from '@/src/store/modules/tags/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

const makeCall = payload => {
  callCustomer(payload)
    .then(res => {
      if (res.status === 204) {
        notification.success({
          message: 'Ligação feita com sucesso!',
          description: 'Continue a ligação via Atende Simples'
        });
      }
    })
    .catch(err => {
      throw new Error(err);
    });
};

const Customer = () => {
  const dispatch = useDispatch();

  const { editPermission } = usePermissions();
  const customersPermission = editPermission(modules.CUSTOMERS_MANAGE);

  const { id } = useParams();

  const {
    isLoading,
    customer,
    creditCard,
    address,
    pets,
    metadata
  } = useSelector(state => state.customers);

  const { wallet } = useSelector(state => state.wallet);

  const {
    lastOrdersCustomer,
    query,
    count,
    subscriptionsCount,
    subscriptionsCustomer,
    subscriptionsQuery
  } = useSelector(state => state.orders);

  const isLoadingOrders = useSelector(state => state.orders.isLoading);

  const tags = useSelector(state => state.tags.tags);

  const addToWalletHandler = (walletId, amount) =>
    dispatch(addToWallet({ id: walletId, amount }));

  const removeFromWalletHandler = (walletId, amount) =>
    dispatch(removeFromWallet({ id: walletId, amount }));

  const editCustomerHanlder = value => {
    dispatch(getEditCustomers({ value, id }));
  };

  const banishReferralCustomerHanlder = () =>
    dispatch(banishReferralCustomer({ id }));

  const activateCustomerOnReferralHanlder = () =>
    dispatch(activateCustomerOnReferral({ id }));

  const logoutCustomerHandler = () => dispatch(logoutCustomer({ id }));
  const deactivateCustomerHandler = () => dispatch(deactivateCustomer({ id }));
  const activateCustomerHandler = () => dispatch(activateCustomer({ id }));

  const addTagHandler = name =>
    dispatch(
      addTag({
        taggable_type: 'Customer',
        taggable_id: id,
        name
      })
    );

  const removeTagHandler = name =>
    dispatch(
      removeTag({
        taggable_type: 'Customer',
        taggable_id: id,
        name
      })
    );

  const resetPasswordCustomerHanlder = () =>
    dispatch(resetCustomerPassword({ user_id: id }));

  const getLastOrdersHandler = useCallback(
    urlQuery => {
      dispatch(getLastOrders({ ...urlQuery }));
    },
    [dispatch]
  );

  const getSubscriptionsHandler = useCallback(
    urlQuery => {
      dispatch(getSubscriptions({ ...urlQuery }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getCustomer(id));
    dispatch(getCustomerAddresses(id));
    dispatch(
      getCustomerWalletTransactions({
        id,
        query: {
          perPage: 5,
          page: 1
        }
      })
    );
    const updatedQuery = {
      page: 1,
      perPage: 10,
      daysAgo: 'all',
      filters: {
        by_customer: id
      }
    };
    getLastOrdersHandler({ query: updatedQuery });
    if (ZEEDOG_STORE) getSubscriptionsHandler({ query: updatedQuery });
    // eslint-disable-next-line
  }, [dispatch, id]);

  const setPaginationHandler = useCallback(
    payload => {
      dispatch(setPagination({ ...payload }));
    },
    [dispatch]
  );
  const setSubsPaginationHandler = useCallback(
    payload => {
      dispatch(setSubscriptionPagination({ ...payload }));
    },
    [dispatch]
  );
  const setSortHandler = useCallback(
    payload => {
      dispatch(setSort({ ...payload }));
    },
    [dispatch]
  );
  const setSubsSortHandler = useCallback(
    payload => {
      dispatch(setSubscriptionSort({ ...payload }));
    },
    [dispatch]
  );

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Editar Cliente</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/clientes">Clientes</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar Cliente</Breadcrumb.Item>
      </Breadcrumb>
      <section
        style={{
          background: '#ebebeb',
          padding: '10px 8px',
          borderRadius: '8px'
        }}
      >
        <section>
          <Row gutter={8}>
            {customer?.attributes?.banned && !isLoading && (
              <Alert
                style={{ margin: '0 4px 10px' }}
                message="Cliente banido"
                type="error"
                showIcon
              />
            )}
            {!customer?.attributes?.banned &&
              customer?.attributes?.banned_from_referral &&
              !isLoading && (
                <Alert
                  style={{ margin: '0 4px 10px' }}
                  message="Cliente banido de indicações"
                  type="error"
                  showIcon
                />
              )}
            {customer?.attributes?.email.startsWith('disabled-') &&
              customer?.attributes?.document === '00000000000' &&
              !customer?.attributes?.phone_number &&
              !isLoading && (
                <Alert
                  style={{ margin: '0 4px 10px' }}
                  message="Cliente com cadastro excluído"
                  type="error"
                  showIcon
                />
              )}
            {!customer?.attributes?.wallet_flag && wallet?.id && !isLoading && (
              <Alert
                style={{ margin: '0 4px 10px' }}
                message="Cliente com carteira desativada"
                type="warning"
                showIcon
              />
            )}
            <Col span={8}>
              <Details
                makeCall={makeCall}
                customer={customer}
                id={id}
                loading={isLoading}
                editCustomer={editCustomerHanlder}
                banishReferral={banishReferralCustomerHanlder}
                activateCustomerOnReferral={activateCustomerOnReferralHanlder}
                deactivateCustomer={deactivateCustomerHandler}
                activateCustomer={activateCustomerHandler}
                resetCustomerPassword={resetPasswordCustomerHanlder}
                logoutCustomer={logoutCustomerHandler}
                // editPermission={customersPermission}
              />
              <span style={{ margin: '8px 0', display: 'block' }} />
              <Card className="no-shadow" title={<h2>Hub</h2>}>
                <p>{customer?.attributes?.hub || 'Não há hub associado'}</p>
              </Card>
              <span style={{ margin: '8px 0', display: 'block' }} />
              <Pets pets={pets} />
              <span style={{ margin: '8px 0', display: 'block' }} />
              <CustomersTags
                addTags={addTagHandler}
                removeTags={removeTagHandler}
                tags={tags}
                editPermission={customersPermission}
              />
              {/* <span style={{ margin: '8px 0', display: 'block' }} />
            <ZeeManAlert /> */}
            </Col>
            <Col span={16}>
              <Access metadata={metadata} />
              <span style={{ margin: '8px 0', display: 'block' }} />
              <Wallet
                addToWallet={addToWalletHandler}
                removeFromWallet={removeFromWalletHandler}
                customer={customer}
                // editPermission={customersPermission}
              />
              <span style={{ margin: '8px 0', display: 'block' }} />
              <LastOrders
                lastOrdersCustomer={lastOrdersCustomer}
                query={query}
                count={count}
                isLoading={isLoadingOrders}
                setSortHandler={setSortHandler}
                setPaginationHandler={setPaginationHandler}
                getLastOrdersHandler={getLastOrdersHandler}
              />
              <span style={{ margin: '8px 0', display: 'block' }} />
              {ZEEDOG_STORE && (
                <>
                  <Subscriptions
                    subscriptionsCustomer={subscriptionsCustomer}
                    query={subscriptionsQuery}
                    count={subscriptionsCount}
                    isLoading={isLoadingOrders}
                    setSortHandler={setSubsSortHandler}
                    setPaginationHandler={setSubsPaginationHandler}
                    getSubscriptionsHandler={getSubscriptionsHandler}
                  />
                  <span style={{ margin: '8px 0', display: 'block' }} />
                </>
              )}
              <Addresses
                addresses={address}
                editPermission={customersPermission}
              />
              <span style={{ margin: '8px 0', display: 'block' }} />
              <CreditCards
                creditCard={creditCard}
                editPermission={customersPermission}
              />
            </Col>
          </Row>
        </section>
      </section>
    </>
  );
};

export default Customer;
