import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  skusPetz: [],
  skuPetz: {},
  isLoading: false,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    url: '',
    filters: {}
  },
  count: 0,
  salechannels: []
};

const skusPetzSlice = createSlice({
  name: '@skusPetz',
  initialState,
  reducers: {
    setSkusPetzList(prevState, action) {
      const state = prevState;
      state.skusPetz = action.payload.data;
      state.count = action.payload.count;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        search: action.payload.query.search || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}`
      };
      return state;
    },
    setSkuPetz(prevState, action) {
      const state = prevState;
      state.skuPetz = action.payload;
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    setWarehouseSaleChannels(prevState, action) {
      const state = prevState;
      state.salechannels = action.payload;
      return state;
    },
    getSkusPetzList(state) {
      return state;
    },
    exportCSV(state) {
      return state;
    },
    getSkuPetz(state) {
      return state;
    },
    createSkuPetz(state) {
      return state;
    },
    updateSkuPetz(state) {
      return state;
    },
    getWarehouseSaleChannels(state) {
      return state;
    }
  }
});

export const {
  setSkusPetzList,
  setSkuPetz,
  setLoading,
  getSkusPetzList,
  getSkuPetz,
  createSkuPetz,
  exportCSV,
  updateSkuPetz,
  setWarehouseSaleChannels,
  getWarehouseSaleChannels
} = skusPetzSlice.actions;

export default skusPetzSlice.reducer;
