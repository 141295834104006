import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Collapse, Row, Checkbox, Col, Icon, Select, Spin } from 'antd';

import { getAttributes } from '@/src/store/modules/attributes/slice';

const { Panel } = Collapse;
const { Option } = Select;

const ProductsFilters = ({ productsQueryFilter, setProductsFilters }) => {
  const dispatch = useDispatch();

  const { attributes, isLoading } = useSelector(state => state.attributes);

  const [checkboxValues, setCheckboxValues] = useState([]);

  const populateAttributes = () =>
    dispatch(
      getAttributes({
        query: {
          sort: '',
          page: 1,
          perPage: 200,
          search: ''
        }
      })
    );

  useEffect(() => {
    const valuesArr = [];
    if (productsQueryFilter?.filters?.by_available_quantity) {
      valuesArr.push('available');
    }
    if (productsQueryFilter?.filters?.by_stock_quantity) {
      valuesArr.push('total');
    }
    if (productsQueryFilter?.filters?.by_reserved_quantity) {
      valuesArr.push('reserved');
    }
    setCheckboxValues(valuesArr);
  }, [productsQueryFilter]);

  const handleStockFilter = value => {
    const filterStock = {
      filters: {}
    };

    value.forEach(item => {
      if (item === 'available')
        filterStock.filters.by_available_quantity = true;
      if (item === 'total') filterStock.filters.by_stock_quantity = true;
      if (item === 'reserved') filterStock.filters.by_reserved_quantity = true;
    });

    const prevFilters = {
      ...productsQueryFilter.filters
    };

    if (prevFilters?.by_available_quantity)
      delete prevFilters.by_available_quantity;
    if (prevFilters?.by_stock_quantity) delete prevFilters.by_stock_quantity;
    if (prevFilters?.by_reserved_quantity)
      delete prevFilters.by_reserved_quantity;

    setProductsFilters(prev => {
      return {
        ...prev,
        filters: {
          ...prevFilters,
          ...filterStock.filters
        }
      };
    });
  };

  const handleFilterSelect = (value, type) => {
    switch (type) {
      case 'ATTRIBUTES':
        setProductsFilters({
          ...productsQueryFilter,
          filters: {
            ...productsQueryFilter.filters,
            by_property: value.length
              ? value.map(property => property.replace(/\s/g, '_'))
              : []
          }
        });
        break;
      case 'STOCK':
        handleStockFilter(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={() => <Icon type="caret-down" />}
        expandIconPosition="right"
      >
        <Panel header="Range de Estoque" key="0">
          <Checkbox.Group
            style={{ width: '100%' }}
            defaultValue={checkboxValues}
            value={checkboxValues}
            onChange={evt => {
              setCheckboxValues(evt);
              handleFilterSelect(evt, 'STOCK');
            }}
          >
            <Row>
              <Col span={12}>
                <Checkbox value="available">Disponíveis</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="total">Totais</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Checkbox value="reserved">Reservados</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Panel>
        <Panel header="Atributos" key="1">
          <Row>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Selecione os atributos"
              defaultValue={productsQueryFilter?.filters?.by_property}
              value={productsQueryFilter?.filters?.by_property}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={evt => handleFilterSelect(evt, 'ATTRIBUTES')}
              onFocus={populateAttributes}
              notFoundContent={isLoading ? <Spin size="small" /> : null}
            >
              {[...attributes]
                .sort((a, b) =>
                  a.attributes.name.localeCompare(b.attributes.name)
                )
                .map(attribute => {
                  return (
                    <Option key={attribute.attributes.id}>
                      {attribute.attributes.name}
                    </Option>
                  );
                })}
            </Select>
          </Row>
        </Panel>
        {/* <Panel header="Tag de Imagens" key="2">
            TAG IMAGENS
        </Panel>
        <Panel header="Sugestão de raça" key="3">
        </Panel> */}
      </Collapse>
    </>
  );
};

ProductsFilters.defaultProps = {
  productsQueryFilter: {},
  setProductsFilters: () => {}
};

ProductsFilters.propTypes = {
  productsQueryFilter: PropTypes.oneOfType([PropTypes.object]),
  setProductsFilters: PropTypes.func
};

export default React.memo(ProductsFilters);
