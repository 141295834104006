import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Select, Button, Spin, Form } from 'antd';
import PropTypes from 'prop-types';

import { handleSelectSearch } from '@/src/utils/searchUtils';

const { Option } = Select;

function sortByName(array) {
  const unsorted = [...array];
  const sorted = unsorted.sort((a, b) => {
    const nameA = a.attributes.name.toLowerCase();
    const nameB = b.attributes.name.toLowerCase();
    return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' });
  });
  return sorted;
}

const Attributes = ({
  settedAttributes,
  attributesList,
  populateProperties,
  loading,
  form,
  editPermission
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const [descriptionValuesToShow, setDescriptionValuesToShow] = useState([]);
  const [sortedAttributes, setSortedAttributes] = useState([]);
  const [initialValue, setInitialValue] = useState([]);
  const inputValues = getFieldValue('attr');

  useEffect(() => {
    const sorted = sortByName(attributesList);
    setSortedAttributes(sorted);
  }, [attributesList]);

  const findAttributes = useCallback(
    idAttr => {
      const attributesDrescription = sortedAttributes
        .map(attribute => attribute)
        .find(idValue => idValue.id === idAttr);
      if (attributesDrescription !== undefined) {
        const existAttributes = descriptionValuesToShow.find(
          attr => attr.id === attributesDrescription.id
        );
        if (!existAttributes?.id) {
          setDescriptionValuesToShow(oldValue => [
            ...oldValue,
            attributesDrescription
          ]);
        }
      }
    },
    [descriptionValuesToShow, sortedAttributes]
  );

  const handleChangeAttributes = useCallback(
    values => {
      values.forEach(findAttributes);
      populateProperties(values);
    },
    [findAttributes, populateProperties]
  );

  const deleteDrescription = id => {
    const attributesDrescription = sortedAttributes
      .map(attribute => attribute)
      .find(idValue => idValue.id === id);
    const existDrescription = descriptionValuesToShow.find(
      attr => attr.id === attributesDrescription.id
    );
    if (existDrescription.id) {
      const newDrescriptions = descriptionValuesToShow.filter(
        drescription => drescription.id !== id
      );
      setDescriptionValuesToShow(newDrescriptions);
    }
  };

  const deleteAttributesOnSelect = value => {
    const hasAttributes = inputValues.includes(value);
    if (hasAttributes) {
      const attributesChange = inputValues.filter(
        attribute => attribute !== value
      );
      setFieldsValue({
        attr: attributesChange
      });
      populateProperties(attributesChange);
      deleteDrescription(value);
    }
  };

  const deleteAttributesAndDrescription = value => {
    deleteDrescription(value.id);
    deleteAttributesOnSelect(value.id);
  };

  const checkDescription = useCallback(() => {
    const initialDescriptionToShow = inputValues
      ?.map(settedId => {
        return sortedAttributes?.find(item => item.id === settedId);
      })
      .filter(item => item !== undefined);
    setDescriptionValuesToShow(initialDescriptionToShow);
    return () => {
      setDescriptionValuesToShow([]);
    };
  }, [inputValues, sortedAttributes]);

  useEffect(() => {
    checkDescription();
  }, [sortedAttributes, checkDescription]);

  const checkValues = useCallback(() => {
    let newValues = sortedAttributes
      ?.map(attr => {
        return initialValue?.find(item => item === attr.id);
      })
      .filter(item => item !== undefined);
    setFieldsValue({
      attr: newValues
    });
    const propertiesValues = newValues
      ?.map(itemId => {
        return sortedAttributes?.find(item => item.id === itemId);
      })
      .filter(item => item !== undefined)
      .map(item => item.id);
    populateProperties(propertiesValues);
    return () => {
      newValues = [];
    };
    // eslint-disable-next-line
  }, [sortedAttributes]);

  useEffect(() => {
    checkValues();
  }, [sortedAttributes, checkValues]);

  useEffect(() => {
    setInitialValue(settedAttributes);
  }, [settedAttributes]);

  const printAttributeAllowedValues = attrib => {
    return attrib?.attributes?.allowed_values?.map(att => att.value).join(', ');
  };

  // const handleSelectSearch = (input, option) => {
  //   const parsedOpt = option.props.children
  //     .toLowerCase()
  //     .normalize('NFD')
  //     .replace(/[\u0300-\u036f]/g, '');
  //   return parsedOpt.includes(input.toLowerCase());
  // };

  return (
    <Spin spinning={loading}>
      <Form.Item className="attributes-form">
        {getFieldDecorator('attr', {
          initialValue
        })(
          <Select
            style={{
              marginTop: 10,
              marginBottom: 10,
              width: '100%'
            }}
            placeholder="Buscar pelo nome"
            mode="multiple"
            onChange={value => handleChangeAttributes(value)}
            onDeselect={value => deleteAttributesOnSelect(value)}
            disabled={!editPermission}
            filterOption={handleSelectSearch}
          >
            {sortedAttributes?.map(values => (
              <Option key={values.attributes.id}>
                {values.attributes.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Row>
        {descriptionValuesToShow?.length > 0 &&
          descriptionValuesToShow !== undefined &&
          descriptionValuesToShow?.map(attributesValue => (
            <Col
              style={{
                backgroundColor: '#EBEBEB',
                padding: 15,
                marginBottom: 15
              }}
              key={attributesValue?.id}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                  {attributesValue?.attributes?.name}
                </p>
                {editPermission && (
                  <Button
                    className="ant-btn-link order-timeline"
                    style={{ fontWeight: 'bold' }}
                    onClick={() =>
                      deleteAttributesAndDrescription(attributesValue)
                    }
                  >
                    X
                  </Button>
                )}
              </div>
              <p style={{ marginBottom: 0 }}>
                {printAttributeAllowedValues(attributesValue)}
              </p>
            </Col>
          ))}
      </Row>
    </Spin>
  );
};

Attributes.defaultProps = {
  settedAttributes: [],
  attributesList: [],
  populateProperties: () => {},
  loading: false,
  editPermission: true
};

Attributes.propTypes = {
  settedAttributes: PropTypes.arrayOf(PropTypes.string),
  attributesList: PropTypes.arrayOf(PropTypes.shape({})),
  populateProperties: PropTypes.func,
  loading: PropTypes.bool,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired,
  editPermission: PropTypes.bool
};

const AttributesForm = Form.create({ name: 'EditShipping' })(Attributes);

export default React.memo(AttributesForm);
