import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { Button, Icon } from 'antd';

const ImagePreview = ({
  image,
  index,
  moveImage,
  editPermission,
  type,
  handleImageError,
  handleDeleteImage,
  setModalImage
}) => {
  const baseKey = image.id || image.uid || image.filename;

  const ref = useRef();

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-right' : ' drop-over-left'
      };
    },
    drop: item => {
      moveImage(item.index, index);
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  useEffect(() => {
    if (editPermission) drop(drag(ref));
    // eslint-disable-next-line
  }, [image]);

  const div = document.createElement('div');
  preview(div);

  return (
    <div
      key={baseKey}
      ref={ref}
      className={`multiple-preview-image ${isOver ? dropClassName : ''}`}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: editPermission ? 'move' : 'default'
      }}
    >
      <img src={image.url} alt={type} onError={handleImageError} />
      <div className="multiple-preview-image-buttons">
        <Button type="link" onClick={() => setModalImage(image)}>
          <Icon type="info-circle" />
        </Button>
        <Button type="link" onClick={() => handleDeleteImage(image)}>
          <Icon type="delete" />
        </Button>
      </div>
    </div>
  );
};

ImagePreview.propTypes = {
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  moveImage: PropTypes.func.isRequired,
  editPermission: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handleImageError: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  setModalImage: PropTypes.func.isRequired
};

export default ImagePreview;
