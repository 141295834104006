import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { errorAlert, successAlert } from '@/src/utils/notifications';

import {
  getRecommendations as getRecommendationsRequest,
  createRecommendation,
  getRecommendation as getRecommendationRequest,
  updateRecommendation,
  updateRecommendationSort
} from '@/src/api/recommendations';

import { getProducts as getProductsRequest } from '@/src/api/products';

import {
  getRecommendationsQueryUrlState,
  getRouterState
} from '@/src/store/selectors';

import {
  setRecommendations,
  getRecommendations,
  getRecommendation,
  setIsLoading,
  getCreateRecommendation,
  getUpdateRecommendation,
  setRecommendation,
  getSpotlightSelect,
  setSpotlightSelect,
  setSpotlightLoading
} from '@/src/store/modules/recommendations/slice';

function* getRecommendationsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));

    const updatedPayload = payload;

    if (payload.query.sort === '') {
      updatedPayload.query.sort = '[id]=asc';
    }

    const response = yield call(getRecommendationsRequest, updatedPayload);

    const { data } = response;
    const total = data.meta.total > 0 ? data.meta.total : 0;
    yield put(
      setRecommendations({
        recommendations: data.recommendations,
        count: total,
        query: updatedPayload.query
      })
    );

    const queryUrl = yield select(getRecommendationsQueryUrlState);
    const routerState = yield select(getRouterState);

    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }

    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getRecommendationSaga({ payload }) {
  try {
    yield put(setIsLoading(true));

    const response = yield call(getRecommendationRequest, payload);
    const { data } = response;

    yield put(setRecommendation(data));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getCreateRecommendationSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(createRecommendation, payload);
    yield put(push('/recomendacoes'));
    successAlert('Nova recomendação criada');
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getUpdateRecommendationSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield call(updateRecommendation, payload.updateData);
    yield call(updateRecommendationSort, payload.updateSort);
    yield put(push('/recomendacoes'));
    successAlert('Os dados da Recomendação foram atualizados');
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getSpotlightSelectSaga({ payload }) {
  try {
    yield put(setSpotlightLoading(true));
    const response = yield call(getProductsRequest, payload);
    yield put(setSpotlightSelect(response.data.data));
    yield put(setSpotlightLoading(false));
  } catch (err) {
    // console.log(err);
    yield put(setSpotlightLoading(false));
    errorAlert(err);
  }
}

function* recommendationsSaga() {
  yield takeEvery(getRecommendations, getRecommendationsSaga);
  yield takeEvery(getRecommendation, getRecommendationSaga);
  yield takeEvery(getCreateRecommendation, getCreateRecommendationSaga);
  yield takeEvery(getUpdateRecommendation, getUpdateRecommendationSaga);
  yield takeEvery(getSpotlightSelect, getSpotlightSelectSaga);
}

export default recommendationsSaga;
