import React from 'react';
import { Row, Col, Card } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import RecoverPasswordForm from '@/src/pages/ForgotPassword/Form/RecoverPasswordForm';
import ResetPasswordForm from '@/src/pages/ForgotPassword/Form/ResetPasswordForm';
import zeenowLoginBg from '@/src/assets/images/login-bg-zeenow.jpg';
import zeedogLoginBg from '@/src/assets/images/skull.jpg';
import logoZeeNow from '@/src/assets/images/logo-zn-positivo.png';
import logoZeeDog from '@/src/assets/images/logo-zeedog.svg';

import { ZEENOW_STORE } from '@/src/globals/constants';

export const ForgotPasswordPage = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');

  const { accountEmail } = useSelector(state => state.forgotPassword);

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{
        height: '100vh',
        background: `linear-gradient(
        ${
          ZEENOW_STORE
            ? 'rgba(51, 214, 239, 0.9),rgba(51, 214, 239, 0.9)'
            : 'rgba(125, 125, 125, 0.9),rgba(125, 125, 125, 0.9)'
        }),
        url(${ZEENOW_STORE ? zeenowLoginBg : zeedogLoginBg})`,
        backgroundSize: 'cover'
      }}
    >
      <Col xs={20} md={10} lg={8}>
        <Card
          cover={
            <>
              <img
                alt={ZEENOW_STORE ? 'Logo Zee.Now' : 'Logo Zee.Dog'}
                src={ZEENOW_STORE ? logoZeeNow : logoZeeDog}
                style={{
                  width: ZEENOW_STORE ? '116px' : '100px',
                  margin: '24px auto 12px'
                }}
              />
              {(!accountEmail && !token) || token ? (
                <p
                  style={{
                    width: '80%',
                    margin: '24px auto 19px',
                    textAlign: 'center'
                  }}
                >
                  {token
                    ? 'Digite sua nova senha. Ela deve conter no mínimo 8 caracteres, com pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.'
                    : 'Digite seu e-email para receber um link de alteração de senha.'}
                </p>
              ) : null}
            </>
          }
        >
          {!accountEmail && !token && <RecoverPasswordForm />}
          {accountEmail && !token && (
            <>
              <p
                style={{
                  width: '80%',
                  margin: '24px auto',
                  textAlign: 'center'
                }}
              >
                Link de alteração de senha enviado para{' '}
                <span style={{ whiteSpace: 'nowrap' }}>{accountEmail}</span>
              </p>
              <Row type="flex" justify="center">
                <Link to="/">Voltar para Login</Link>
              </Row>
            </>
          )}
          {token && <ResetPasswordForm token={token} />}
        </Card>
      </Col>
    </Row>
  );
};

export default ForgotPasswordPage;
