import { createSlice } from '@reduxjs/toolkit';
import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  subscriptions: [],
  subscription: {},
  subscriptionAddress: {},
  subscriptionCreditCard: {},
  subscriptionCustomer: {},
  subscriptionItems: [],
  cycles: [],
  cyclesCount: 0,
  isLoading: false,
  isCycleLoading: false,
  count: 0,
  // total: 0,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    daysAgo: 'all',
    filters: {}
  },
  cyclesQuery: {
    page: 1,
    perPage: 10
  },
  selectedFilters: false
};

const subscriptionsSlice = createSlice({
  name: '@subscriptions',
  initialState,
  reducers: {
    setSubscriptions(prevState, action) {
      const state = prevState;
      state.subscriptions = action.payload.subscriptions;

      state.count = action.payload.count;
      // state.total = action.payload.total;

      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        daysAgo: action.payload.query.daysAgo || state.query.daysAgo,
        search: action.payload.query.search || '',
        sort: action.payload.query.sort || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}&days_ago=${action
          .payload.query.daysAgo || state.query.daysAgo}${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }${
          action.payload.query.sort !== ''
            ? `&sort${action.payload.query.sort}`
            : ''
        }${
          action.payload.query.filters
            ? buildQueryStringFromObj(action.payload.query.filters)
            : ''
        }`
      };

      state.isLoading = false;

      return state;
    },
    setSubscription(prevState, action) {
      const state = prevState;
      state.subscription = action.payload.attributes;
      state.subscriptionAddress = action.payload?.address || {};
      state.subscriptionCreditCard =
        action.payload?.credit_card?.attributes || {};
      state.subscriptionCustomer =
        {
          ...action.payload?.customer.attributes,
          id: action.payload?.customer.id
        } || {};
      state.subscriptionItems = action.payload?.subscription_item || [];

      return state;
    },
    setCycles(prevState, action) {
      const state = prevState;
      state.cycles = action.payload.cycles;
      state.cyclesCount = action.payload.count;
      state.cyclesQuery = {
        page: action.payload.query?.page
          ? Number(action.payload.query.page)
          : state.cyclesQuery.page,
        perPage: action.payload.query?.perPage || state.cyclesQuery.perPage
      };
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setIsCycleLoading(prevState, action) {
      const state = prevState;
      state.isCycleLoading = action.payload;
      return state;
    },
    setSelectedFilters(prevState, action) {
      const state = prevState;
      state.selectedFilters = action.payload;
      return state;
    },
    getIsLoading(state) {
      return state;
    },
    getIsCycleLoading(state) {
      return state;
    },
    getSubscriptions(state) {
      return state;
    },
    getCycles(state) {
      return state;
    },
    getSubscription(state) {
      return state;
    },
    exportCSV(state) {
      return state;
    },
    pauseSubscriptionItem(state) {
      return state;
    },
    unpauseAllSubscriptionItems(state) {
      return state;
    },
    pauseAllSubscriptionItems(state) {
      return state;
    },
    requestCancelSubscription(state) {
      return state;
    },
    cancelSubscription(state) {
      return state;
    },
    retryCycleBilling(state) {
      return state;
    },
    editSubscription(state) {
      return state;
    }
  }
});

export const {
  setSubscriptions,
  setIsLoading,
  setSubscription,
  setSelectedFilters,
  setCycles,
  setIsCycleLoading,
  getSubscription,
  getSubscriptions,
  getIsLoading,
  getCycles,
  exportCSV,
  pauseSubscriptionItem,
  unpauseAllSubscriptionItems,
  pauseAllSubscriptionItems,
  requestCancelSubscription,
  cancelSubscription,
  retryCycleBilling,
  editSubscription
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
