/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Table,
  Icon,
  Form,
  Modal,
  Breadcrumb,
  Button,
  Card,
  Select,
  Switch,
  Menu,
  Dropdown,
  Spin,
  Avatar,
  Input
} from 'antd';

import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { debounce } from 'throttle-debounce';

import Searchbar from '@/src/components/Searchbar/Searchbar';
import Audit from '@/src/components/Audit/index';
import Tables from '@/src/components/Tables';

import { getProducts } from '@/src/store/modules/products/slice';
import {
  getCreateRecommendation,
  getUpdateRecommendation,
  getRecommendation,
  getSpotlightSelect,
  setSpotlightSelect,
  setSpotlightLoading,
  setRecommendations
} from '@/src/store/modules/recommendations/slice';

import dragIcon from '@/src/assets/images/drag-n-drop.svg';
import style from '../../Recom.module.scss';

export const RecommendationPage = () => {
  const dispatch = useDispatch();

  const RNDContext = createDndContext(HTML5Backend);

  const { id } = useParams();
  const { Option } = Select;

  const newRecommendation = !id;

  const [showAudit, setShowAudit] = useState(false);

  const [isSectionEnabled, setIsSectionEnabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // form
  const [selectedRecomType, setSelectedRecomType] = useState('');
  const [selectedSLProduct, setSelectedSLProduct] = useState('');
  const [recomStatus, setRecomStatus] = useState(false);

  // products
  const [productsToAdd, setProductsToAdd] = useState([]);
  const [productsToRemove, setProductsToRemove] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [modalProducts, setModalProducts] = useState([]);
  const [searchSelected, setSearchSelected] = useState('');

  const {
    recommendation,
    spotlightSelect,
    spotlightLoading,
    isLoading
  } = useSelector(state => state.recommendations);
  const {
    products: productsList,
    isLoading: isLoadingProducts,
    query,
    count
  } = useSelector(state => state.products);

  const handleGetModalProducts = useCallback(
    urlQuery => {
      dispatch(getProducts(urlQuery));
    },
    [dispatch]
  );

  // Handle data
  const cleanStates = () => {
    setSelectedRecomType('');
    setSelectedSLProduct('');
    setRecomStatus(false);
  };

  const handleSpotlightSelect = useRef(
    debounce(500, value => {
      const spotlightQuery = {
        query: {
          filters: {},
          page: 1,
          perPage: 50,
          search: value,
          sort: '',
          url: ''
        }
      };
      dispatch(getSpotlightSelect(spotlightQuery));
    })
  ).current;

  useEffect(() => {
    if (!newRecommendation) {
      dispatch(getRecommendation(Number(id)));
    } else {
      cleanStates();
    }
    dispatch(
      setRecommendations({
        recommendations: [],
        query: {},
        count: 0
      })
    );
    dispatch(setSpotlightSelect([]));
    // eslint-disable-next-line
  }, []);

  // Handle products lists
  useEffect(() => {
    const products = productsList.map(item => item.attributes);
    // console.log(products);
    setModalProducts(products);
  }, [productsList]);

  useEffect(() => {
    console.log(modalProducts);
  }, [modalProducts]);

  // Get recommendation by id
  useEffect(() => {
    if (
      !newRecommendation &&
      recommendation !== undefined &&
      recommendation?.spotlight_product !== undefined
    ) {
      // form
      setSelectedRecomType(recommendation.group_id);
      setSelectedSLProduct(recommendation.spotlight_product);
      setRecomStatus(recommendation.status);

      const productsIds = [];
      recommendation.products.forEach(product => {
        productsIds.push(product.id);
      });
      setProductsToAdd(productsIds);
      setRecommendedProducts(recommendation.products);
      setProductsData(recommendation.products);
    }
  }, [recommendation, newRecommendation]);

  // Handle "recommended products" section visibility
  useEffect(() => {
    if (selectedRecomType && selectedSLProduct) {
      setIsSectionEnabled(true);
      dispatch(getProducts({ query }));
    }
    // eslint-disable-next-line
  }, [selectedSLProduct, selectedRecomType]);

  // Handle save button
  const handleSaveButton = () => {
    const products = recommendedProducts.map(item => Number(item.id));
    let payload = {
      type: Number(selectedRecomType.key) || selectedRecomType,
      spotlight_product: Number(selectedSLProduct.id),
      active: recomStatus ? 1 : 0,
      recommendations: products
    };

    if (newRecommendation) {
      dispatch(getCreateRecommendation(payload));
    } else {
      payload = {
        updateData: { ...payload, id: Number(id) },
        updateSort: { id: Number(id), products: recommendedProducts }
      };
      dispatch(getUpdateRecommendation(payload));
    }
  };

  // Drag n Drop
  const type = 'DragableBodyRow';

  const DragableBodyRow = ({
    index,
    moveRow,
    className,
    styles,
    ...restProps
  }) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: monitor => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
        };
      },
      drop: item => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      item: { type, index },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move', ...styles }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      />
    );
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = recommendedProducts[dragIndex];
      setRecommendedProducts(
        update(recommendedProducts, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      );
    },
    [recommendedProducts]
  );

  const manager = useRef(RNDContext);
  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  // Select products to add (add products modal)
  const onSelectToAdd = (selectedRowsKeys, selectedRowsData) => {
    setProductsToAdd(selectedRowsKeys);
    setProductsData(prev => {
      const current = prev.filter(
        item => !selectedRowsData.find(product => product.id === item.id)
      );
      return [...current, ...selectedRowsData];
    });
  };

  // Select products to remove (recommended products table)
  const onSelectToRemove = selectedRowKeys => {
    setProductsToRemove(selectedRowKeys);
  };

  // Products functions
  const handleAddProducts = () => {
    setIsModalVisible(false);
    setRecommendedProducts(
      productsData.filter(item => productsToAdd.includes(item.id))
    );
  };

  const handleRemoveProducts = () => {
    setRecommendedProducts(prev =>
      prev.filter(item => !productsToRemove.includes(item.id))
    );
    setProductsToAdd(prev =>
      prev.filter(item => !productsToRemove.includes(item))
    );
    setProductsToRemove([]);
  };

  const searchSelectRef = useRef();

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchSelectRef}
          placeholder="Nome do produto"
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchSelected(e.target.value || '');
          }}
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={confirm}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          id="recomm-products-clear-filter"
          onClick={clearFilters}
          size="small"
          style={{ width: 90 }}
        >
          Limpar
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <div
        style={{
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          color: filtered ? '#33d6ef' : '#333333'
        }}
      >
        <span style={{ fontSize: 14, padding: '0 8px' }}>
          {filtered ? searchSelected : 'BUSCAR'}
        </span>
        <Icon
          type={filtered ? 'close' : 'search'}
          style={{
            fontSize: filtered ? 16 : 18,
            paddingRight: 8
          }}
          onClick={e => {
            if (filtered) {
              e.stopPropagation();
              const clearBtn = document.getElementById(
                'aisle-products-clear-filter'
              );
              if (clearBtn) clearBtn.click();
            }
          }}
        />
      </div>
    ),
    onFilter: (value, record) =>
      (record.title || record.name)
        .toString()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchSelectRef.current.select());
      }
    }
  });

  // Tables
  const tableColumnsRem = [
    {
      key: 'image',
      width: 88,
      align: 'center',
      render: record => {
        return (
          <Avatar
            shape="square"
            size={60}
            alt={record.title || record.name}
            src={record.image || record.image}
            icon="camera"
            className="img-placeholder"
          />
        );
      }
    },
    {
      key: 'name',
      render: record => record.title || record.name,
      colSpan: 2,
      ...getColumnSearchProps()
    },
    {
      dataIndex: 'sort',
      width: 60,
      align: 'center',
      colSpan: 0,
      render: () => (
        <img src={dragIcon} alt="drag icon" style={{ fontSize: 20 }} />
      )
    }
  ];
  const tableColumnsAdd = [
    {
      title: 'Imagem',
      key: 'image',
      width: 88,
      align: 'center',
      render: record => {
        return (
          <Avatar
            shape="square"
            size={60}
            alt={record.name}
            src={record.image}
            icon="camera"
            className="img-placeholder"
          />
        );
      }
    },
    {
      title: 'Nome',
      key: 'name',
      render: record => record?.name || record?.title
    }
  ];

  // Recommendation actions
  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );
  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      {/* Add products modal */}
      <Modal
        className="user-logTable"
        visible={isModalVisible}
        title="ADICIONAR PRODUTOS"
        centered
        width={900}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="submit"
            onClick={handleAddProducts}
            className="ant-btn ant-btn-primary"
          >
            ADICIONAR
          </Button>
        ]}
      >
        <Searchbar
          placeholder="Buscar produto"
          populateResult={handleGetModalProducts}
          query={query}
          value={query.search}
        />
        <p style={{ marginTop: 20, marginBottom: 4, fontWeight: 'bold' }}>
          {productsToAdd.length} produto(s) selecionado(s)
        </p>
        {/* <span>
          *Produtos já adicionados não podem ser selecionados novamente
        </span> */}
        <Tables
          data={modalProducts}
          columns={tableColumnsAdd}
          rowKey={record => record.id}
          rowSelection={{
            selectedRowKeys: productsToAdd,
            onChange: (selectedId, data) => onSelectToAdd(selectedId, data)
          }}
          // rowClassName={record =>
          //   record?.attributes.present_on_aisle && 'disabled'
          // }
          isLoading={isLoadingProducts}
          hasScroll
          query={query}
          count={count}
          visiblePaginationDown
          populateTables={handleGetModalProducts}
        />
      </Modal>
      {/* Audit */}
      {!newRecommendation && (
        <Audit
          id={id}
          title="RECOMENDAÇÃO"
          type="Recommendation"
          visible={showAudit}
          setVisible={setShowAudit}
        />
      )}
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>
            {newRecommendation
              ? 'Nova Recomendação'
              : recommendation?.spotlight_product?.title}
          </h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/recomendacoes" style={{ textDecoration: 'underline' }}>
            Recomendações
          </Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {newRecommendation
            ? 'Nova Recomendação'
            : recommendation?.spotlight_product?.title}
        </Breadcrumb.Item>
      </Breadcrumb>
      <section
        style={{
          borderRadius: '16px',
          backgroundColor: '#ebebeb',
          minHeight: '48px',
          padding: '8px',
          marginBottom: 60
        }}
      >
        <Card
          className="no-shadow"
          title="DETALHES DA RECOMENDAÇÃO"
          extra={!newRecommendation ? actionsExtra : ''}
          style={{ marginBottom: '8px', padding: '10px 0 0' }}
        >
          <Row span={11}>
            <Form style={{ marginTop: 10 }}>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col span={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                      Tipo de recomendação
                    </p>
                  </div>
                  <Form.Item style={{ marginBottom: 10 }}>
                    <Select
                      mode="lucy"
                      labelInValue
                      placeholder="Todos os tipos"
                      onFocus={() => {}}
                      size="default"
                      style={{ marginBottom: '20px' }}
                      onSelect={e => {
                        setSelectedRecomType(e.key);
                      }}
                      onChange={e => setSelectedRecomType(e.key)}
                      value={
                        selectedRecomType
                          ? {
                              key: selectedRecomType,
                              label: recommendation.group_name
                            }
                          : undefined
                      }
                    >
                      <Option value={1} key={1}>
                        Conjunto
                      </Option>
                      <Option value={2} key={2}>
                        Upsell
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col span={10}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 3
                    }}
                  >
                    <p style={{ margin: 0, fontWeight: 'bold' }}>
                      Produto em destaque
                    </p>
                  </div>
                  <Form.Item>
                    <Select
                      showSearch
                      labelInValue
                      style={{ width: '100%' }}
                      placeholder="Digite o nome do produto"
                      showArrow={false}
                      size="default"
                      notFoundContent={
                        spotlightLoading ? <Spin size="small" /> : null
                      }
                      filterOption={false}
                      value={
                        selectedSLProduct?.id
                          ? {
                              key: selectedSLProduct?.id,
                              label: selectedSLProduct?.title
                            }
                          : undefined
                      }
                      onSelect={e =>
                        setSelectedSLProduct(
                          spotlightSelect.find(product => product.id === e.key)
                        )
                      }
                      onSearch={value => {
                        dispatch(setSpotlightLoading(true));
                        handleSpotlightSelect(value);
                      }}
                    >
                      {!spotlightLoading &&
                        spotlightSelect.map(product => {
                          if (
                            selectedSLProduct?.id &&
                            product.id !== selectedSLProduct.id
                          ) {
                            return (
                              <Option value={product.id} key={product.id}>
                                {product.attributes.name}
                              </Option>
                            );
                          }
                          return (
                            <Option value={product.id} key={product.id}>
                              {product.attributes.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                      Status
                    </p>
                  </div>
                  <Form.Item>
                    <Switch
                      checked={recomStatus}
                      onChange={e => {
                        setRecomStatus(e);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card>

        <Card
          className="no-shadow"
          style={{ paddingTop: 16, minHeight: '48px' }}
        >
          {!isSectionEnabled ? (
            <div className={style.disabledSection}>
              <Icon
                type="info-circle"
                theme="filled"
                className={style.disabledSection__icon}
              />
              <p className={style.disabledSection__text}>
                Preencha o tipo e destaque da recomendação para adicionar
                produtos.
              </p>
            </div>
          ) : null}

          <Row
            type="flex"
            justify="space-between"
            style={{ marginBottom: 16, position: 'relative' }}
          >
            <Col>
              <h2>PRODUTOS RECOMENDADOS</h2>
              <span>
                Selecione o(s) produtos(s) para adicionar na vitrine do produto
                em destaque.
              </span>
            </Col>
            <Col>
              <Button
                style={{ height: 36 }}
                onClick={() => setIsModalVisible(true)}
                className="ant-btn ant-btn-primary"
                disabled={productsToRemove.length > 0}
              >
                Adicionar Produto
              </Button>
            </Col>
          </Row>
          <Row>
            {productsToRemove?.length > 0 ? (
              <div className={style.removeBox}>
                <div className={style.removeBox__text}>
                  <p>{productsToRemove?.length} produto(s) selecionado(s)</p>
                </div>
                <Button
                  className="ant-btn"
                  onClick={() => handleRemoveProducts()}
                >
                  Remover
                </Button>
              </div>
            ) : null}

            {/* Drag and drop table */}
            {recommendedProducts.length > 0 ? (
              <DndProvider manager={manager.current.dragDropManager}>
                <Table
                  style={{ marginTop: 24 }}
                  columns={tableColumnsRem}
                  rowKey={record => record.id}
                  dataSource={recommendedProducts}
                  pagination={false}
                  className="ant-table-select-drag"
                  rowSelection={{
                    selectedRowKeys: productsToRemove,
                    onChange: (selectedId, data) =>
                      onSelectToRemove(selectedId, data)
                  }}
                  components={components}
                  onRow={(record, index) => ({
                    index,
                    moveRow
                  })}
                />
              </DndProvider>
            ) : (
              <div className={style.emptyTable}>
                <span>Nenhum produto cadastrado.</span>
              </div>
            )}
          </Row>
        </Card>
      </section>

      {isSectionEnabled ? (
        <footer className="footer-form-save">
          <Button
            disabled={productsToRemove.length > 0}
            style={{ height: 36, left: '85%' }}
            className="ant-btn ant-btn-primary"
            onClick={handleSaveButton}
            loading={isLoading}
          >
            Salvar
          </Button>
        </footer>
      ) : null}
    </>
  );
};

export default RecommendationPage;
