import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { Row, Breadcrumb, Col, Card, Form, Select, Button } from 'antd';
import PropTypes from 'prop-types';

import UploadImage from '@/src/components/UploadImage';

import { getManufacturers } from '@/src/store/modules/manufacturers/slice';
import { getProducts } from '@/src/store/modules/products/slice';
import { getCategories } from '@/src/store/modules/categories/slice';
import { getCreateHighlight } from '@/src/store/modules/highlights/slice';

import { translateHighlightsLabel } from '@/src/utils/localeData';
import { handleSelectSearch } from '@/src/utils/searchUtils';

const { Option } = Select;

const NewHighlight = props => {
  const dispatch = useDispatch();
  const { form } = props;
  const {
    getFieldDecorator,
    validateFields,
    getFieldsValue,
    setFieldsValue
  } = form;
  const fieldValues = getFieldsValue(['type', 'type_value']);
  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);
  // const [uploadWebImage, setUploadWebImage] = useState([]);
  // const [withoutWebImage, setWithoutWebImage] = useState(false);
  const [type, setType] = useState('');
  const [typeOptions, setTypeOptions] = useState([]);

  const { products } = useSelector(state => state.products);
  const { categories } = useSelector(state => state.categories);
  const { manufacturers } = useSelector(state => state.manufacturers);
  const { isLoading } = useSelector(state => state.highlights);

  const checkIfHasImage = () => {
    setWithoutImage(uploadImage.length === 0);
    // setWithoutWebImage(uploadWebImage.length === 0);
    // if (uploadImage.length === 0 || uploadWebImage.length === 0) return false;
    if (uploadImage.length === 0) return false;
    return true;
  };

  const handleSubmitWithAllValues = values => {
    if (checkIfHasImage()) {
      const imageNew = uploadImage.replace(/^data:.+;base64,/, '');
      // const webImageNew = uploadWebImage.replace(/^data:.+;base64,/, '');
      const payload = {
        highlightable_type: values.type,
        highlightable_id: values.type_value,
        image: imageNew,
        // web_image: webImageNew,
        sort: 0
      };
      // console.log(payload, 'payload');
      dispatch(getCreateHighlight(payload));
    }
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        checkIfHasImage();
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  const handleTypeChange = value => {
    setType(value);
    setFieldsValue({
      type_value: undefined
    });
  };

  useEffect(() => {
    if (type === 'product') {
      setTypeOptions([]);
    } else if (type === 'category') {
      setTypeOptions(categories);
      dispatch(getCategories({ query: { perPage: 1000 } }));
    } else if (type === 'manufacturer') {
      setTypeOptions(manufacturers);
      dispatch(getManufacturers({ query: { perPage: 1000 } }));
    }
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (type === 'product') {
      setTypeOptions(products);
    } else if (type === 'manufacturer') {
      setTypeOptions(manufacturers);
    } else if (type === 'category') {
      setTypeOptions(categories);
    }
    // eslint-disable-next-line
  }, [products, categories, manufacturers]);

  const delayedProductsQuery = useRef(
    debounce(200, value =>
      dispatch(getProducts({ query: { search: value, perPage: 100 } }))
    )
  ).current;

  const handleTypeValueSearch = value => {
    if (type !== 'product') return;
    if (value) {
      delayedProductsQuery(value);
    } else {
      setTypeOptions([]);
    }
  };

  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Novo Destaque</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/destaques">Destaques</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Novo destaque</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          minHeight: '48px'
        }}
      >
        <Card className="no-shadow" title="DETALHES DO DESTAQUE">
          <Row>
            <Col span={10}>
              <Form>
                <Row style={{ display: 'flex', flexDirection: 'column' }}>
                  <Col>
                    <Form.Item label="Tipo" colon={false}>
                      {getFieldDecorator('type', {
                        rules: [
                          {
                            required: true,
                            message: 'Selecione um tipo'
                          }
                        ]
                      })(
                        <Select
                          placeholder="Selecione um tipo"
                          onChange={handleTypeChange}
                        >
                          <Option value="product">Produto</Option>
                          <Option value="category">Categoria</Option>
                          <Option value="manufacturer">Fabricante</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    {type !== '' && (
                      <Form.Item
                        label={translateHighlightsLabel[type]}
                        colon={false}
                      >
                        {getFieldDecorator('type_value', {
                          rules: [
                            {
                              required: true,
                              message: `Selecione ${
                                translateHighlightsLabel[type] === 'Categoria'
                                  ? 'uma'
                                  : 'um'
                              } ${translateHighlightsLabel[type]}`
                            }
                          ]
                        })(
                          <Select
                            showSearch
                            placeholder="Buscar"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={
                              type === 'product' ? false : handleSelectSearch
                            }
                            onSearch={handleTypeValueSearch}
                            notFoundContent={
                              type === 'product'
                                ? 'Digite o nome do produto'
                                : null
                            }
                          >
                            {typeOptions.map(item => {
                              return (
                                <Option key={item.id}>
                                  {`${item.attributes.name}
                                ${
                                  item.type === 'category'
                                    ? ` para ${item.attributes.department_name}`
                                    : ''
                                }`}
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong
                style={{
                  display: 'block',
                  marginBottom: 8,
                  color: '#000000D9'
                }}
              >
                Imagem App
              </strong>
              <UploadImage
                setUploadImage={setUploadImage}
                uploadImage={uploadImage}
                withoutImage={withoutImage}
                setWithoutImage={setWithoutImage}
                recommendedSize={[600, 383]}
              />
            </Col>
            {/* <Col>
              <strong
                style={{
                  display: 'block',
                  marginBottom: 8,
                  color: '#000000D9'
                }}
              >
                Imagem Web
              </strong>
              <UploadImage
                setUploadImage={setUploadWebImage}
                uploadImage={uploadWebImage || []}
                withoutImage={withoutWebImage}
                setWithoutImage={setWithoutWebImage}
                recommendedSize={[600, 383]}
              />
            </Col> */}
          </Row>
          <Row style={{ marginTop: 24 }}>
            <Button
              className="ant-btn ant-btn-primary"
              onClick={onSubmit}
              disabled={
                !fieldValues.type ||
                !fieldValues.type_value ||
                !uploadImage.length
                // !uploadWebImage.length
              }
              loading={isLoading}
            >
              Salvar
            </Button>
          </Row>
        </Card>
      </div>
    </>
  );
};

NewHighlight.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldsValue: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const NewHighlightForm = Form.create({ name: 'NewHighlight ' })(NewHighlight);

export default NewHighlightForm;
