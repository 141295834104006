import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customFields: [],
  isLoading: false
};

const customFieldsSlice = createSlice({
  name: '@customFields',
  initialState,
  reducers: {
    setCustomFields(prevState, action) {
      const state = prevState;
      state.customFields = action.payload;
      return state;
    },
    setNewCustomField(prevState, action) {
      const state = prevState;
      state.customFields = [...state.customFields, action.payload];
      return state;
    },
    setUpdateCustomField(prevState, action) {
      const state = prevState;
      state.customFields = state.customFields.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      return state;
    },
    setLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
    },
    getCustomFields(state) {
      return state;
    },
    createCustomField(state) {
      return state;
    },
    updateCustomField(state) {
      return state;
    }
  }
});

export const {
  setCustomFields,
  setNewCustomField,
  setUpdateCustomField,
  setLoading,
  getCustomFields,
  createCustomField,
  updateCustomField
} = customFieldsSlice.actions;

export default customFieldsSlice.reducer;
