import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Card, Skeleton, Icon } from 'antd';

// import { setCurrentValues } from '@/src/store/modules/attribute-values/slice';

import AttributeCardSingle from '../AttributeCardSingle/index';

const AttributesCard = ({
  properties,
  productProperties,
  handleUpdate,
  loading,
  editPermission,
  isFilterable
}) => {
  // const history = useHistory();
  // const dispatch = useDispatch();
  const [atributeProperties, setAttributeProperties] = useState([]);

  useEffect(() => {
    const propertiesArr = properties.filter(item => {
      if (isFilterable) return item?.attributes?.filterable;
      return !item?.attributes?.filterable;
    });
    setAttributeProperties(propertiesArr);
    return () => {
      setAttributeProperties([]);
    };
  }, [properties, isFilterable]);

  // const handleClick = () => {
  //   dispatch(setCurrentValues([{ name: '' }]));
  //   history.push('/atributos/novo');
  // };

  return (
    <Card
      title={
        <>
          <h2>
            {isFilterable ? 'ATRIBUTOS FILTRÁVEIS' : 'ATRIBUTOS NÃO FILTRÁVEIS'}
          </h2>
          <span>
            {isFilterable
              ? 'Atributos exibidos como filtro nas vitrines do app e do site'
              : 'Atributos para uso interno, não exibidos no app e no site'}
          </span>
        </>
      }
      className="no-shadow box attribute-cards w-100"
      // style={{ position: 'relative' }}
    >
      <Skeleton loading={loading} active title={false} paragraph={{ rows: 4 }}>
        {/* {editPermission && (
            <Button
              type="secondary"
              onClick={handleClick}
              style={{ position: 'absolute', top: '15px', right: '15px' }}
            >
              Novo Atributo
            </Button>
          )} */}
        {atributeProperties.length ? (
          atributeProperties.map(property => (
            <Col span={12} key={property?.id}>
              <AttributeCardSingle
                property={property}
                productProperties={productProperties}
                handleUpdate={handleUpdate}
                editPermission={editPermission}
              />
            </Col>
          ))
        ) : (
          <div className="no-image-preview">
            <Icon
              style={{ fontSize: '24px', color: '#B3B3B3' }}
              type="unordered-list"
            />
            <span className="no-image-text">
              Não há opções associadas à categoria do produto
            </span>
          </div>
        )}
      </Skeleton>
    </Card>
  );
};

AttributesCard.defaultProps = {
  properties: [],
  productProperties: [],
  handleUpdate: () => {},
  loading: true
};

AttributesCard.propTypes = {
  properties: PropTypes.oneOfType([PropTypes.array]),
  productProperties: PropTypes.oneOfType([PropTypes.array]),
  handleUpdate: PropTypes.func,
  loading: PropTypes.bool,
  editPermission: PropTypes.bool.isRequired,
  isFilterable: PropTypes.bool.isRequired
};

export default AttributesCard;
