import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { errorAlert, successAlert } from '@/src/utils/notifications';

// import normalize from 'json-api-normalizer';
import {
  setSubscriptions,
  getSubscriptions,
  setSubscription,
  getSubscription,
  getCycles,
  setCycles,
  setIsLoading,
  setIsCycleLoading,
  exportCSV,
  pauseSubscriptionItem,
  unpauseAllSubscriptionItems,
  pauseAllSubscriptionItems,
  cancelSubscription,
  requestCancelSubscription,
  retryCycleBilling,
  editSubscription
} from '@/src/store/modules/subscriptions/slice';

import {
  getSubscriptions as getSubscriptionsRequest,
  getSubscription as getSubscriptionRequest,
  exportSubscriptions as exportCSVRequest,
  getSubscriptionCycles as getCyclesRequest,
  pauseSubscriptionItem as pauseItemRequest,
  requestCancelSubscription as requestCancelRequest,
  cancelSubscription as cancelRequest,
  unpauseAllSubscriptionItems as unpauseAllItemsRequest,
  pauseAllSubscriptionItems as pauseAllItemsRequest,
  retryCycleBilling as retryCycleBillingRequest,
  editSubscription as editSubscriptionRequest
} from '@/src/api/subscriptions';

import { cancelationSubscriptionOptions } from '@/src/components/Subscriptions/Modals/CancelSubscriptionModal/index';

import { getRouterState, getSubscriptionUrlQuery } from '@/src/store/selectors';

// Cancelation

function* cancelSubscriptionSaga(params) {
  const { payload } = params;

  try {
    yield call(cancelRequest, payload);
    const msg = cancelationSubscriptionOptions.filter(
      option => option.value === payload.cancelation_reason
    )[0];
    successAlert(`A assinatura foi cancelada. (Motivo: ${msg.text})`);
  } catch (err) {
    errorAlert(err);
  }
}
function* requestCancelSubscriptionSaga(params) {
  const { payload } = params;

  try {
    yield call(requestCancelRequest, payload);
  } catch (err) {
    errorAlert(err);
  }
}

// Pause

function* pauseSubscriptionItemSaga(params) {
  const { payload } = params;

  try {
    yield call(pauseItemRequest, payload);
    successAlert('O item foi pausado');
  } catch (err) {
    errorAlert(err);
  }
}
function* unpauseAllSubscriptionItemsSaga(params) {
  const { payload } = params;

  try {
    yield call(unpauseAllItemsRequest, payload);
    successAlert('Todos os itens foram retomados');
  } catch (err) {
    errorAlert(err);
  }
}
function* pauseAllSubscriptionItemsSaga(params) {
  const { payload } = params;

  try {
    yield call(pauseAllItemsRequest, payload);
    successAlert('Todos os itens foram pausados');
  } catch (err) {
    errorAlert(err);
  }
}

// Cycles

function* retryCycleBillingSaga(params) {
  const { payload } = params;

  try {
    yield call(retryCycleBillingRequest, payload);
  } catch (err) {
    errorAlert(err);
  }
}
function* getCyclesSaga(params) {
  const { payload } = params;

  try {
    yield put(setIsCycleLoading(true));
    const response = yield call(getCyclesRequest, payload);

    const { data } = response;

    yield put(
      setCycles({
        cycles: data.data,
        count: data.meta?.count || 0,
        query: payload.query
      })
    );
    yield put(setIsCycleLoading(false));
  } catch (err) {
    yield put(setIsCycleLoading(false));
    // errorAlert(err);
  }
}

// Subscriptions
function* editSubscriptionSaga(params) {
  const { payload } = params;

  try {
    yield call(editSubscriptionRequest, payload);
    successAlert('Assinatura atualizada com sucesso');
  } catch (err) {
    errorAlert(err);
  }
}
function* getSubscriptionSaga(params) {
  const { payload } = params;

  try {
    yield put(setIsLoading(true));
    const response = yield call(getSubscriptionRequest, payload);
    const { data } = response;

    let formattedIncluded = {};
    const items = [];

    data.included.forEach(included => {
      // console.log(included);
      if (included.type === 'subscription_item') {
        items.push(included);
      } else {
        formattedIncluded = { ...formattedIncluded, [included.type]: included };
      }
    });

    formattedIncluded = { ...formattedIncluded, subscription_item: items };

    const { attributes } = data.data;
    const address = {
      // id: attributes.address_id,
      street: attributes.address_street,
      city: attributes.address_city,
      state: attributes.address_state,
      country: attributes.address_country,
      number: attributes.address_number,
      district: attributes.address_district,
      complement: attributes.address_complement,
      zipcode: attributes.zipcode,
      place_id: attributes.place_id,
      latitude: attributes.address_latitude,
      longitude: attributes.address_longitude
    };
    const newObject = {
      attributes: {
        ...data?.data?.attributes,
        canCancelSubscription: data?.meta?.user_can_cancel_subscription
      },
      id: data.data.id,
      address,
      ...formattedIncluded
    };
    yield put(setSubscription(newObject));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getSubscriptionsSaga(params) {
  const { payload } = params;
  // console.log(payload);
  try {
    yield put(setIsLoading(true));
    const response = yield call(getSubscriptionsRequest, payload);

    const count = response.data.meta.count > 0 ? response.data.meta.count : 0;
    // const total = response.data.meta.total > 0 ? response.data.meta.total : 0;

    yield put(
      setSubscriptions({
        count,
        // total,
        subscriptions: response.data.data,
        query: payload.query
      })
    );

    const queryUrl = yield select(getSubscriptionUrlQuery);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }

    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

export function* exportCSVSaga(action) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(exportCSVRequest, action.payload);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${url.slice(27)}.csv`;
    document.body.appendChild(a);
    a.click();
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
  return yield put(setIsLoading(false));
}

function* subscriptionsSaga() {
  yield takeEvery(getSubscriptions, getSubscriptionsSaga);
  yield takeEvery(getSubscription, getSubscriptionSaga);
  yield takeEvery(getCycles, getCyclesSaga);
  yield takeEvery(retryCycleBilling, retryCycleBillingSaga);
  yield takeEvery(exportCSV, exportCSVSaga);
  yield takeEvery(pauseSubscriptionItem, pauseSubscriptionItemSaga);
  yield takeEvery(unpauseAllSubscriptionItems, unpauseAllSubscriptionItemsSaga);
  yield takeEvery(pauseAllSubscriptionItems, pauseAllSubscriptionItemsSaga);
  yield takeEvery(cancelSubscription, cancelSubscriptionSaga);
  yield takeEvery(requestCancelSubscription, requestCancelSubscriptionSaga);
  yield takeEvery(editSubscription, editSubscriptionSaga);
}

export default subscriptionsSaga;
