import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Button,
  Tag,
  Modal,
  InputNumber,
  Input,
  Icon,
  Alert,
  Avatar,
  Radio
} from 'antd';
import moment from '@/src/services/moment';

import OrderResumeBagIcon from '@/src/assets/images/order/order-resume-bag.png';
import OrderResumeNfesIcon from '@/src/assets/images/order/order-resume-nfes.png';
import formatCurrency from '@/src/utils/formatCurrency';
import formatParityText from '@/src/utils/formatParityText';
import {
  translateRefundMethod,
  translateRequestReasons,
  translateTicketStatus,
  translateTicketOrigin
} from '@/src/utils/localeData';
import { ZEEDOG_STORE } from '@/src/globals/constants';

import OrderResumeStyles from './OrderResume.module.scss';

const { TextArea } = Input;

const OrderResume = props => {
  const {
    order,
    orderItem,
    orderAlert,
    orderItemParcel,
    parcel,
    cancelOrder,
    dispatchReturnItens,
    editPermission
  } = props;
  const { attributes } = order.data;

  const [isPetzHub, setIsPetzHubs] = useState(
    order.data.attributes.hub_workflow === 'petz'
  );
  const [hasAlert, setHasAlert] = useState(false);
  const [orderRequests, setOrderRequests] = useState([]);
  // const [hasReturnedItems, setHasReturnedItems] = useState({
  //   items: [],
  //   totalQty: 0,
  //   totalPrice: 0,
  //   message: 'Quantidade: 0 itens'
  // });
  const [itemsToReturn, setItemsToReturn] = useState({
    items: [],
    totalQty: 0,
    totalPrice: 0,
    message: 'Devolução: 0 itens'
  });
  const [hasItemsToReturn, setHasItemsToReturn] = useState(true);

  const [requestReason, setRequestReason] = useState();
  const [requestRemarks, setRequestRemarks] = useState('');
  const [refundMethod, setRefundMethod] = useState();
  const [showRefundMethods, setShowRefundMethods] = useState(false);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelModalLoader, setCancelModalLoader] = useState(false);

  const [showReturnModal, setShowReturnModal] = useState(false);
  const [returnModalLoader, setReturnModalLoader] = useState(false);

  useEffect(() => {
    setIsPetzHubs(order.data.attributes.hub_workflow === 'petz');
  }, [order]);

  useEffect(() => {
    if (orderAlert.length) {
      const unresolved = orderAlert.filter(
        item => !item.attributes.alert_resolved
      );
      setHasAlert(!!unresolved.length);
    } else {
      setHasAlert(false);
    }
  }, [orderAlert]);

  useEffect(() => {
    // const hasReturnedQty = orderItem.filter(
    //   item => item.attributes.returned_quantity > 0
    // );
    // const items = hasReturnedQty.map(item => {
    //   return {
    //     id: item.attributes.id,
    //     qty: item.attributes.returned_quantity,
    //     price: item.attributes.returned_quantity * item.attributes.sale_price
    //   };
    // });
    // const totalQty = items.reduce((a, b) => a + b.qty, 0);
    // const totalPrice = items.reduce((a, b) => a + b.price, 0);
    // setHasReturnedItems({
    //   items,
    //   totalQty,
    //   totalPrice,
    //   message: `Quantidade: ${totalQty} ${totalQty === 1 ? 'item' : 'itens'}`
    // });
    const availableToReturn = orderItem.filter(
      item => item.attributes.quantity > item.attributes.returned_quantity
    );
    setHasItemsToReturn(!!availableToReturn.length);
  }, [orderItem, isPetzHub]);

  useEffect(() => {
    if (isPetzHub) {
      const requests = order.included
        .filter(item => item?.attributes?.returned_items?.length > 0)
        .map(item => ({
          ...item.attributes
        }));
      setOrderRequests(requests);
    }
  }, [order, isPetzHub]);

  const onChangeInputNumber = (id, value, price, bagId) => {
    setItemsToReturn(prevState => {
      const items = prevState.items.filter(item => item.id !== id);
      const newItem = {
        id,
        qty: value,
        price: price * value,
        bagId
      };
      items.push(newItem);

      const totalQty = items.reduce((a, b) => a + b.qty, 0);
      const totalPrice = items.reduce((a, b) => a + b.price, 0);
      return {
        items,
        totalQty,
        totalPrice,
        message: `Devolução: ${totalQty} ${totalQty === 1 ? 'item' : 'itens'}`
      };
    });
  };

  const handleCancelModal = () => {
    setRequestReason();
    setRequestRemarks('');
    setCancelModalLoader(false);
    setShowCancelModal(prevState => !prevState);
  };

  const handleSubmitCancelModal = () => {
    setReturnModalLoader(true);
    const payload = {
      id: order.data.id,
      data: {
        customer_id: order.data.relationships.customer.data.id,
        request_reason: requestReason,
        remarks: requestRemarks
      }
    };
    cancelOrder(payload);
    handleCancelModal();
  };

  const handleReturnModal = () => {
    setRequestReason();
    setRefundMethod();
    setShowRefundMethods(false);
    setReturnModalLoader(false);
    if (showReturnModal) {
      setItemsToReturn({
        items: [],
        totalQty: 0,
        totalPrice: 0,
        message: 'Devolução: 0 itens'
      });
    } else if (hasAlert) {
      const hasMissingQty = orderItem.filter(
        item => item.attributes.missing_quantity > 0
      );
      const items = hasMissingQty.map(item => {
        return {
          id: item.attributes.id,
          qty: item.attributes.missing_quantity,
          price: item.attributes.missing_quantity * item.attributes.sale_price
        };
      });
      const totalQty = items.reduce((a, b) => a + b.qty, 0);
      const totalPrice = items.reduce((a, b) => a + b.price, 0);
      setItemsToReturn({
        items,
        totalQty,
        totalPrice,
        message: `Devolução: ${totalQty} ${totalQty === 1 ? 'item' : 'itens'}`
      });
    }
    setShowReturnModal(prevState => !prevState);
  };

  const handleSubmitReturnModal = () => {
    const items = itemsToReturn.items.map(item => {
      return {
        order_item_id: item.id,
        quantity: item.qty
      };
    });
    const payload = {
      id: order.data.id,
      data: {
        request_reason: requestReason,
        remarks: requestRemarks,
        returned_products: items
      }
    };
    if (isPetzHub) payload.data.refund_method = refundMethod;
    // console.log(payload);
    dispatchReturnItens(payload);
    handleReturnModal();
  };

  const buildBagIncludedItems = (bagId, returnModal) => {
    const itemTemplate = (
      id,
      skuCode,
      thumb,
      title,
      quantity,
      listPrice,
      salePrice,
      skuName,
      missingQty,
      returnedQty,
      gift
    ) => {
      const itemToReturn = itemsToReturn.items.find(item => item.id === id);
      return (
        <>
          <li key={id}>
            <div className={OrderResumeStyles['bag-item-image']}>
              <Avatar
                shape="square"
                size={60}
                src={thumb}
                icon="camera"
                className="img-placeholder"
              />
              {gift && (
                <Icon type="gift" theme="filled" className="order-item-gift" />
              )}
            </div>
            <div className={OrderResumeStyles['bag-item-text-info']}>
              <p>
                {skuCode} - {title}
              </p>
              <p>
                {returnModal ? quantity - returnedQty : quantity} x{' '}
                {listPrice > salePrice ? (
                  <>
                    {formatCurrency(salePrice)}{' '}
                    <span
                      style={{
                        fontWeight: 'normal',
                        textDecoration: 'line-through'
                      }}
                    >
                      {formatCurrency(listPrice)}
                    </span>
                  </>
                ) : (
                  formatCurrency(listPrice)
                )}
              </p>
              {skuName ? <Tag>{skuName}</Tag> : ''}
            </div>
            {returnModal && (
              <div className={OrderResumeStyles['bag-item-input-picker']}>
                <InputNumber
                  min={hasAlert ? missingQty : 0}
                  max={quantity - returnedQty}
                  defaultValue={hasAlert ? missingQty : itemToReturn?.qty || 0}
                  formatter={value => `${value || 0}/${quantity - returnedQty}`}
                  parser={value =>
                    value.replace(`/${quantity - returnedQty}`, '')
                  }
                  onChange={value =>
                    onChangeInputNumber(id, value, salePrice, Number(bagId))
                  }
                  className="dark-input-number"
                />
                <p>{formatCurrency(itemToReturn ? itemToReturn.price : 0)}</p>
              </div>
            )}
          </li>
          {missingQty > 0 && (
            <div
              className={OrderResumeStyles['bag-item-list--alert']}
              style={{ color: hasAlert ? '#fdab02' : '#b3b3b3' }}
            >
              <Icon type="warning" theme="filled" />
              {returnModal && orderAlert.length && !hasAlert ? (
                <span>
                  Incidência resolvida em{' '}
                  {moment(orderAlert[0].attributes.updated_at).format(
                    'DD/MM/YY - HH:mm'
                  )}
                </span>
              ) : (
                <span>
                  {formatParityText(missingQty, 'produto')} com alerta
                </span>
              )}
            </div>
          )}
        </>
      );
    };
    if (bagId) {
      const bagIdNumber = Number(bagId, 10);
      const itemsToAdd = orderItemParcel
        .filter(itemParcel => itemParcel.attributes.parcel_id === bagIdNumber)
        .map(el => {
          return {
            item_id: el.attributes.order_item_id,
            quantity: el.attributes.quantity
          };
        });
      const elementsToAdd = [];
      itemsToAdd.forEach(itemToAdd => {
        const toAddId = itemToAdd.item_id;
        orderItem.forEach(item => {
          const itemId = Number(item.id, 10);
          if (toAddId === itemId) {
            const itemWithQuantityInBag = {
              ...item,
              quantity: itemToAdd.quantity
            };
            elementsToAdd.push(itemWithQuantityInBag);
          }
        });
      });
      return elementsToAdd.map(element => {
        const { quantity } = element;
        const {
          id,
          external_reference,
          sku_external_id,
          thumb,
          title,
          list_price,
          sale_price,
          sku_name,
          missing_quantity,
          returned_quantity,
          gift
        } = element.attributes;
        const sku_code = sku_external_id || external_reference;
        return itemTemplate(
          id,
          sku_code,
          thumb,
          title,
          quantity,
          list_price,
          sale_price,
          sku_name,
          missing_quantity,
          returned_quantity,
          gift
        );
      });
    }
    const orderItemsArr = returnModal
      ? orderItem.filter(
          el => el.attributes.quantity - el.attributes.returned_quantity > 0
        )
      : orderItem;
    return orderItemsArr.map(el => {
      const {
        id,
        external_reference,
        sku_external_id,
        thumb,
        title,
        quantity,
        list_price,
        sale_price,
        sku_name,
        missing_quantity,
        returned_quantity,
        gift
      } = el.attributes;
      const sku_code = sku_external_id || external_reference;
      return itemTemplate(
        id,
        sku_code,
        thumb,
        title,
        quantity,
        list_price,
        sale_price,
        sku_name,
        missing_quantity,
        returned_quantity,
        gift
      );
    });
  };

  const buildNfesSection = (orderAttr, type) => {
    const { invoice_data } = orderAttr;
    if (invoice_data) {
      const invoice = invoice_data.filter(data => data.type === type);
      return invoice.map((item, index) => (
        <div className={OrderResumeStyles.OrderResume__nfes}>
          <h4>
            Nota Fiscal: {type === 'sale' ? 'Venda' : 'Devolução'}
            {invoice.length > 1 && ` [${index + 1}]`}
          </h4>
          <div className={OrderResumeStyles['OrderResume__nfes--info']}>
            <a href={item.danfe_url} target="_blank" rel="noopener noreferrer">
              <img src={OrderResumeNfesIcon} alt="Invoice Download Icon" />
            </a>
          </div>
        </div>
      ));
    }
    return '';
  };

  const buildBagDetailsSection = (
    returnModal = false,
    returnedItems = false
  ) => {
    const bagTemplate = (
      key = 1,
      bagIndex = 0,
      bagsLength = undefined,
      bagId = undefined
    ) => (
      <div className={OrderResumeStyles['bag-details__bag']} key={key}>
        <div className={OrderResumeStyles['bag-details__bag--position']}>
          <h4>
            <span className={OrderResumeStyles['bag-id']}>{`${bagIndex +
              1}/${bagsLength || 1}`}</span>
            <span className={OrderResumeStyles['bag-icon']}>
              <img src={OrderResumeBagIcon} alt="Order Resume Bag Icon" />
            </span>
            <span className={OrderResumeStyles['bag-id']}>
              {bagId ? `#${bagId}` : ''}
            </span>
          </h4>
          {returnedItems ? (
            <ul className={OrderResumeStyles['bag-item-list']}>
              {orderItem
                .filter(item => item.attributes.returned_quantity > 0)
                .map(item => {
                  return (
                    <>
                      <li key={`returned-${item.attributes.id}`}>
                        <div className={OrderResumeStyles['bag-item-image']}>
                          <Avatar
                            shape="square"
                            size={60}
                            src={item.attributes.thumb}
                            icon="camera"
                            className="img-placeholder"
                          />
                          {item.attributes.gift && (
                            <Icon
                              type="gift"
                              theme="filled"
                              className="order-item-gift"
                            />
                          )}
                        </div>
                        <div
                          className={OrderResumeStyles['bag-item-text-info']}
                        >
                          <p>
                            {item.attributes.sku_external_id ||
                              item.attributes.external_reference}{' '}
                            - {item.attributes.title}
                          </p>
                          <p>
                            {item.attributes.returned_quantity} x{' '}
                            {formatCurrency(item.attributes.sale_price)}
                          </p>
                          {item.attributes.sku_name && (
                            <Tag>{item.attributes.sku_name}</Tag>
                          )}
                        </div>
                      </li>
                      {orderAlert.length > 0 &&
                        item.attributes.missing_quantity > 0 && (
                          <div
                            className={
                              OrderResumeStyles['bag-item-list--alert']
                            }
                            style={{ color: '#b3b3b3' }}
                          >
                            <Icon type="warning" theme="filled" />
                            <span>
                              Incidência resolvida em{' '}
                              {moment(
                                orderAlert[0].attributes.updated_at
                              ).format('DD/MM/YY - HH:mm')}
                            </span>
                          </div>
                        )}
                    </>
                  );
                })}
            </ul>
          ) : (
            <ul className={OrderResumeStyles['bag-item-list']}>
              {buildBagIncludedItems(bagId, returnModal)}
            </ul>
          )}
        </div>
      </div>
    );
    if (parcel.length <= 1) {
      const singleParcelbagId =
        parcel.length > 0 ? parcel[0].attributes.id : '';
      return bagTemplate(1, 0, undefined, singleParcelbagId);
    }
    return parcel.map((el, index) =>
      bagTemplate(el.attributes.id, index, parcel.length, el.id)
    );
  };

  // Hub Petz
  const buildOrderRequestSection = type => {
    return (
      <section className={OrderResumeStyles.OrderResume}>
        <Card
          className={`no-shadow ${OrderResumeStyles.ReturnSection} ${OrderResumeStyles.ReturnSection__cancelation}`}
          title="CANCELAMENTOS"
          style={{ width: '100%' }}
        >
          {/* <div className={OrderResumeStyles.ReturnSection__details}>
            <h4 className="text-bold">{hasReturnedItems.message}</h4>
            <h4 className="text-bold">
              - {formatCurrency(hasReturnedItems.totalPrice)}
            </h4>
          </div> */}
          {orderRequests.map((item, index) => (
            <>
              <div className={OrderResumeStyles['OrderResume__bag-details']}>
                <div
                  className={OrderResumeStyles['bag-details__bag']}
                  key={`order-request-${item.id}`}
                >
                  <div
                    className={OrderResumeStyles['bag-details__bag--position']}
                  >
                    <h4>
                      <span className={OrderResumeStyles['bag-id']}>{`${index +
                        1}/${orderRequests.length || 1}`}</span>
                      <span className={OrderResumeStyles['bag-icon']}>
                        <img
                          src={OrderResumeBagIcon}
                          alt="Order Resume Bag Icon"
                        />
                      </span>
                      <span className={OrderResumeStyles['bag-id']}>
                        {item.ticket_id ? `Ticket #${item.ticket_id}` : ''}
                      </span>
                    </h4>
                    <h4 style={{ fontWeight: 'normal' }}>
                      Ressarcimento via{' '}
                      {item.refund_method
                        ? translateRefundMethod[item.refund_method] ||
                          item.refund_method
                        : '(não informado)'}
                    </h4>
                    {/* <h4 style={{ fontWeight: 'normal' }}>
                      Data:{' '}
                      {item.created_at
                        ? moment(item.created_at).format('DD/MM/YY - HH:mm')
                        : '(não informado)'}
                    </h4> */}
                    <h4 style={{ fontWeight: 'normal' }}>
                      Motivo:{' '}
                      {item.request_reason
                        ? translateRequestReasons[item.request_reason] ||
                          item.request_reason
                        : '(não informado)'}
                    </h4>
                    <h4 style={{ fontWeight: 'normal' }}>
                      Status:{' '}
                      {item.ticket_status
                        ? translateTicketStatus[item.ticket_status] ||
                          item.ticket_status
                        : '(não informado)'}
                    </h4>
                    <h4 style={{ fontWeight: 'normal' }}>
                      Origem:{' '}
                      {item.origin
                        ? translateTicketOrigin[item.origin] || item.origin
                        : '(não informado)'}
                    </h4>
                    <ul className={OrderResumeStyles['bag-item-list']}>
                      {item.returned_items.map(sku => {
                        const skuData = orderItem.find(
                          i => Number(i.id) === Number(sku.order_item_id)
                        );
                        const {
                          sku_internal_id,
                          sku_external_id,
                          title,
                          sku_name,
                          sale_price,
                          thumb,
                          missing_quantity,
                          gift
                        } = skuData.attributes;
                        return (
                          <>
                            <li key={`returned-${sku_internal_id}`}>
                              <div
                                className={OrderResumeStyles['bag-item-image']}
                              >
                                <Avatar
                                  shape="square"
                                  size={60}
                                  src={thumb}
                                  icon="camera"
                                  className="img-placeholder"
                                />
                                {gift && (
                                  <Icon
                                    type="gift"
                                    theme="filled"
                                    className="order-item-gift"
                                  />
                                )}
                              </div>
                              <div
                                className={
                                  OrderResumeStyles['bag-item-text-info']
                                }
                              >
                                <p>
                                  {sku_external_id} - {title}
                                </p>
                                <p>
                                  {sku.quantity} x {formatCurrency(sale_price)}
                                </p>
                                {sku_name && <Tag>{sku_name}</Tag>}
                              </div>
                            </li>
                            {orderAlert.length > 0 && missing_quantity > 0 && (
                              <div
                                className={
                                  OrderResumeStyles['bag-item-list--alert']
                                }
                                style={{ color: '#b3b3b3' }}
                              >
                                <Icon type="warning" theme="filled" />
                                <span>
                                  Incidência resolvida em{' '}
                                  {moment(
                                    orderAlert[0].attributes.updated_at
                                  ).format('DD/MM/YY - HH:mm')}
                                </span>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ))}
          {buildNfesSection(attributes, 'return')}
        </Card>
      </section>
    );
  };

  const showCancelBtn = () => {
    if (editPermission && order.data.attributes.status === 'processing') {
      return (
        <Button type="danger" onClick={handleCancelModal}>
          Cancelar Pedido
        </Button>
      );
    }
    return <></>;
  };

  const showReturnBtn = () => {
    const statusCanReturn = isPetzHub
      ? order.data.attributes.status === 'delivered' ||
        order.data.attributes.status === 'handled' ||
        order.data.attributes.status === 'delivering'
      : true;

    const isVtex =
      order?.data?.attributes?.number?.indexOf('-01') !== -1 || false;

    if (editPermission && hasItemsToReturn && statusCanReturn && !isVtex) {
      return (
        <Button
          type={hasAlert ? 'alert' : 'primary'}
          onClick={handleReturnModal}
        >
          Devolver itens
        </Button>
      );
    }
    return <></>;
  };

  return (
    <>
      <Modal
        visible={showCancelModal}
        title="CANCELAMENTO DE PEDIDO"
        width={500}
        onOk={handleSubmitCancelModal}
        onCancel={handleCancelModal}
        footer={[
          <Button
            key="submit"
            type="danger"
            loading={cancelModalLoader}
            onClick={handleSubmitCancelModal}
            disabled={
              !requestReason || (requestReason === 'other' && !requestRemarks)
            }
          >
            CANCELAR PEDIDO
          </Button>
        ]}
      >
        <div>
          <p>
            Ao cancelar esse pedido, os produtos serão retornados à sua devida
            base. Apenas com um novo pedido o cliente poderá ter acesso aos
            produtos.
          </p>
          <p>
            <strong>Selecione o motivo do cancelamento:</strong>
          </p>

          <Radio.Group
            onChange={e => {
              setRequestReason(e.target.value);
              setRequestRemarks('');
            }}
            value={requestReason}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Radio value="cancelled_purchase" style={{ marginBottom: 4 }}>
              Desisti da compra
            </Radio>
            <Radio value="delivery_delay" style={{ marginBottom: 4 }}>
              Atraso na entrega
            </Radio>
            <Radio
              value="customer_made_wrong_purchase"
              style={{ marginBottom: 4 }}
            >
              Cliente efetuou a compra errada
            </Radio>
            <Radio
              value="will_reorder_with_different_items"
              style={{ marginBottom: 4 }}
            >
              Vou refazer com outro(s) item(ns)
            </Radio>
            <Radio
              value="other"
              style={{ marginBottom: requestReason === 'other' ? 12 : 0 }}
            >
              Outros
            </Radio>
          </Radio.Group>
          {requestReason === 'other' && (
            <TextArea
              onChange={e => setRequestRemarks(e.target.value)}
              placeholder="Descreva o motivo do cancelamento"
              autoSize={{ minRows: 3 }}
            />
          )}
        </div>
      </Modal>
      <Modal
        visible={showReturnModal}
        title={
          <>
            <div>DEVOLUÇÃO DE ITENS</div>
            {showRefundMethods && (
              <Button
                className="order-return-modal"
                type="link"
                onClick={() => setShowRefundMethods(false)}
              >
                Voltar para detalhes da devolução
              </Button>
            )}
            {attributes.has_pending_return && (
              <Alert
                message="Este pedido tem uma devolução pendente"
                type="warning"
                showIcon
                style={{ marginTop: '12px', border: '1px solid #ffe58f' }}
              />
            )}
          </>
        }
        width={900}
        style={attributes.has_pending_return ? { paddingBottom: 0 } : {}}
        bodyStyle={{ minHeight: 350, display: 'flex', flexDirection: 'column' }}
        onCancel={handleReturnModal}
        footer={null}
        className={OrderResumeStyles.ReturnModal}
        destroyOnClose
        centered
      >
        <Row
          type="flex"
          gutter={24}
          justify="space-between"
          style={{ flexGrow: 1 }}
        >
          {showRefundMethods ? (
            <Col>
              <p>Selecione a opção de reembolso:</p>
              <Radio.Group
                onChange={e => setRefundMethod(e.target.value)}
                value={refundMethod}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Radio value="redelivery" style={{ marginBottom: 4 }}>
                  Ressarcimento via reenvio/transportadora
                </Radio>
                <Radio value="wallet" style={{ marginBottom: 4 }}>
                  Ressarcimento via carteira digital
                </Radio>
                {order.data.attributes.payment_method === 'credit_card' && (
                  <Radio
                    value="credit_card_reversal"
                    style={{ marginBottom: 4 }}
                  >
                    Ressarcimento via estorno
                  </Radio>
                )}
                {order.data.attributes.payment_method === 'pix' && (
                  <Radio value="pix_reversal" style={{ marginBottom: 4 }}>
                    Ressarcimento via Pix
                  </Radio>
                )}
              </Radio.Group>
            </Col>
          ) : (
            <>
              <Col span={16}>
                <div
                  className={`${OrderResumeStyles['OrderResume__bag-details']} ${OrderResumeStyles['OrderResume__bag-details--with-item-picker']}`}
                >
                  {buildBagDetailsSection(true)}
                </div>
              </Col>
              <Col
                span={8}
                className={OrderResumeStyles['OrderResume__return-message']}
              >
                <h2>MOTIVO DE DEVOLUÇÃO</h2>
                <Radio.Group
                  onChange={e => setRequestReason(e.target.value)}
                  value={requestReason}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value="damaged_or_expired" style={{ marginBottom: 4 }}>
                    Avaria ou vencido
                  </Radio>
                  <Radio value="carrier_loss" style={{ marginBottom: 4 }}>
                    Extravio na transportadora
                  </Radio>
                  <Radio value="purchase_regret" style={{ marginBottom: 4 }}>
                    Arrependimento de compra
                  </Radio>
                  <Radio
                    value="defect_free_exchange"
                    style={{ marginBottom: 4 }}
                  >
                    Troca sem defeito
                  </Radio>
                  <Radio
                    value="customer_received_wrong_product"
                    style={{ marginBottom: 4 }}
                  >
                    Cliente recebeu produto errado
                  </Radio>
                  <Radio
                    value="product_dissatisfaction"
                    style={{ marginBottom: 4 }}
                  >
                    Insatisfação com o produto
                  </Radio>
                  <Radio
                    value="customer_did_not_receive_some_item"
                    style={{ marginBottom: 4 }}
                  >
                    Cliente não recebeu algum item
                  </Radio>
                  <Radio
                    value="will_reorder_with_different_items"
                    style={{ marginBottom: 4 }}
                  >
                    Vou refazer com outro(s) item(ns)
                  </Radio>
                  <Radio
                    value="other"
                    style={{ marginBottom: requestReason === 'other' ? 12 : 0 }}
                  >
                    Outros
                  </Radio>
                </Radio.Group>

                {requestReason === 'other' && (
                  <TextArea
                    onChange={e => setRequestRemarks(e.target.value)}
                    placeholder="Descreva o motivo da devolução"
                    autoSize={{ minRows: 3 }}
                  />
                )}
              </Col>
            </>
          )}
        </Row>
        <Row
          type="flex"
          align="middle"
          justify="end"
          className={OrderResumeStyles['OrderResume__modal-footer']}
        >
          {showRefundMethods && isPetzHub ? (
            <Button
              type={hasAlert ? 'alert' : 'primary'}
              loading={returnModalLoader}
              onClick={handleSubmitReturnModal}
              disabled={!refundMethod}
            >
              DEVOLVER ITENS
            </Button>
          ) : (
            <>
              <Col>
                <h2>{itemsToReturn.message}</h2>
              </Col>
              <Col>
                <Row type="flex" align="middle">
                  <h2 style={{ marginLeft: 4, marginRight: 16 }}>
                    - {formatCurrency(itemsToReturn.totalPrice)}
                  </h2>
                  <Button
                    type="primary"
                    // loading={returnModalLoader}
                    onClick={() => {
                      if (isPetzHub) {
                        setShowRefundMethods(true);
                      } else {
                        handleSubmitReturnModal();
                      }
                    }}
                    disabled={
                      itemsToReturn.totalQty === 0 ||
                      !requestReason ||
                      (requestReason === 'other' && !requestRemarks)
                    }
                  >
                    {isPetzHub ? 'CONTINUAR' : 'DEVOLVER ITENS'}
                  </Button>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Modal>
      <section className={OrderResumeStyles.OrderResume}>
        <Card
          className="no-shadow"
          title="Pedido"
          extra={showCancelBtn()}
          style={{ width: '100%' }}
        >
          <div className={OrderResumeStyles['OrderResume__order-price']}>
            <ul>
              <li>
                <h4 className="text-bold">
                  Subtotal: {attributes.items_count}{' '}
                  {attributes.items_count === 1 ? ' item' : ' itens'}
                </h4>
                <p>{formatCurrency(attributes.subtotal, true)}</p>
              </li>
              <li>
                <h4 className="text-bold">Cupom:</h4>
                <p>{attributes.coupon?.code || '–'}</p>
              </li>
              {ZEEDOG_STORE && (
                <li>
                  <h4 className="text-bold">Frete:</h4>
                  <p>
                    {attributes.shipping > 0
                      ? formatCurrency(attributes.shipping)
                      : 'Grátis'}
                  </p>
                </li>
              )}
              {attributes.service_fee > 0 && (
                <li>
                  <h4 className="text-bold">Taxa de Serviço:</h4>
                  <p>{formatCurrency(attributes.service_fee, true)}</p>
                </li>
              )}
              {attributes.wallet_discount > 0 && (
                <li>
                  <h4 className="text-bold">Créditos (Carteira):</h4>
                  <p>- {formatCurrency(attributes.wallet_discount, true)}</p>
                </li>
              )}
              {attributes.discount > 0 && (
                <li>
                  <h4 className="text-bold">Desconto:</h4>
                  <p>- {formatCurrency(attributes.discount, true)}</p>
                </li>
              )}

              {attributes.shipping_discount > 0 && (
                <li>
                  <h4 className="text-bold">Desconto de Frete:</h4>
                  <p>- {formatCurrency(attributes.shipping_discount, true)}</p>
                </li>
              )}
              {attributes.subscription_discount > 0 && (
                <li>
                  <h4 className="text-bold">Desconto de Assinatura:</h4>
                  <p>
                    - {formatCurrency(attributes.subscription_discount, true)}
                  </p>
                </li>
              )}
              {attributes.mtag_discount > 0 && (
                <li>
                  <h4 className="text-bold">Desconto de Marketing Tag:</h4>
                  <p>- {formatCurrency(attributes.mtag_discount, true)}</p>
                </li>
              )}
              <li>
                <h4 className="text-bold">Parcelas:</h4>
                <p>
                  {attributes.installments
                    ? `${attributes.installments} x de ${formatCurrency(
                        attributes.installment_value,
                        true
                      )}`
                    : '-'}
                </p>
              </li>
              <li>
                <h4 className="text-bold">TOTAL:</h4>
                <h4 className="text-bold">
                  {formatCurrency(attributes.total, true)}
                </h4>
              </li>
            </ul>
          </div>
          <div className={OrderResumeStyles['OrderResume__return-item']}>
            <h3>ITENS</h3>
            {showReturnBtn()}
          </div>
          <div className={OrderResumeStyles['OrderResume__bag-details']}>
            {buildBagDetailsSection()}
          </div>
          {buildNfesSection(attributes, 'sale')}
        </Card>
      </section>

      {isPetzHub
        ? orderRequests.length > 0 && buildOrderRequestSection('return')
        : orderItem.filter(item => item.attributes.returned_quantity > 0)
            .length > 0 && (
            <section className={OrderResumeStyles.OrderResume}>
              <Card
                className={`no-shadow ${OrderResumeStyles.ReturnSection}`}
                title="DEVOLUÇÕES"
                style={{ width: '100%' }}
              >
                {/* <div className={OrderResumeStyles.ReturnSection__details}>
                  <h4 className="text-bold">{hasReturnedItems.message}</h4>
                  <h4 className="text-bold">
                    - {formatCurrency(hasReturnedItems.totalPrice)}
                  </h4>
                </div> */}
                <div className={OrderResumeStyles['OrderResume__bag-details']}>
                  {buildBagDetailsSection(false, true)}
                </div>
                {buildNfesSection(attributes, 'return')}
              </Card>
            </section>
          )}
    </>
  );
};

OrderResume.propTypes = {
  order: PropTypes.shape({
    data: PropTypes.shape({
      attributes: PropTypes.objectOf(PropTypes.any),
      id: PropTypes.string,
      relationships: PropTypes.shape({
        customer: PropTypes.shape({
          data: PropTypes.shape({
            id: PropTypes.string
          })
        })
      }),
      type: PropTypes.string
    }),
    included: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  orderItem: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.objectOf(PropTypes.any)
    })
  ).isRequired,
  orderAlert: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.objectOf(PropTypes.any)
    })
  ).isRequired,
  parcel: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.objectOf(PropTypes.any)
    })
  ).isRequired,
  orderItemParcel: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.objectOf(PropTypes.any)
    })
  ).isRequired,
  cancelOrder: PropTypes.func.isRequired,
  dispatchReturnItens: PropTypes.func.isRequired,
  editPermission: PropTypes.bool.isRequired
};

export default React.memo(OrderResume);
