export const buildQueryFromUrl = (history, params = {}) => {
  const queries = new URLSearchParams(history.location.search);
  const predefinedParams = {
    sort: '',
    page: 1,
    perPage: 20,
    search: '',
    ...params
  };
  [...queries.entries()].map(pair => {
    if (pair[0].includes('sort')) {
      predefinedParams.sort = `${pair[0].replace('sort', '')}=${pair[1]}`;
    } else if (pair[0] === 'page') {
      predefinedParams.page = Number(pair[1], 10);
    } else if (pair[0] === 'q') {
      predefinedParams.search = `${pair[1]}`;
    } else if (pair[0] === 'per_page') {
      predefinedParams.perPage = Number(pair[1], 10);
    } else if (pair[0] === 'days_ago') {
      predefinedParams.daysAgo = `${pair[1]}`;
    } else if (pair[0].includes('filters')) {
      const stringParam = pair[0]
        .replace('filters[', '')
        .replace(']', '')
        .replace('[]', '');

      let filterValue = pair[1];
      if (predefinedParams.filters && predefinedParams.filters[stringParam]) {
        if (predefinedParams.filters[stringParam].length) {
          filterValue = [...predefinedParams.filters[stringParam]];
          filterValue.push(pair[1]);
        } else {
          filterValue = predefinedParams.filters[stringParam];
        }
      } else if (stringParam === 'by_hub') {
        filterValue = [pair[1]];
      }

      predefinedParams.filters = {
        ...predefinedParams.filters,
        [stringParam]: filterValue
      };
    } else if (pair[0] === 'price_table_ids') {
      predefinedParams.price_table_ids = `${pair[1]}`;
    } else if (pair[0] === 'sku_price_lte') {
      predefinedParams.sku_price_lte = `${pair[1]}`;
    } else if (pair[0] === 'sku_price_gte') {
      predefinedParams.sku_price_gte = `${pair[1]}`;
    } else if (pair[0] === 'hub_ids') {
      predefinedParams.hub_ids = `${pair[1]}`;
    }
    return false;
  });
  return predefinedParams;
};

export const buildQueryStringFromObj = filtersObj => {
  let filtersQuery = '';
  if (filtersObj) {
    Object.keys(filtersObj).forEach(key => {
      if (
        key === 'by_hub' ||
        key === 'by_status' ||
        key === 'by_deliverer' ||
        key === 'by_property'
      ) {
        filtersObj[key].forEach(id => {
          filtersQuery += `&filters[${key}][]=${id}`;
        });
      } else if (key === 'by_coupons' || key === 'by_order_request_origin') {
        filtersObj[key].forEach(id => {
          filtersQuery += `&filters[${key}][]=${id}`;
        });
      } else if (key === 'pet_breed') {
        filtersObj[key].forEach(id => {
          filtersQuery += `&filters[${key}][]=${id}`;
        });
      } else if (key === 'by_tag' && filtersObj[key].length === 0) {
        filtersQuery = '';
      } else {
        filtersQuery += `&filters[${key}]=${filtersObj[key]}`;
      }
    });
  }
  return filtersQuery;
};
