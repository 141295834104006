import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Card, Checkbox, Radio, Button, Input, Icon } from 'antd';
import Add from '@/src/assets/images/add.svg';

import { getUpdateAttribute } from '@/src/store/modules/attributes/slice';

const AttributeCardSingle = ({
  property,
  productProperties,
  handleUpdate,
  editPermission
}) => {
  const dispatch = useDispatch();
  const { attributeData } = useSelector(state => state.attributes);

  const [attributeValues, setAttributesValues] = useState([]);
  const [showNewValue, setShowNewValue] = useState(false);
  const [groupValue, setGroupValue] = useState([]);
  const inputNewValue = useRef();

  useEffect(() => {
    setAttributesValues(property.attributes.allowed_values);
    const allowedValuesIds = property.attributes.allowed_values.map(
      item => item.id
    );
    const selectedValue = productProperties.filter(
      item => allowedValuesIds.includes(item.id) && item.selected_id
    );
    setGroupValue(selectedValue.map(item => item.id));
  }, [property.attributes, productProperties]);

  useEffect(() => {
    const { attributes } = attributeData;
    if (Number(property?.id) === Number(attributes?.id))
      setAttributesValues(attributes.allowed_values);
  }, [property, attributeData]);

  const handleNewItem = () => {
    const newValues = [];
    const label = inputNewValue.current.state.value;

    attributeValues.forEach(attribute => {
      newValues.push(attribute?.value);
    });

    newValues.push(label);
    inputNewValue.current.handleReset();

    dispatch(
      getUpdateAttribute({
        id: property?.id,
        data: {
          name: property?.attributes?.name,
          values: newValues
        },
        callFromAttributeSingleCard: true
      })
    );
    setShowNewValue(false);
  };

  const handleGroupValueChange = value => {
    const selected = [];
    const unselected = [];
    attributeValues.forEach(item => {
      if (value.includes(item.id)) {
        selected.push(item.id);
      } else {
        unselected.push(item.id);
      }
    });
    setGroupValue(value);
    handleUpdate(selected, unselected);
  };

  const renderValues = renderItem => {
    const { id, value } = renderItem;
    // https://github.com/facebook/react/issues/13477
    // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
    const isSelected = productProperties.some(item => {
      if (item.id === renderItem.id && item.selected_id) return true;
      return false;
    });

    return (
      <Row
        key={`${id}-${isSelected ? 'sel' : 'notsel'}-${
          productProperties.length
        }`}
      >
        {property?.attributes?.allow_multiple ? (
          <Checkbox
            value={id}
            style={{ margin: '5px 0' }}
            disabled={!editPermission}
          >
            {value || `Atributo ${id}`}
          </Checkbox>
        ) : (
          <Radio
            value={id}
            style={{ margin: '5px 0' }}
            disabled={!editPermission}
          >
            {value}
          </Radio>
        )}
      </Row>
    );
  };

  if (!attributeValues)
    return (
      <Card
        title={property?.attributes?.name}
        size="small"
        className="no-shadow attribute-card"
      >
        Não há valores cadastrados
      </Card>
    );

  return (
    <Card
      title={property?.attributes?.name}
      size="small"
      className="no-shadow attribute-card"
      extra={
        // property?.attributes?.allow_multiple ||
        !groupValue.length ? null : (
          <Button
            className="product-property-card"
            type="link"
            onClick={() => handleGroupValueChange([])}
          >
            Limpar
          </Button>
        )
      }
    >
      {property?.attributes?.allow_multiple ? (
        <Checkbox.Group
          onChange={e => {
            handleGroupValueChange([...e]);
          }}
          value={groupValue}
        >
          {attributeValues?.map(item => renderValues(item))}
        </Checkbox.Group>
      ) : (
        <Radio.Group
          onChange={e => {
            handleGroupValueChange([e.target.value]);
          }}
          value={groupValue[0]}
        >
          {attributeValues?.map(item => renderValues(item))}
        </Radio.Group>
      )}
      {editPermission && (
        <Row style={{ margin: '5px 0' }}>
          {showNewValue ? (
            <div className="new-value-attributes">
              <Input
                placeholder="Novo Valor"
                ref={inputNewValue}
                onPressEnter={() => handleNewItem(property)}
              />
              <Button
                className="add-new-attribute"
                onClick={() => handleNewItem(property)}
              >
                <Icon type="check" />
              </Button>
              <Button
                className="cancel-new-attribute"
                onClick={() => setShowNewValue(false)}
              >
                <Icon type="close" />
              </Button>
            </div>
          ) : (
            <div>
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  background: 'none',
                  fontWeight: 'bold',
                  color: '#000000D9',
                  padding: '0',
                  cursor: 'pointer'
                }}
                type="button"
                onClick={() => setShowNewValue(true)}
              >
                <img style={{ marginRight: 6, width: 16 }} src={Add} alt="+" />
                <span>Novo Valor</span>
              </button>
            </div>
          )}
        </Row>
      )}
    </Card>
  );
};

AttributeCardSingle.defaultProps = {
  property: [],
  productProperties: [],
  handleUpdate: () => {}
};

AttributeCardSingle.propTypes = {
  property: PropTypes.oneOfType([PropTypes.object]),
  productProperties: PropTypes.oneOfType([PropTypes.array]),
  handleUpdate: PropTypes.func,
  editPermission: PropTypes.bool.isRequired
};

export default AttributeCardSingle;
