import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import {
  Collapse,
  Select,
  DatePicker,
  Switch,
  Row,
  Form,
  Checkbox,
  Col,
  InputNumber,
  Icon
} from 'antd';

import moment from '@/src/services/moment';

import { getCoupons } from '@/src/store/modules/orders/slice';
import { getTags } from '@/src/store/modules/tags/slice';

import { getHubs } from '@/src/store/modules/hubs/slice';

import formatCurrency from '@/src/utils/formatCurrency';
import getNumberFromString from '@/src/utils/getNumberFromString';

const { Panel } = Collapse;
const { Option } = Select;

const OrdersFilters = props => {
  const { ordersFilters, setOrdersFilters } = props;
  // console.log(ordersFilters);

  const dispatch = useDispatch();

  const { query, coupons } = useSelector(state => state.orders);

  const { hubs } = useSelector(state => state.hubs);

  const tagsToFilters = useSelector(state => state.tags.tagsFilters);

  const pendingOptions = [
    { label: 'Petz', value: 'petz' },
    { label: 'Cliente', value: 'storefront' },
    { label: 'Zee', value: 'portal' }
  ];

  const statusOptions = [
    { label: 'Aguardando', value: 'waiting' },
    { label: 'Preparando', value: 'processing' },
    { label: 'Saiu para entrega', value: 'handled' },
    { label: 'Chegando', value: 'delivering' },
    { label: 'Entregue', value: 'delivered' },
    { label: 'Cancelado', value: 'canceled' }
  ];

  const populateHubs = () => dispatch(getHubs());
  const populateTags = () => dispatch(getTags({ taggable_type: 'customer' }));

  // const delayedDeliverersQuery = useRef(
  //   debounce(200, value => dispatch(getDeliverers(value)))
  // ).current;

  // const handleDeliverers = value => {
  //   delayedDeliverersQuery(value);
  // };

  const delayedCouponsQuery = useRef(
    debounce(200, value => dispatch(getCoupons(value)))
  ).current;

  const handleCoupons = value => {
    delayedCouponsQuery(value);
  };

  const handleFilterSelect = (value, type) => {
    switch (type) {
      case 'MIN_VALUE':
        setOrdersFilters({
          ...ordersFilters,
          min_value: value
        });
        break;
      case 'MAX_VALUE':
        setOrdersFilters({
          ...ordersFilters,
          max_value: value
        });
        break;
      case 'NUM_ITEM_MIN':
        setOrdersFilters({
          ...ordersFilters,
          more_items_than: value
        });
        break;
      case 'NUM_ITEM_MAX':
        setOrdersFilters({
          ...ordersFilters,
          less_items_than: value
        });
        break;
      case 'PARTIAL_DELIVER':
        setOrdersFilters({
          ...ordersFilters,
          partially_delivered: value
        });
        break;
      case 'WITH_ALERT':
        setOrdersFilters({
          ...ordersFilters,
          with_open_issue: value
        });
        break;
      case 'FRAUDULENT':
        setOrdersFilters({
          ...ordersFilters,
          fraudulent: value
        });
        break;
      case 'START_DATE':
        setOrdersFilters({
          ...ordersFilters,
          start_date: value ? moment(value).format('YYYY-MM-DD') : null
        });
        break;
      case 'END_DATE':
        setOrdersFilters({
          ...ordersFilters,
          end_date: value ? moment(value).format('YYYY-MM-DD') : null
        });
        break;
      case 'BY_STATUS':
        setOrdersFilters({
          ...ordersFilters,
          by_status: value
        });
        break;
      case 'BY_HUB':
        setOrdersFilters({
          ...ordersFilters,
          by_hub: value
        });
        break;
      case 'BY_DELIVERER':
        setOrdersFilters({
          ...ordersFilters,
          by_deliverer: value
        });
        break;
      case 'BY_COUPONS':
        setOrdersFilters({
          ...ordersFilters,
          by_coupons: value
        });
        break;
      case 'TAGS':
        setOrdersFilters({
          ...ordersFilters,
          by_tag: value ? value.map(tag => tag.replace(/\s/g, '_')) : ''
        });
        break;
      case 'BY_ORDER_REQUEST_ORIGIN':
        setOrdersFilters({
          ...ordersFilters,
          by_order_request_origin: value
        });
        break;
      default:
        console.warn('Don`t match any filter type:', type); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    setOrdersFilters(query.filters);
  }, [setOrdersFilters, query]);

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={() => <Icon type="caret-down" />}
        expandIconPosition="right"
      >
        <Panel header="Detalhes do pedido" key="1">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Valor mínimo"
                  value={ordersFilters.min_value}
                  min={0}
                  step={1}
                  onChange={evt => handleFilterSelect(evt, 'MIN_VALUE')}
                  formatter={value => formatCurrency(value, false)}
                  parser={value => getNumberFromString(value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Valor máximo"
                  value={ordersFilters.max_value}
                  min={0}
                  step={1}
                  onChange={evt => handleFilterSelect(evt, 'MAX_VALUE')}
                  formatter={value => formatCurrency(value, false)}
                  parser={value => getNumberFromString(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={10}>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Nº de itens mínimo"
                  value={ordersFilters.more_items_than}
                  min={0}
                  step={1}
                  formatter={value => `${value}`.replace(/\D+/g, '')}
                  onChange={evt => handleFilterSelect(evt, 'NUM_ITEM_MIN')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <InputNumber
                  placeholder="Nº de itens máximo"
                  value={ordersFilters.less_items_than}
                  min={0}
                  step={1}
                  formatter={value => `${value}`.replace(/\D+/g, '')}
                  onChange={evt => handleFilterSelect(evt, 'NUM_ITEM_MAX')}
                />
              </Form.Item>
            </Col>
          </Row> */}
          <Row>
            <Form.Item className="grey-border-b">
              <Col span={20}>Entrega parcial</Col>
              <Col span={4}>
                <Switch
                  defaultChecked={Boolean(ordersFilters.partially_delivered)}
                  checked={Boolean(ordersFilters.partially_delivered)}
                  onChange={evt => handleFilterSelect(evt, 'PARTIAL_DELIVER')}
                />
              </Col>
            </Form.Item>
          </Row>
          {/* <Row>
            <Form.Item className="grey-border-b">
              <Col span={20}>Com alerta</Col>
              <Col span={4}>
                <Switch
                  defaultChecked={Boolean(ordersFilters.with_open_issue)}
                  checked={Boolean(ordersFilters.with_open_issue)}
                  onChange={evt => handleFilterSelect(evt, 'WITH_ALERT')}
                />
              </Col>
            </Form.Item>
          </Row> */}
          <Row>
            <Form.Item className="grey-border-b">
              <Col span={20}>Fraudulento</Col>
              <Col span={4}>
                <Switch
                  defaultChecked={Boolean(ordersFilters.fraudulent)}
                  checked={Boolean(ordersFilters.fraudulent)}
                  onChange={evt => handleFilterSelect(evt, 'FRAUDULENT')}
                />
              </Col>
            </Form.Item>
          </Row>
        </Panel>
        <Panel header="Hubs" key="2">
          <Row>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Selecione os hubs"
              onFocus={populateHubs}
              defaultValue={ordersFilters?.by_hub}
              value={ordersFilters?.by_hub || []}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={evt => handleFilterSelect(evt, 'BY_HUB')}
            >
              {hubs.map(hub => {
                return <Option key={hub.id}>{hub.attributes.name}</Option>;
              })}
            </Select>
          </Row>
        </Panel>
        {/* <Panel header="Entregador" key="3">
          <Select
            mode="multiple"
            showSearch
            placeholder="Pesquisar por nome"
            onSearch={handleDeliverers}
            onChange={evt => handleFilterSelect(evt, 'BY_DELIVERER')}
            notFoundContent="Nenhum entregador encontrado"
            filterOption={false}
          >
            {deliverers.map(item => {
              return <Option key={item.id}>{item.attributes.name}</Option>;
            })}
          </Select>
        </Panel> */}
        <Panel header="Data" key="4">
          <Row gutter={10}>
            <Col span={12}>
              <DatePicker
                value={
                  ordersFilters.start_date
                    ? moment(ordersFilters.start_date)
                    : null
                }
                format="DD/MM/YY"
                placeholder="de DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'START_DATE')}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                value={
                  ordersFilters.end_date ? moment(ordersFilters.end_date) : null
                }
                format="DD/MM/YY"
                placeholder="até DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'END_DATE')}
              />
            </Col>
          </Row>
        </Panel>
        <Panel header="Cupons" key="5">
          <Select
            mode="multiple"
            showSearch
            placeholder="Pesquisar código"
            value={ordersFilters?.by_coupons || []}
            onSearch={handleCoupons}
            onChange={evt => {
              handleFilterSelect(evt, 'BY_COUPONS');
            }}
            notFoundContent="Nenhum cupom encontrado"
            filterOption={false}
          >
            {coupons.map(item => {
              return (
                <Option key={item.attributes.code}>
                  {item.attributes.code}
                </Option>
              );
            })}
          </Select>
        </Panel>
        <Panel header="Status" key="6">
          <Checkbox.Group
            onChange={evt => handleFilterSelect(evt, 'BY_STATUS')}
            defaultValue={ordersFilters?.by_status}
            value={ordersFilters?.by_status}
            style={{ width: '100%' }}
          >
            <Row type="flex">
              {statusOptions.map(option => (
                <Col span={12} key={option.value}>
                  <Checkbox value={option.value}>{option.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Panel>
        <Panel header="Tags do cliente" key="7">
          <Row>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Selecione a tag"
              onFocus={populateTags}
              defaultValue={ordersFilters.tags_orders_default}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={evt => handleFilterSelect(evt, 'TAGS')}
            >
              {tagsToFilters.map(tags => {
                return <Option key={tags}>{tags}</Option>;
              })}
            </Select>
          </Row>
        </Panel>
        <Panel header="Solicitações pendentes" key="8">
          <Row>
            <Select
              style={{ width: '100%' }}
              placeholder="Selecione a origem da solicitação"
              mode="multiple"
              value={ordersFilters?.by_order_request_origin || []}
              onChange={evt => {
                handleFilterSelect(evt, 'BY_ORDER_REQUEST_ORIGIN');
              }}
            >
              {pendingOptions.map(option => {
                return <Option key={option.value}>{option.label}</Option>;
              })}
            </Select>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};

OrdersFilters.defaultProps = {
  setOrdersFilters: () => {},
  ordersFilters: {
    min_value: null,
    max_value: null,
    more_items_than: '',
    less_items_than: '',
    partially_delivered: false,
    with_open_issue: false,
    start_date: null,
    end_date: null,
    by_hub: [],
    by_status: [],
    by_deliverer: [],
    by_coupons: [],
    by_order_request_origin: []
  }
};
OrdersFilters.propTypes = {
  setOrdersFilters: PropTypes.func,
  ordersFilters: PropTypes.shape({
    min_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    more_items_than: PropTypes.string,
    less_items_than: PropTypes.string,
    partially_delivered: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    with_open_issue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    fraudulent: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    start_date: PropTypes.any,
    end_date: PropTypes.any,
    by_hub: PropTypes.arrayOf(PropTypes.string),
    by_status: PropTypes.arrayOf(PropTypes.string),
    by_deliverer: PropTypes.arrayOf(PropTypes.string),
    by_coupons: PropTypes.arrayOf(PropTypes.string),
    by_order_request_origin: PropTypes.arrayOf(PropTypes.string),
    tags_orders_default: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
  })
};

export default React.memo(OrdersFilters);
