import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { debounce } from 'throttle-debounce';
import {
  Collapse,
  Select,
  DatePicker,
  Button,
  // Switch,
  Row,
  // Form,
  Checkbox,
  // Input,
  Col,
  InputNumber,
  Icon
} from 'antd';

import moment from '@/src/services/moment';

// import {
//   /* getDeliverers, */ getCoupons
// } from '@/src/store/modules/orders/slice';
// import { getTags } from '@/src/store/modules/tags/slice';

// import { getHubs } from '@/src/store/modules/hubs/slice';

// import formatCurrency from '@/src/utils/formatCurrency';
// import getNumberFromString from '@/src/utils/getNumberFromString';

const { Panel } = Collapse;
const { Option } = Select;

const SubscriptionsFilters = props => {
  const { subscriptionsFilters, setSubscriptionsFilters } = props;
  const [isCustomValue, setIsCustomValue] = useState(false);
  // console.log(ordersFilters);

  // const dispatch = useDispatch();

  // const { /* deliverers, */ coupons } = useSelector(
  //   state => state.orders
  // );

  const { query } = useSelector(state => state.subscriptions);

  // const { hubs } = useSelector(state => state.hubs);

  // const tagsToFilters = useSelector(state => state.tags.tagsFilters);

  const statusOptions = [
    { label: 'Ativa', value: 'active' },
    { label: 'Cancelada', value: 'inactive' }
    // { label: 'Pulando', value: 'processing' },
    // { label: 'Pausada', value: 'paused' },
    // { label: 'Erro em tentativa', value: 'error' },
  ];
  const frequencyOptions = [
    { label: '15 dias', value: 15 },
    { label: '20 dias', value: 20 },
    { label: '30 dias', value: 30 },
    { label: '45 dias', value: 45 },
    { label: '60 dias', value: 60 },
    { label: '90 dias', value: 90 },
    { label: '105 dias', value: 105 },
    { label: '120 dias', value: 120 }
  ];

  // const populateHubs = () => dispatch(getHubs());
  // const populateTags = () => dispatch(getTags({ taggable_type: 'customer' }));

  // const delayedDeliverersQuery = useRef(
  //   debounce(200, value => dispatch(getDeliverers(value)))
  // ).current;

  // const handleDeliverers = value => {
  //   delayedDeliverersQuery(value);
  // };

  // const delayedCouponsQuery = useRef(
  //   debounce(200, value => dispatch(getCoupons(value)))
  // ).current;

  // const handleCoupons = value => {
  //   delayedCouponsQuery(value);
  // };

  const handleFilterSelect = (value, type) => {
    switch (type) {
      case 'START_DATE':
        setSubscriptionsFilters({
          ...subscriptionsFilters,
          start_date: value ? moment(value).format('YYYY-MM-DD') : null
        });
        break;
      case 'END_DATE':
        setSubscriptionsFilters({
          ...subscriptionsFilters,
          end_date: value ? moment(value).format('YYYY-MM-DD') : null
        });
        break;
      case 'next_billing_start_date':
        setSubscriptionsFilters({
          ...subscriptionsFilters,
          next_billing_start_date: value
            ? moment(value).format('YYYY-MM-DD')
            : null
        });
        break;
      case 'next_billing_end_date':
        setSubscriptionsFilters({
          ...subscriptionsFilters,
          next_billing_end_date: value
            ? moment(value).format('YYYY-MM-DD')
            : null
        });
        break;
      case 'BY_STATUS':
        setSubscriptionsFilters({
          ...subscriptionsFilters,
          by_status: value
        });
        break;
      case 'BY_INTERVAL':
        setSubscriptionsFilters({
          ...subscriptionsFilters,
          by_interval: value
        });
        break;
      default:
        console.warn('Don`t match any filter type:', type); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    setSubscriptionsFilters(query.filters);
    // eslint-disable-next-line
  }, [query]);

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={() => <Icon type="caret-down" />}
        expandIconPosition="right"
      >
        <Panel header="Próxima cobrança" key="1">
          <Row gutter={10}>
            <Col span={12}>
              <DatePicker
                value={
                  subscriptionsFilters.next_billing_start_date
                    ? moment(subscriptionsFilters.next_billing_start_date)
                    : null
                }
                format="DD/MM/YY"
                placeholder="de DD/MM/AA"
                onChange={evt =>
                  handleFilterSelect(evt, 'next_billing_start_date')
                }
              />
            </Col>
            <Col span={12}>
              <DatePicker
                value={
                  subscriptionsFilters.next_billing_end_date
                    ? moment(subscriptionsFilters.next_billing_end_date)
                    : null
                }
                format="DD/MM/YY"
                placeholder="até DD/MM/AA"
                onChange={evt =>
                  handleFilterSelect(evt, 'next_billing_end_date')
                }
              />
            </Col>
          </Row>
        </Panel>

        <Panel header="Status" key="2">
          <Checkbox.Group
            onChange={evt => handleFilterSelect(evt, 'BY_STATUS')}
            defaultValue={subscriptionsFilters?.by_status}
            value={subscriptionsFilters?.by_status}
            style={{ width: '100%' }}
          >
            <Row type="flex">
              {statusOptions.map(option => (
                <Col span={12} key={option.value}>
                  <Checkbox value={option.value}>{option.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Panel>

        <Panel header="Frequência" key="3">
          {/* <Row></Row> */}
          {isCustomValue ? (
            <InputNumber
              value={subscriptionsFilters?.by_interval || []}
              placeholder="Insira a frequência"
              onChange={evt => {
                handleFilterSelect(evt, 'BY_INTERVAL');
              }}
            />
          ) : (
            <Select
              // mode="multiple"
              // showSearch
              placeholder="Selecione a frequência"
              value={subscriptionsFilters?.by_interval || []}
              // onSearch={handleCoupons}
              onChange={evt => {
                handleFilterSelect(evt, 'BY_INTERVAL');
              }}
              notFoundContent="Nenhuma frequência encontrada"
              filterOption={false}
            >
              {frequencyOptions.map(item => {
                return <Option key={item.value}>{item.label}</Option>;
              })}
            </Select>
          )}
          <Row style={{ width: '100%' }}>
            <Button
              type="link"
              style={{
                textTransform: 'none',
                padding: 0,
                fontWeight: 'normal',
                color: '#33d6ef'
              }}
              onClick={() => setIsCustomValue(!isCustomValue)}
            >
              {isCustomValue
                ? 'Ver frequências sugeridas'
                : 'Inserir outro valor'}
            </Button>
          </Row>
        </Panel>

        <Panel header="Data de criação" key="4">
          <Row gutter={10}>
            <Col span={12}>
              <DatePicker
                value={
                  subscriptionsFilters.start_date
                    ? moment(subscriptionsFilters.start_date)
                    : null
                }
                format="DD/MM/YY"
                placeholder="de DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'START_DATE')}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                value={
                  subscriptionsFilters.end_date
                    ? moment(subscriptionsFilters.end_date)
                    : null
                }
                format="DD/MM/YY"
                placeholder="até DD/MM/AA"
                onChange={evt => handleFilterSelect(evt, 'END_DATE')}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};

SubscriptionsFilters.defaultProps = {
  setSubscriptionsFilters: () => {},
  subscriptionsFilters: {
    start_date: null,
    end_date: null,
    by_status: [],
    by_interval: 0,
    next_billing_start_date: null,
    next_billing_end_date: null
  }
};
SubscriptionsFilters.propTypes = {
  setSubscriptionsFilters: PropTypes.func,
  subscriptionsFilters: PropTypes.shape({
    start_date: PropTypes.any,
    end_date: PropTypes.any,
    by_status: PropTypes.arrayOf(PropTypes.string),
    by_interval: PropTypes.number,
    next_billing_start_date: PropTypes.any,
    next_billing_end_date: PropTypes.any
  })
};

export default React.memo(SubscriptionsFilters);
