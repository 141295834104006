import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, DatePicker, Row, Col, Spin, Select } from 'antd';
import { handleSelectSearch } from '@/src/utils/searchUtils';

import PropTypes from 'prop-types';
import moment from '@/src/services/moment';
// import { getHubs } from '@/src/store/modules/hubs/slice';

import Tables from '@/src/components/Tables/index';

import { getHubs } from '@/src/store/modules/hubs-data/slice';

import { getAuditLog } from '@/src/store/modules/audit-log/slice';

const { Option } = Select;

const Audit = ({ id, title, type, visible, setVisible }) => {
  const modalTitle = `HISTÓRICO DE ATUALIZAÇÕES - ${title}`;
  const tableType = type === 'SkuPetz' ? 'logSkuPetz' : 'logUser';

  const dispatch = useDispatch();

  const { isLoading, auditLog, query, count } = useSelector(
    state => state.auditLog
  );

  const { list: hubsData, isLoading: hubsLoading } = useSelector(
    state => state.hubsData
  );

  const [petzData, setPetzData] = useState([]);
  const [petzEvent, setPetzEvent] = useState({});

  const [petzSearch, setPetzSearch] = useState(undefined);
  const [petzDateSearch, setPetzDateSearch] = useState('');

  const [hubsList, setHubsList] = useState([]);

  const getUserLogsHandler = useCallback(
    urlQuery => {
      const auditQuery = {
        ...urlQuery,
        auditable_id: id,
        auditable_type: type
      };
      if (type === 'Property') auditQuery.query['include[]'] = 'allowed_values';
      if (id && type) dispatch(getAuditLog(auditQuery));
    },
    [dispatch, id, type]
  );

  useEffect(() => {
    if (hubsData?.hubs) {
      const actives = Object.values(hubsData.hubs)?.filter(
        hub => hub.attributes.active || hub.attributes.externalId === '04 7013'
      );
      setHubsList(actives);
    }
  }, [hubsData]);

  const handleSearchFilters = (typeTitle, value) => {
    let date = '';
    if (typeTitle === 'by_date') {
      if (moment(value).isValid()) date = moment(value).format('YYYY-MM-DD');
    } else if (moment(petzDateSearch).isValid()) {
      date = moment(petzDateSearch).format('YYYY-MM-DD');
    }
    getUserLogsHandler({
      query: {
        page: 1,
        perPage: 20,
        search: '',
        by_hub: typeTitle === 'by_hub' ? value : petzSearch || '',
        by_date: date,
        url: ''
      }
    });
  };

  useEffect(() => {
    if (!petzDateSearch && !petzSearch && visible) handleSearchFilters();
    // eslint-disable-next-line
  }, [petzSearch, petzDateSearch]);

  useEffect(() => {
    if (visible) {
      getUserLogsHandler({
        query: {
          page: 1,
          perPage: 20,
          search: '',
          url: ''
        }
      });
    }

    if (type === 'SkuPetz') {
      dispatch(
        getHubs({
          query: {
            // include: 'regions',
            // fields: [{ key: 'regions', value: 'id' }],
            // filters: [],
            perPage: 500,
            sort: 'name'
          }
        })
      );
    }
    // eslint-disable-next-line
  }, [visible]);

  const handlePetzEventModal = data => setPetzEvent(data);

  useEffect(() => {
    if (type !== 'SkuPetz') return;
    const data = auditLog.map(item => ({
      ...item,
      handlePetzEventModal
    }));
    setPetzData(data);
    // eslint-disable-next-line
  }, [auditLog]);

  return (
    <>
      {type === 'SkuPetz' && (
        <Modal
          title="PAYLOAD EVENTO ORIGINAL - PETZ"
          visible={!!petzEvent.data}
          onOk={() => setPetzEvent({})}
          onCancel={() => setPetzEvent({})}
          width={900}
          bodyStyle={{ maxHeight: 600, overflow: 'scroll' }}
          centered
          footer={null}
        >
          <pre style={{ alignItems: 'left' }}>
            {JSON.stringify(petzEvent, null, 4)}
          </pre>
        </Modal>
      )}
      <Modal
        title={modalTitle}
        width={type === 'SkuPetz' ? 1200 : 900}
        visible={visible}
        className="user-logTable"
        centered
        onCancel={() => {
          if (type === 'SkuPetz') setPetzSearch(undefined);
          setVisible(false);
        }}
        footer={null}
      >
        {type === 'SkuPetz' && (
          <Row type="flex" align="middle">
            <Col span={6} style={{ marginRight: 8 }}>
              <Select
                placeholder="Hub"
                allowClear
                showSearch
                value={petzSearch}
                onChange={e => {
                  setPetzSearch(e);
                  handleSearchFilters('by_hub', e);
                }}
                style={{ width: '100%' }}
                className="ant-select-hub"
                filterOption={handleSelectSearch}
                notFoundContent={hubsLoading ? <Spin size="small" /> : null}
                loading={hubsLoading}
              >
                {hubsList?.map(hub => (
                  <Option
                    key={hub.attributes.externalId}
                    value={hub.attributes.internalId}
                  >
                    {hub.attributes.externalId} - {hub.attributes.name}
                  </Option>
                ))}
              </Select>
              {/* <Search
                placeholder="Buscar por hub"
                size="large"
                onChange={e => setPetzSearch(e.target.value)}
                // className="ant-searchbar ant-searchbar-list"
                onSearch={e => {
                  console.log(e);
                  handleSearchFilters({ title: 'by_hub', value: e });
                }}
                allowClear
              /> */}
            </Col>
            <Col span={4} style={{ marginRight: 8 }}>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={e => {
                  setPetzDateSearch(e);
                  handleSearchFilters('by_date', e);
                }}
                size="large"
              />
            </Col>
            {/* <Col>
              <Button onClick={() => handleSearchFilters()}>Pesquisar</Button>
            </Col> */}
          </Row>
        )}
        <Tables
          className="table-padding-logUser"
          contentToShow={tableType}
          visiblePaginationUp
          isLoading={isLoading}
          data={type === 'SkuPetz' ? petzData : auditLog}
          hasScroll
          count={count}
          query={query}
          populateTables={getUserLogsHandler}
          showCountFilters={type === 'SkuPetz'}
          showCountText={type === 'SkuPetz' ? 'eventos' : ''}
        />
      </Modal>
    </>
  );
};

Audit.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default Audit;
