import React from 'react';
import { Link } from 'react-router-dom';
import StatusPill from '@/src/components/StatusPill';
import formatCurrency from '@/src/utils/formatCurrency';
import moment from '@/src/services/moment';

const subscriptions = [
  {
    title: '# da Assinatura',
    dataIndex: 'attributes.id',
    key: 'number',
    render: (text, record) => {
      return (
        <Link
          to={`/assinaturas/${record.id}`}
          style={{
            border: '0',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'transparent',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          {text}
        </Link>
      );
    }
  },
  {
    title: 'Data de criação',
    dataIndex: 'attributes.created_at',
    key: 'created_at',
    render: createdAt => moment(createdAt).format('DD/MM/YYYY - HH:mm:ss')
  },
  {
    title: 'Ciclo atual',
    dataIndex: 'attributes.current_cycle',
    key: 'current_cycle',
    width: '100px'
  },
  {
    title: 'Frequência',
    dataIndex: 'attributes.interval',
    key: 'interval',
    width: '120px'
  },
  {
    title: 'Valor Gasto',
    dataIndex: 'attributes.total',
    key: 'total',
    width: '100px',
    render: value => formatCurrency(value)
  },
  {
    title: 'Status',
    dataIndex: 'attributes.status',
    key: 'status',
    render: status => <StatusPill status={status} centered steps={3} />
  }
];

export default subscriptions;
