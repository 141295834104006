import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllProducts } from '@/src/store/modules/products-all/slice';

const useProductsFilter = () => {
  const dispatch = useDispatch();
  const [hubFilter, setHubFilter] = useState([]);
  const { query } = useSelector(state => state.allProducts);

  const getProductsHandler = useCallback(
    urlQuery => {
      const queryHandle = urlQuery;
      const hubs = [];
      if (!queryHandle.query.search) delete queryHandle.query.search;

      if (queryHandle.query.filters && queryHandle.query.filters.by_hub)
        queryHandle.query.filters.by_hub.forEach(hub => {
          hubs.push(parseInt(hub, 10));
        });

      setHubFilter(hubs);
      // console.log(queryHandle, 'queryHandle');
      dispatch(getAllProducts(queryHandle));
    },
    [dispatch]
  );

  const handleProductsFilter = (type, value) => {
    const productsQuery = {
      ...query,
      filters: {
        ...query.filters
      }
    };
    let filterHubs = [];
    // console.log(productsQuery, 'productsQuery');
    switch (type) {
      case 'hubs':
        if (query.filters.by_hub && value.length)
          filterHubs = [...query.filters.by_hub];

        filterHubs = value;
        setHubFilter(filterHubs);

        productsQuery.filters.by_hub = filterHubs;

        break;
      case 'manufacturer':
        delete productsQuery.filters.by_manufacturer;

        if (value) productsQuery.filters.by_manufacturer = value;
        break;
      case 'category':
        delete productsQuery.filters.by_category;

        if (value) productsQuery.filters.by_category = value;
        break;
      case 'status':
        delete productsQuery.filters.not_active;
        delete productsQuery.filters.active;

        if (value === 'active') productsQuery.filters.active = true;
        if (value === 'disabled') productsQuery.filters.not_active = true;

        break;
      case 'stockqtd':
        productsQuery.filters.by_stock_quantity = value;
        break;
      case 'availableqtd':
        productsQuery.filters.by_available_quantity = value;
        break;
      case 'reservedqtd':
        productsQuery.filters.by_reserved_quantity = value;
        break;
      case 'property':
        productsQuery.filters.by_property = value;
        break;
      default:
    }

    dispatch(getAllProducts({ query: productsQuery }));
  };

  return {
    hubFilter,
    setHubFilter,
    handleProductsFilter,
    getProductsHandler
  };
};

export default useProductsFilter;
